import { useQuery } from '@tanstack/react-query'
// import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response';
// import type { UserCampaignDto } from '@inphiz-shared/shared-models/src/F_Feedback/user-campaign-dto';
// import { UserSurveyDto } from '@inphiz-shared/shared-models/src/F_Feedback/user-survey-dto';
import { APIConst, QueryKey } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'

// const CampaignDetailsDto = new DataItemApiResponse<UserCampaignDto>()
// export type CampaignDetailsParam = typeof CampaignDetailsDto

// const QuestionFeedbackSurveyDto = new UserSurveyDto()
// export type QuestionFeedbackSurveyItem = typeof QuestionFeedbackSurveyDto

export interface SurveyParam {
  handle?: string
  title: string
  content: string
  type: string
  inputTypeConfiguration: {
    inputType: number
  }
  comment: {
    isCommentAllowed: boolean
  }
  selected?: boolean
}

export interface SchedulesParam {
  title: string
  description: string
  cron: string
  startAt: Date | null
  endAt: Date | null
  campaignHandle?: string
  jobTitleHandles: string[]
  handle?: string
  surveys: SurveyParam[] | []
}

export interface CampaignDetailsParam {
  isSuccess: boolean
  errorInfo: null
  code: null
  message: null
  item: {
    title: string
    description: string
    handle: string
    status: number
    startAt: Date | null
    endAt: Date | null
    groupHandles: string[]
    schedules: SchedulesParam[]
  }
}

export function useCampaignDetails(handle: string) {
  return useQuery<CampaignDetailsParam>({
    queryKey: [QueryKey.campaignDetails],
    queryFn: async () => APIFunctions.GET(`${APIConst.createCampaign}?Handle=${handle}`),
  })
}
