import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CustomText, Spinner } from '../../atoms'
import { SortIcon } from '../../../icons'

interface CustomTableProps {
  tableHeaderList: any[]
  tableDataList?: any[]
  displayKeys?: string[]
  isLoading?: boolean
  keyMapping?: Record<string, string[]>
  onClick?: (item: any) => void
}

export function CustomTable({
  tableHeaderList,
  tableDataList = [],
  displayKeys,
  isLoading,
  keyMapping,
  onClick,
}: CustomTableProps) {
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' } | null>(
    null,
  )
  const { t } = useTranslation('common')

  const getValueByKey = (item: any, key: string) => {
    // Split the key to handle dot notation and array indexing
    const keys = key.replace(/\[(\d+)\]/g, '.$1').split('.')
    return keys.reduce((o, k) => (o ? o[k] : undefined), item)
  }

  const sortedData = React.useMemo(() => {
    const sortableData = [...tableDataList]
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        const aValue = getValueByKey(a, sortConfig.key)
        const bValue = getValueByKey(b, sortConfig.key)

        if (aValue === undefined)
          return 1
        if (bValue === undefined)
          return -1

        const aString = aValue?.toString()?.toLowerCase()
        const bString = bValue?.toString()?.toLowerCase()

        if (aString < bString) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (aString > bString) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    return sortableData
  }, [tableDataList, sortConfig])

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  return (
    <table className="w-full text-left">
      <thead>
        <tr>
          {tableHeaderList.map((i, index) => (
            <th
              key={index}
              scope="col"
              className="px-6 py-[22px] sticky top-0 bg-white cursor-pointer"
              onClick={() => i.key && handleSort(i.key)}
            >
              <div className="flex items-center">
                <CustomText wrapText={false} variant="mdPoppinsBold">
                  {i.title}
                </CustomText>
                {i.key && (
                  <SortIcon
                    className={`h-5 w-5 ml-4 stroke-[#C9C9C9] ${
                      sortConfig?.direction === 'asc' && sortConfig?.key === i.key
                        ? 'rotate-180'
                        : ''
                    }`}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.length === 0 ? (
          <tr>
            <td
              colSpan={displayKeys?.length}
              className="relative text-center h-32 border-t border-t-stroke"
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <CustomText wrapText={false} variant="mdPoppinsRegular">
                  {t('noDataFound')}
                </CustomText>
              )}
            </td>
          </tr>
        ) : (
          sortedData?.map((item, index) => (
            <tr
              onClick={() => onClick?.(item)}
              key={index}
              className="odd:bg-table-odd-bg border-t border-t-stroke"
            >
              {displayKeys?.map((key, index) => (
                <td
                  key={index}
                  className={`px-5 py-4 ${index < displayKeys.length - 1 ? 'border-r border-r-stroke' : ''}`}
                >
                  {key === 'severity' ? (
                    <div className="flex items-center">
                      {item[key] && (
                        <div
                          className={`h-3 w-3 rounded-full bg-opacity-30 mr-2 ${
                            item[key] == 1
                              ? 'bg-textDanger'
                              : item[key] == 2
                                ? 'bg-yellow'
                                : 'bg-grey'
                          }`}
                        />
                      )}
                      <CustomText wrapText={false} variant="mdPoppinsRegular">
                        {item[key] == 1
                          ? t('neededTxt')
                          : item[key] == 2
                            ? t('recommendedTxt')
                            : t('otherTxt')}
                      </CustomText>
                    </div>
                  ) : (
                    <CustomText wrapText={false} variant="mdPoppinsRegular">
                      {(() => {
                        const value = getValueByKey(item, key)
                        const dateRegex = /^\d{4}-\d{2}-\d{2}/
                        if (Array.isArray(value)) {
                          const mappedKeys = keyMapping?.[key]
                          return mappedKeys?.map((mappedKey) => {
                            return value?.map(i => i[mappedKey] || '-').join(', ')
                          })
                        }
                        if (
                          dateRegex.test(value)
                          && moment(value, moment.ISO_8601, true).isValid()
                        ) {
                          return moment(value).format('DD-MM-yyyy hh:mm:ss a') // Handle date
                        }
                        if (typeof value === 'boolean' || value === 'True' || value === 'False') {
                          return value === true || value === 'True' ? 'Yes' : 'No' // Handle boolean
                        }
                        return value || '-' // Handle other text fields
                      })()}
                    </CustomText>
                  )}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}
