import type { IAuthenticationClient } from './IAuthenticationClient'

export class AuthenticationSharedClient {
  private static _instance: IAuthenticationClient

  public static get instance(): IAuthenticationClient {
    return this._instance
  }

  public static set instance(instance: IAuthenticationClient) {
    this._instance = instance
  }
}
