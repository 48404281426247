import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { APIConst } from '@inphiz/api'
import { NewsLikeIcon } from '../../../icons'
import { CustomImg, CustomText } from '../../atoms'
import { dateFormat } from '../../../helpers'
import { UserContext } from '../../../contexts/UserContext'
import type { Comments } from '../../../packages/api-web'

export interface CommentItemProps {
  item: any
  isChild?: boolean
  isPending?: boolean
  pendingItem?: any
  onLike?: (item: Comments) => void
  onChildComment?: (item: Comments) => void
  onMore?: (item: Comments) => void
}

export function CommentItem({
  item,
  pendingItem,
  isChild = false,
  isPending,
  onLike,
  onChildComment,
  onMore,
}: CommentItemProps) {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)

  return (
    <>
      <div className="flex justify-between py-3 px-5 bg-white">
        <div className="h-10 w-10 rounded-lg">
          <CustomImg
            name={`${item?.author?.displayName ?? 'Anonym'}`}
            imgSrc={
              item?.author?.profileImageHandle
                ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item?.author?.profileImageHandle}`
                : ''
            }
            altText="user_image"
            className="h-10 w-10 rounded-md"
            imgClassName="rounded-md"
          />
        </div>
        <div className="flex flex-1 flex-col ml-3">
          <div
            className={`flex-1 ${isChild ? 'bg-white border border-stroke' : 'bg-comment-bg'} rounded-lg p-3`}
          >
            <CustomText className="!text-[12px]" variant="smPoppinsSemiBold">
              {item?.author?.displayName ?? 'Anonym'}
            </CustomText>
            <CustomText className="!text-[12px] mt-1" variant="smPoppinsRegular">
              {item?.content}
            </CustomText>
            {item?.coverMedia?.length > 0 && (
              <img src={item?.coverMedia[0]?.url} className="h-36 w-3/6 mt-1" />
            )}
          </div>
          <div className="flex flex-1 justify-between mt-2">
            <div className="flex">
              <CustomText
                className="mr-5 !text-[12px]"
                variant="smPoppinsRegular"
                textColor="text-textSecondary"
              >
                {dateFormat(item?.createdTime)}
              </CustomText>
              <button
                className="mr-5"
                onClick={() => {
                  onLike?.(item)
                }}
              >
                <CustomText
                  className="!text-[12px]"
                  textColor={
                    item?.metadata?.IsLikedByUser == `True`
                      ? 'text-textPrimary'
                      : 'text-textSecondary'
                  }
                  variant="smPoppinsMedium"
                >
                  {t('likeU')}
                </CustomText>
              </button>
              {!isChild && (
                <button
                  className="mr-5"
                  onClick={() => {
                    onChildComment?.(item)
                  }}
                >
                  <CustomText className="!text-[12px]" variant="smPoppinsMedium">
                    {t('reply')}
                  </CustomText>
                </button>
              )}
              {user.userData.handle == item?.author?.handle && (
                <button
                  className="self-start"
                  onClick={() => {
                    onMore?.(item)
                  }}
                >
                  <CustomText className="!text-[12px]" variant="smPoppinsMedium">
                    {t('remove')}
                  </CustomText>
                </button>
              )}
            </div>
            {item?.likeCount > 0 && (
              <div className="flex items-center">
                <CustomText className="mr-2 !text-[12px]" variant="smPoppinsMedium">
                  {`${item?.likeCount > 0 ? item?.likeCount : ''}`}
                </CustomText>
                <NewsLikeIcon className="h-4 w-4" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isPending && item?.childs.length == 0 && item?.handle == pendingItem?.handle && (
        <div style={{ paddingLeft: 16, opacity: 0.5 }}>
          <CommentItem item={pendingItem} isChild={true} />
        </div>
      )}
      {/* {item?.childs && item?.childs.length == 0 && !isChild && <Divider />} */}
      {item?.childs && item?.childs.length > 0 && (
        <>
          <div className="ml-4">
            {item?.childs.map((childComment: Comments) => (
              <CommentItem
                key={childComment.handle}
                item={childComment}
                isChild={true}
                isPending={isPending}
                onLike={() => {
                  onLike?.(childComment)
                }}
                onMore={() => {
                  onMore?.(childComment)
                }}
              />
            ))}
            {isPending && item?.handle == pendingItem?.handle && (
              <div style={{ opacity: 0.5 }}>
                <CommentItem item={pendingItem} isChild={true} />
              </div>
            )}
          </div>
          {/* <Divider /> */}
        </>
      )}
    </>
  )
}
