export function ShareIcon() {
  return (
    <svg width={19} height={25} viewBox="0 0 19 25" fill="none">
      <path
        d="M14.5 8.5H16C16.3978 8.5 16.7794 8.65815 17.0607 8.93945C17.342 9.22076 17.5 9.60218 17.5 10V22C17.5 22.3978 17.342 22.7792 17.0607 23.0605C16.7794 23.3419 16.3978 23.5 16 23.5H2.5C2.10218 23.5 1.72064 23.3419 1.43933 23.0605C1.15803 22.7792 1 22.3978 1 22V10C1 9.60218 1.15803 9.22076 1.43933 8.93945C1.72064 8.65815 2.10218 8.5 2.5 8.5H4"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 1V11.5"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 4.75L9.25 1L13 4.75"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
