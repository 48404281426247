import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'

export interface Group {
  readonly title: string
  readonly handle: string | undefined
  readonly metadata?: { [key: string]: string }
}

export function useFollowersQuery(contentType: string = 'news', doNotOrderItemsByName = false) {
  const { t } = useTranslation('common')
  return useQuery<Array<Group>>({
    queryKey: [QueryKey.groupsList, contentType],
    queryFn: async () => {
      let result: Array<Group> = await APIFunctions.GET(
        `${APIConst.followersGroup}?userHandle=@me&contentType=${contentType}&attachPermissions=true`,
      )

      // its better to sort the data in the backend
      // because then backend can manage the sorting based on the data or current preferences, or any other logic
      // by doing this in the frontend, we remove the flexibility of the backend to manage the sorting
      if (!doNotOrderItemsByName && result) {
        result = result.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
      }

      const allTab: Group = { title: t('all'), handle: undefined }
      result.unshift(allTab)
      return result
    },
  })
}
