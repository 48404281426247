import { useQuery } from '@tanstack/react-query'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { UserMetadataDto } from '@inphiz-shared/shared-models/src/Dto/user-metadata-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const UserDetailsType = new UserDto()
export type UserDetails = typeof UserDetailsType

const UserMetadataType = new UserMetadataDto()
export type UserMetadata = typeof UserMetadataType

export function userDetails(handle: string) {
  const results = useQuery({
    queryKey: [QueryKey.userDetails, handle],
    queryFn: async () =>
      APIFunctions.GET(`${APIConst.usersDirectory.userProfile}?handle=${handle}`),
  })
  const response = results.isFetching ? null : results.data

  if (response && response?.isSuccess)
    return { ...results, data: response.user as UserDetails }
  else return { ...results, data: null }
}
