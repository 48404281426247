import { CustomText } from '../../atoms'
import type { Group } from '../../../packages/api-web'

interface CustomTabProps {
  tabList: Group[]
  selectedItem?: Group
  onClick?: (i: Group) => void
}

export function CustomTab({ tabList, selectedItem, onClick }: CustomTabProps) {
  return (
    <ul className="flex overflow-x-auto scrollbar" role="tablist">
      {tabList.map(i => (
        <li
          onClick={(e) => {
            e.preventDefault()
            onClick?.(i)
          }}
          key={`${i.handle}`}
          className={`mr-4 last:mr-0 text-center cursor-pointer ${i.handle === selectedItem?.handle ? 'bg-secondary' : 'bg-white'} rounded-full border border-secondary`}
        >
          <CustomText
            wrapText={false}
            className="px-5 py-1 !text-[12px]"
            textColor={i.handle === selectedItem?.handle ? 'text-textWhite' : 'text-textPrimary'}
            variant="smPoppinsRegular"
          >
            {i.title}
          </CustomText>
        </li>
      ))}
    </ul>
  )
}
