import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { GetUserApiResponse } from '@inphiz-shared/shared-models/src/F_Users/get-user-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { filterItem } from '../../../screen/EmployeeList'

const UserItemDto = new GetUserApiResponse()
export type UserItem = typeof UserItemDto

const EmployeeListDto = new DataCollectionApiResponse<UserItem>()
export type EmployeeListData = typeof EmployeeListDto

interface EmployeeListParams {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  filterData?: filterItem[]
}

export function useEmployeeList({
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
  filterData = [],
}: EmployeeListParams) {
  return useQuery<EmployeeListData>({
    queryKey: [QueryKey.getAllUsers],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.usersList)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
        .add('isorderascending', false)
        .add('ParametersWithValues[IncludeOrganizationUnit]', true)
        .add('ParametersWithValues[HideWithoutJobTitle]', true)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
