import { useLocation } from 'react-router-dom'
import MetaDataType from '../../component/templates/MetaDataType'

function CreateRoleMetaDataType() {
  const { state } = useLocation()

  return (
    <div className="p-5 h-full flex flex-col bg-white border border-stroke rounded-lg">
      <MetaDataType jobTitleList={state?.jobTitleList ?? []} selectedItem={state?.selectedItem} />
    </div>
  )
}

export default CreateRoleMetaDataType
