export function NewsCommentIcon({ className = '' }) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M15.7503 8.00006H8.25263C7.92371 7.99673 7.60701 8.13315 7.37213 8.37935C7.13724 8.62555 7.0034 8.96138 7 9.31304V13.6834C7.0034 14.035 7.13724 14.3709 7.37213 14.6171C7.60701 14.8633 7.92371 14.9997 8.25263 14.9963H13.4953C13.6059 14.9963 13.7121 15.0425 13.7912 15.1251L15.4538 16.8702C15.5123 16.9321 15.5866 16.9742 15.6674 16.9914C15.7481 17.0085 15.8318 16.9999 15.9081 16.9666C15.9843 16.9333 16.0497 16.8768 16.0961 16.804C16.1425 16.7313 16.1678 16.6456 16.169 16.5575V15.3452C16.1728 15.2543 16.203 15.1669 16.2554 15.0952C16.3078 15.0235 16.3798 14.971 16.4614 14.9451C16.6186 14.8839 16.7544 14.7727 16.8507 14.6265C16.947 14.4803 16.9991 14.3061 17 14.1273V9.31304C16.9966 8.96192 16.8632 8.62657 16.6289 8.38046C16.3947 8.13435 16.0787 7.99756 15.7503 8.00006Z"
        fill="#FF3399"
      />
      <circle cx={12} cy={12} r={11.5} stroke="#FF3399" />
    </svg>
  )
}
