export function RoundArrowIcon({ className = '' }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <circle cx={12} cy={12} r={10} />
      <path d="M8 12h8" />
      <path d="m12 16 4-4-4-4" />
    </svg>
  )
}
