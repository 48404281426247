import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { EncryptedStorageKey } from '@inphiz/api'
import i18n from '../src/translations'
import { AuthRoutes, MainRoutes } from './Router'
import 'react-loading-skeleton/dist/skeleton.css'

export const queryClient = new QueryClient()

export const router = createBrowserRouter([AuthRoutes, MainRoutes])

function App() {
  const changeLanguage = () => {
    const code = localStorage.getItem(EncryptedStorageKey.languageCode)
    i18n.changeLanguage(code || 'sv')
  }

  useEffect(() => {
    changeLanguage()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
