import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import type { JobTitleItem } from './useRolePositions'

const RolePositionsDto = new DataItemApiResponse<JobTitleItem>()
export type JobTitleDetailsData = typeof RolePositionsDto

interface JobTitleDetailsParams {
  handle: string
}

export function useJobTitleDetails({ handle }: JobTitleDetailsParams) {
  return useQuery<JobTitleDetailsData>({
    queryKey: [QueryKey.roleTitleDetail],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getJobTitleDetails).add(
        'Handle',
        handle,
      )
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
