export function SocialNoDataIcon({ className = '' }) {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" className={className}>
      <path
        opacity="0.813"
        d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
        fill="#DFE1E4"
      />
      <path
        d="M112.995 45.7598H89.4141C85.5481 45.7598 82.4141 48.8938 82.4141 52.7598V103.352C82.4141 107.218 85.5481 110.352 89.4141 110.352H112.995C116.861 110.352 119.995 107.218 119.995 103.352V52.7598C119.995 48.8938 116.861 45.7598 112.995 45.7598Z"
        fill="white"
      />
      <path
        d="M112.995 46.5098H89.4141C85.9623 46.5098 83.1641 49.308 83.1641 52.7598V103.352C83.1641 106.804 85.9623 109.602 89.4141 109.602H112.995C116.447 109.602 119.245 106.804 119.245 103.352V52.7598C119.245 49.308 116.447 46.5098 112.995 46.5098Z"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M111.472 49.2832H90.9375C88.1761 49.2832 85.9375 51.5218 85.9375 54.2832V101.828C85.9375 104.59 88.1761 106.828 90.9375 106.828H111.472C114.233 106.828 116.472 104.59 116.472 101.828V54.2832C116.472 51.5218 114.233 49.2832 111.472 49.2832Z"
        fill="white"
      />
      <path
        d="M111.472 50.0332H90.9375C88.5903 50.0332 86.6875 51.936 86.6875 54.2832V101.828C86.6875 104.175 88.5903 106.078 90.9375 106.078H111.472C113.819 106.078 115.722 104.175 115.722 101.828V54.2832C115.722 51.936 113.819 50.0332 111.472 50.0332Z"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M59.7666 62.0547C59.7666 62.0547 37.2773 71.7207 39.1123 95.4277C40.9473 119.135 59.7666 122.957 59.7666 122.957C64.9155 123.855 70.2149 123.169 74.9659 120.99C79.7168 118.812 83.6951 115.243 86.375 110.756"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M71.6028 116.646C71.1785 116.646 70.7657 116.508 70.4268 116.252C70.0878 115.997 69.8412 115.638 69.7242 115.23C69.6072 114.823 69.6261 114.388 69.7781 113.992C69.9302 113.595 70.207 113.26 70.5668 113.035L92.2738 99.4656C92.5958 99.264 92.9697 99.1607 93.3495 99.1684C93.7293 99.176 94.0987 99.2943 94.4123 99.5087C94.726 99.7231 94.9702 100.024 95.1153 100.375C95.2603 100.727 95.2998 101.112 95.2288 101.486L91.0008 124.003C90.9308 124.375 90.7539 124.719 90.4917 124.993C90.2295 125.267 89.8933 125.458 89.5241 125.544C89.155 125.631 88.7687 125.607 88.4125 125.478C88.0563 125.348 87.7454 125.118 87.5178 124.815L81.3988 116.647L71.6028 116.646Z"
        fill="white"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.3989 116.646H78.4629V122.717C78.4628 123.073 78.5597 123.421 78.743 123.725C78.9263 124.03 79.1891 124.278 79.5031 124.444C79.8171 124.61 80.1705 124.688 80.5252 124.668C80.8798 124.648 81.2224 124.532 81.5159 124.331L85.2479 121.787L81.3989 116.646Z"
        fill="white"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M81.3984 116.646L94.4104 99.5117L81.3984 116.646Z" fill="white" />
      <path
        d="M81.3984 116.646L94.4104 99.5117"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.0378 45.8423H52.7378L45.8738 52.7064V45.8423H41.2978C40.691 45.8421 40.1092 45.601 39.6802 45.1719C39.2512 44.7429 39.01 44.1611 39.0098 43.5543V36.6904C39.01 36.0836 39.2512 35.5018 39.6802 35.0728C40.1092 34.6437 40.691 34.4026 41.2978 34.4023H71.0408C71.6475 34.4026 72.2293 34.6437 72.6584 35.0728C73.0874 35.5018 73.3285 36.0836 73.3288 36.6904V43.5524C73.3289 43.8532 73.2697 44.1511 73.1546 44.4291C73.0395 44.707 72.8708 44.9596 72.658 45.1722C72.4452 45.3849 72.1926 45.5536 71.9146 45.6686C71.6366 45.7836 71.3386 45.8426 71.0378 45.8423Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.2939 50.418C40.6871 50.4182 40.1053 50.6594 39.6763 51.0884C39.2473 51.5174 39.0061 52.0993 39.0059 52.706V59.568C39.0061 60.1747 39.2473 60.7565 39.6763 61.1855C40.1053 61.6146 40.6871 61.8557 41.2939 61.856H59.5939L66.4579 68.72V61.858H71.0339C71.6406 61.8577 72.2224 61.6166 72.6514 61.1876C73.0805 60.7585 73.3216 60.1767 73.3219 59.5699V52.706C73.3216 52.0993 73.0805 51.5174 72.6514 51.0884C72.2224 50.6594 71.6406 50.4182 71.0339 50.418H55.0219"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
