import * as React from 'react'

export function CameraIcon({ className = '' }) {
  return (
    <svg width={23} height={21} viewBox="0 0 23 21" fill="none" className={className}>
      <path
        d="M11.4125 6.52344C10.3791 6.52344 9.36889 6.82989 8.50964 7.40402C7.65039 7.97815 6.9807 8.79418 6.58523 9.74892C6.18976 10.7037 6.08629 11.7542 6.2879 12.7678C6.48951 13.7813 6.98713 14.7124 7.71786 15.4431C8.44859 16.1738 9.3796 16.6714 10.3932 16.873C11.4067 17.0747 12.4573 16.9712 13.412 16.5757C14.3668 16.1802 15.1828 15.5106 15.7569 14.6513C16.3311 13.7921 16.6375 12.7819 16.6375 11.7484C16.6382 11.0621 16.5035 10.3824 16.2411 9.74814C15.9788 9.11392 15.5939 8.53766 15.1086 8.05234C14.6233 7.56702 14.047 7.18218 13.4128 6.91983C12.7786 6.65748 12.0988 6.52278 11.4125 6.52344ZM11.4125 15.7454C10.6214 15.7454 9.84801 15.5108 9.19021 15.0713C8.53242 14.6318 8.01973 14.0071 7.71698 13.2762C7.41423 12.5453 7.33502 11.741 7.48936 10.9651C7.6437 10.1892 8.02466 9.47644 8.58407 8.91703C9.14348 8.35761 9.85621 7.97664 10.6321 7.8223C11.4081 7.66796 12.2123 7.74718 12.9432 8.04993C13.6741 8.35268 14.2989 8.86537 14.7384 9.52317C15.1779 10.181 15.4125 10.9543 15.4125 11.7454C15.412 12.806 14.9903 13.8229 14.2402 14.5726C13.4901 15.3223 12.473 15.7434 11.4125 15.7434V15.7454Z"
        fill="black"
      />
      <path
        d="M19.6737 7.10249C19.6737 7.28346 19.6201 7.46037 19.5195 7.61084C19.419 7.76131 19.2761 7.87859 19.1089 7.94785C18.9417 8.0171 18.7577 8.03521 18.5802 7.99991C18.4027 7.9646 18.2397 7.87746 18.1118 7.7495C17.9838 7.62153 17.8966 7.4585 17.8613 7.28101C17.826 7.10351 17.8441 6.91953 17.9134 6.75233C17.9827 6.58514 18.0999 6.44225 18.2504 6.34171C18.4009 6.24116 18.5778 6.1875 18.7588 6.1875C19.0014 6.1875 19.2342 6.28389 19.4058 6.45549C19.5774 6.62709 19.6737 6.85982 19.6737 7.10249Z"
        fill="black"
      />
      <path
        d="M19.0422 3.01088H16.2492L15.5992 1.87888C15.4178 1.56105 15.1547 1.2975 14.8371 1.11552C14.5196 0.933549 14.1592 0.839763 13.7932 0.84388H9.03023C8.66486 0.842756 8.30562 0.937808 7.98861 1.11948C7.6716 1.30116 7.40797 1.56306 7.22423 1.87888L6.57424 3.01088H3.61223C2.63402 3.0496 1.70946 3.46822 1.03499 4.1778C0.360527 4.88737 -0.0106836 5.83196 0.000234177 6.81088V16.7309C0.00366083 17.7376 0.40512 18.7022 1.11702 19.4141C1.82891 20.126 2.79347 20.5275 3.80024 20.5309H19.0902C20.097 20.5275 21.0616 20.126 21.7735 19.4141C22.4854 18.7022 22.8868 17.7376 22.8902 16.7309V6.81088C22.8719 5.79961 22.4585 4.83568 21.7384 4.12546C21.0182 3.41523 20.0487 3.01516 19.0372 3.01088H19.0422ZM21.6422 16.7109C21.6414 17.3938 21.3698 18.0486 20.8869 18.5315C20.4039 19.0144 19.7492 19.2861 19.0662 19.2869H3.78023C3.09728 19.2861 2.44253 19.0144 1.95961 18.5315C1.47669 18.0486 1.20503 17.3938 1.20424 16.7109V6.79088C1.19227 6.1328 1.43692 5.4959 1.88639 5.01507C2.33586 4.53425 2.95484 4.24725 3.61223 4.21488H7.27224L8.28324 2.45688C8.35765 2.32471 8.46606 2.21486 8.59723 2.13873C8.72841 2.06259 8.87757 2.02293 9.02923 2.02388H13.7972C13.9489 2.02293 14.0981 2.06259 14.2292 2.13873C14.3604 2.21486 14.4688 2.32471 14.5432 2.45688L15.5542 4.21488H19.0422C19.7254 4.21541 20.3804 4.48695 20.8635 4.9699C21.3467 5.45285 21.6184 6.10776 21.6192 6.79088V16.7109H21.6422Z"
        fill="black"
      />
    </svg>
  )
}
