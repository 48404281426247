import { useQuery } from '@tanstack/react-query'
import { NotificationSettingsApiResponse } from '@inphiz-shared/shared-models/src/F_UserNotificationSettings/notification-settings-api-response'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const NotificationSettingProps = new NotificationSettingsApiResponse()
export type NotificationSettingParam = typeof NotificationSettingProps

export function useGetSettingList() {
  return useQuery<NotificationSettingParam>({
    queryKey: [QueryKey.notificationSetting],
    queryFn: async () => {
      const { user } = await APIFunctions.GET(`${APIConst.usersDirectory.userProfile}?handle=@me`)
      const switchData: NotificationSettingParam = await APIFunctions.GET(
        APIConst.getNotificationSettings,
      )
      const data = switchData?.groups?.map((item) => {
        if (item.isEditable) {
          return {
            ...item,
            items:
              item?.items?.map((i) => {
                return {
                  id: i.id,
                  title: i.title,
                  isEditable: !!user.userNotificationSettings?.items?.find(
                    (j: { id: string }) => j.id == i.id,
                  ),
                }
              }) ?? [],
          }
        }
        return item
      })
      return {
        ...switchData,
        groups: data,
      }
    },
  })
}
