import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const JobAssignDto = new DataCollectionApiResponse()
export type JobAssignData = typeof JobAssignDto

export function useJobAssign() {
  return useQuery<JobAssignData>({
    queryKey: [QueryKey.roleAssign],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.jobAutoAssign)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: false,
  })
}
