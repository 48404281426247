import { InputPicker } from 'rsuite'
import { useEffect, useState } from 'react'
import { RightIcon, RightJumpIcon } from '../../../icons'
import { CustomText } from '../../atoms'

interface CustomPaginationProps {
  totalItem?: number
  itemPerPage?: number
  skip?: number
  onChange?: (value: any) => void
  onPagePress?: (index: number) => void
}

export function CustomPagination({
  totalItem,
  itemPerPage,
  skip,
  onChange,
  onPagePress,
}: CustomPaginationProps) {
  const totalPage = Number(itemPerPage && totalItem ? Math.ceil(totalItem / itemPerPage) : 1)
  const [selectedPage, setSelectedPage] = useState(1)
  const itemsToShow = 3
  const pageArray = Array.from({ length: totalPage }, (_, index) => index + 1)
  const startIndex = Math.max(0, selectedPage - 1 - Math.floor(itemsToShow / 2))
  const endIndex = startIndex + itemsToShow

  const displayedPages = pageArray.slice(startIndex, endIndex)

  useEffect(() => {
    if (!skip)
      setSelectedPage(1)
  }, [skip])

  useEffect(() => {
    if (selectedPage) {
      onPagePress?.(selectedPage)
    }
  }, [selectedPage, itemPerPage])

  return (
    <div className="flex flex-wrap py-2 px-2 sm:px-5 border-t border-t-stroke items-center justify-between">
      <button
        onClick={() => setSelectedPage(1)}
        disabled={selectedPage <= 1}
        className={`${selectedPage <= 1 ? 'opacity-50' : ''} h-6 w-6 sm:h-8 sm:w-8 bg-gray20 rounded-md items-center justify-center flex`}
      >
        <RightJumpIcon className="rotate-180" />
      </button>
      <button
        onClick={() => setSelectedPage(selectedPage - 1)}
        disabled={selectedPage <= 1}
        className={`${selectedPage <= 1 ? 'opacity-50' : ''} h-6 w-6 sm:h-8 sm:w-8 bg-gray20 rounded-md ml-1 sm:ml-2 items-center justify-center flex mr-1 sm:mr-3`}
      >
        <RightIcon className="rotate-180 stroke-1" />
      </button>
      <div className="flex flex-1 justify-center items-center">
        {displayedPages.map((i, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedPage(i)
            }}
            className={`h-6 w-6 sm:h-8 sm:w-8 ${index === displayedPages.length - 1 ? 'mr-0' : 'mr-1 sm:mr-2'} ${selectedPage == i ? 'bg-secondary' : 'bg-gray20'} rounded-md items-center justify-center flex`}
          >
            <CustomText
              textColor={selectedPage == i ? 'text-textWhite' : 'text-textSecondary'}
              variant="smPoppinsRegular"
            >
              {i.toString()}
            </CustomText>
          </button>
        ))}
      </div>
      <div className="hidden md:flex items-center justify-center cs-input-picker">
        <CustomText variant="smPoppinsRegular">Items per page</CustomText>
        <InputPicker
          placement="auto"
          defaultValue={20}
          value={itemPerPage}
          cleanable={false}
          data={[
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
            { label: 300, value: 300 },
          ]}
          className="mx-1 sm:mx-3"
          searchable={false}
          style={{ width: 80 }}
          onChange={(value) => {
            setSelectedPage(1)
            onChange?.(value)
          }}
        />
      </div>
      <button
        onClick={() => setSelectedPage(selectedPage + 1)}
        disabled={selectedPage == pageArray.length}
        className={`${selectedPage == pageArray.length ? 'opacity-50' : ''} h-6 w-6 sm:h-8 sm:w-8 bg-gray20 rounded-md items-center justify-center flex`}
      >
        <RightIcon className="stroke-1" />
      </button>
      <button
        onClick={() => setSelectedPage(pageArray.length)}
        disabled={selectedPage == pageArray.length}
        className={`${selectedPage == pageArray.length ? 'opacity-50' : ''} h-6 w-6 sm:h-8 sm:w-8 bg-gray20 rounded-md ml-1 sm:ml-2 items-center justify-center flex`}
      >
        <RightJumpIcon />
      </button>
    </div>
  )
}
