import { useTranslation } from 'react-i18next'
import type { KeyboardEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import { useRolePositions, useRoleSpecializations } from '../../packages/api-web/RoleManagement'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: null
  isSearch?: boolean
}

function RoleSpecializationsList() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [searchTxt, setSearchTxt] = useState('')
  const [searchJob, setSearchJob] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const {
    data: jobTitle,
    refetch: refetchJob,
    isFetching,
  } = useRolePositions({
    itemPerPage: 2147483647,
    skip: 0,
    searchTxt: searchJob,
  })
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: 'jobTitle',
      data: [],
      selectedData: null,
      isSearch: true,
    },
  ])
  const { isLoading, data, refetch } = useRoleSpecializations({
    itemPerPage,
    skip: skipItem,
    searchTxt,
    filterData,
  })

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = jobTitle?.items
    setFilterData(newFilter)
  }, [jobTitle])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchJob.length >= 3) {
        refetchJob()
      }
      else if (searchJob.length == 0) {
        refetchJob()
      }
    }, 2000)

    return () => clearTimeout(debounce)
  }, [searchJob])

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  const tableHeaderList = useMemo(
    () => [
      { title: t('specialization'), id: 1 },
      { title: t('positionType'), id: 2 },
      { title: t('shortDescription'), id: 3 },
    ],
    [t],
  )

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      refetch()
  }

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('specializations')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  isSearchLoader={isFetching}
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={t(item?.header)}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {...(item?.isSearch && {
                    onSearch: (e) => {
                      setSearchJob(e)
                    },
                  })}
                />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['title', 'jobTitles', 'description']}
              keyMapping={{
                jobTitles: ['title'],
              }}
              onClick={(item) => {
                navigate(`/role_management/specialization/job-role/${item.handle}`)
              }}
            />
          </div>
          {data && data?.items && data?.items.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RoleSpecializationsList
