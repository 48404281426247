import { appConfig } from '@inphiz/app-config/src/AppConfig'
import urlJoin from 'url-join'

export const APIConst = {
  /// <summary>
  /// The base URL for the API
  /// </summary>
  baseURL: urlJoin(
    appConfig.properties.ServiceBaseUrl,
    appConfig.properties.ServiceBaseUrlApiEndpointPath,
  ),

  ApiKey: appConfig.properties.AuthorizationApiKey,

  usersDirectory: {
    loginDate: '2.0/users/login', // TrackLastLoggedInDateApiResponse? but we dont use the response, so it's ApiResponse only
    userProfile: '2.0/users/item', // this shoule be used as the default user details endpoint!!!!!!!!!!
    profilePicUpload: '2.0/users/profile-photo',
    updateUserProfile: '2.0/users/update', // UpdateUserApiRequest //GetUserApiResponse
    updateMetadataVisibility: '2.0/users/update-metadata-visibility', // UpdateUserMetadataVisibilityApiRequest ApiResponse
    sensitiveData: '2.0/sensitive-data/ssn',
    deviceToken: '2.0/users/@me/devices',
  },

  employeesPerformance: {
    userPerformance: '3.1/progress/user-performance',
    jobRolesAchieved: '2.0/self-leadership/user/job-roles/achieved',
    myTeam: '3.1/progress/team-performance',
    certificateItems: '2.0/certificate-items',
    certificateCategory: '2.0/certificates',
    createCertificate: '2.0/certificate-items',
    certificateRequirements: '2.0/self-leadership/requirements/user/all',
    getJobRoles: '2.0/self-leadership/user/job-roles/with-badge-content',
    getJobRoleDetails: '2.0/self-leadership/user/job-roles/item/with-requirements-badges',
    certificateDetails: '2.0/certificate-items/item',
    renewCertificate: '2.0/certificate-items/renew',
    acceptJobRole: '2.0/self-leadership/user/job-roles/accept',
    promoteJobRole: '2.0/self-leadership/user/job-roles/promote',
    getJobTitles: '2.0/self-leadership/job-titles',
    getJobRolesLeadership: '2.0/self-leadership/job-roles',
    archivingReasons: '2.0/certificate-items/archiving-reasons',
    addArchiveCertificate: '2.0/certificate-items/status',
    jobAutoAssign: '2.0/self-leadership/user/job-roles/auto-assign',
    getJobTitleDetails: '2.0/self-leadership/job-titles/handle',
    jobRequirementTypes: '2.0/self-leadership/requirements/types',
    jobTitlesRelationsInfo: '2.0/self-leadership/job-titles/updated-relations-info',
    jobRolesRelationsInfo: '2.0/self-leadership/job-roles/updated-relations-info',
    requirements: '2.0/self-leadership/requirements',
    getJobRoleItemDetails: '2.0/self-leadership/job-roles/handle',
    getDepartments: '2.0/users/departments',
  },

  mediaUpload: '2.0/media',

  notificationsList: '2.0/notifications',
  notificationsCount: '2.0/notifications/unread/me',
  readNotifications: '2.0/notifications/status',
  readAllNotifications: '2.0/notifications/all/statuses',
  followersGroup: '1.0/social/followers/following',
  getNews: '2.0/news',

  getLikes: '2.0/social/likes',
  getComments: '2.0/social/comments',

  socials: {
    socials: '2.0/socials',
    getSocialDetails: '2.0/socials/handle',
    readConfirmed: '2.0/social-actions',
    readingUserList: '2.0/social-actions/viewers',
  },

  emergencyContacts: {
    emergencyContacts: '2.0/sensitive-data/emergency-contacts',
    deleteEmergencyContacts: '2.0/sensitive-data',
  },

  getAllEmployeeList: '2.0/contacts',
  getMyTeamEmployeeList: '2.0/users/collections',
  getPolicies: '2.0/users/policies-questions',
  getPoliciesQuestions: '2.0/users/policies-questions', // a new endpoint, policy list + questions
  postPolicy: '2.0/users/policy',
  applicationConfiguration: '2.1/configuration/application',
  onboardingInfo: '2.0/users/onboarding-info',
  feedback: '3.0/feedback',
  appFeedback: '1.0/utility/feedback',
  linksGeneral: '3.0/links/collection/general',
  certificateType: '2.0/certificate-types',
  myApplication: '3.0/links',
  addFeedback: '1.0/utility/feedback',
  assistantChat: 'assistant/chat',
  myDocument: '2.0/pages',
  getNotificationSettings: '2.0/notifications/settings',
  getSearch: '1.0/search',
  newsDetail: '2.0/news/handle',
  getCampaignsList: '3.0/feedbacks/user/campaigns/collection',
  getCampaignDetails: '3.0/feedbacks/user/campaigns',
  getQuestionDetails: '3.0/feedbacks/question-items',
  getActionCard: '2.0/recommended-actions/cards',
  getFeedbacks: '3.0/feedbacks/campaigns/collection',
  getOrganizationUnit: '2.0/organization-units',
  getEmployeeState: '2.0/users/states',
  getFeedbackGroups: '2.0/groups',

  createCampaign: '3.0/feedbacks/campaigns',
  campaignStatus: '3.0/feedbacks/campaigns/status',
  managerList: '2.0/users/filter',
  usersList: '2.0/users',

  equipment: {
    createEquipment: '2.0/equipment/type',
    detailsEquipment: '2.0/equipment/type/handle',
    deleteEquipment: '2.0/equipment/type',
    getEquipmentTypes: '2.0/equipment/type',
    getEquipmentCategory: '2.0/equipment/category',
  },

  newCategory: '2.0/groups',
  createNew: '2.0/news',
  developerSection: {
    devResetUserPolicies: '1.0/test/drop-accepted-policies',
    resetEnps: '1.0/test/reset-enps',
    resetSSN: '1.0/test/reset-ssn',
    feedbackPushNotiticationRequest: '1.0/test/feedback/push', // add '/1' etc at the end
    requestTestPushNotification: '1.0/test/push', // add '/1' etc at the end
  },
}
