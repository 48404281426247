import type { IApiSharedAuthProvider } from '@inphiz/api/src/Networking/IApiSharedAuthProvider'
import type { Configuration } from '@azure/msal-browser'
import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { appConfig } from '@inphiz/app-config/src/AppConfig'

const b2c = appConfig.properties.auth_azureb2c

const authConfig: Configuration = {
  auth: {
    clientId: appConfig.properties.auth_azureb2c.ClientID,
    authority: `https://${b2c.AzureADB2CHostname}/${b2c.Tenant}/${b2c.PolicySignUpSignIn}/v2.0`,
    knownAuthorities: [b2c.AzureADB2CHostname],
    redirectUri: appConfig.properties.webApp.redirectUri,
    postLogoutRedirectUri: appConfig.properties.webApp.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
        }
      },
    },
  },
}

export const msalInstance = new PublicClientApplication(authConfig)
msalInstance.initialize()

export class ApiWebAuthProvider implements IApiSharedAuthProvider {
  public async getAuthToken(): Promise<string | null> {
    const account = msalInstance.getAllAccounts()[0]
    const response = await msalInstance
      .acquireTokenSilent({
        scopes: b2c.Scopes,
        account,
      })
      .then((tokenResponse) => {
        return tokenResponse
      })
      .catch((_error) => {
        // handle errors
        return null
      })

    return response?.accessToken ?? null
  }

  public async sessionExpiredAlert(title: string, message: string): Promise<void> {
    // Logger.Log.debug('Session expired alert %s %s', title, message)
    alert(`${title} ${message}`)
  }

  public async checkSessionExpired(): Promise<boolean> {
    const account = msalInstance.getAllAccounts()[0]
    const response = await msalInstance
      .acquireTokenSilent({
        scopes: b2c.Scopes,
        account,
      })
      .then((tokenResponse) => {
        return tokenResponse
      })
      .catch((_error) => {
        // handle errors
        return null
      })

    if (response?.expiresOn === null || response?.expiresOn === undefined) {
      return false
    }

    return Date.now() > new Date(response.expiresOn).getTime()
  }

  public async resetSession(): Promise<void> {
    // localStorage.removeItem(EncryptedStorageKey.userSession)
    // TODO
    // import { queryClient, router } from '../../../App'
    // router.navigate('/', { replace: true })
  }
}
