import { useInfiniteQuery } from '@tanstack/react-query'
import { UserCampaignDto } from '@inphiz-shared/shared-models/src/F_Feedback/user-campaign-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const CampaignListType = new UserCampaignDto()
export type CampaignListParam = typeof CampaignListType

export function campaignList() {
  return useInfiniteQuery({
    queryKey: [QueryKey.campaignList],
    queryFn: async ({ pageParam }) => {
      const url = `?take=10&cursor=${pageParam}`
      const results = await APIFunctions.GET(APIConst.getCampaignsList + url)
      const nextCursorValue = pageParam + 10
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
  })
}
