export function CampaignIcon({ className = '' }) {
  return (
    <svg viewBox="0 0 80 80" className={className}>
      <g transform="translate(0,80) scale(0.10,-0.10)" fill="#ffffff">
        <path d="M600 745 c-13 -33 -13 -85 0 -85 14 0 28 30 32 69 4 42 -18 53 -32 16z" />
        <path d="M378 739 c-10 -5 -20 -26 -24 -46 -9 -50 -114 -153 -219 -217 -44 -27 -90 -61 -102 -75 -34 -39 -31 -103 7 -163 39 -62 72 -83 132 -83 45 0 51 -3 113 -65 l65 -64 45 44 45 44 -57 58 -57 57 35 15 c85 35 214 62 224 46 10 -16 50 -12 68 8 24 26 21 47 -14 112 l-32 57 22 22 c30 30 28 85 -5 118 -22 21 -32 25 -68 21 -42 -5 -43 -4 -67 41 -40 77 -69 95 -111 70z m149 -210 c100 -170 116 -209 90 -209 -16 0 -230 367 -221 381 13 22 33 -4 131 -172z m-63 -43 c48 -82 85 -150 84 -151 -2 -1 -29 -7 -61 -13 -32 -6 -85 -23 -119 -36 -34 -14 -63 -24 -64 -23 -1 1 -28 46 -59 100 l-57 99 24 15 c12 9 55 48 93 87 39 40 71 72 72 71 0 0 40 -67 87 -149z m136 90 c14 -18 10 -58 -6 -63 -10 -3 -54 54 -54 71 0 12 48 6 60 -8z m-397 -213 c75 -132 73 -118 23 -147 -25 -14 -57 -26 -71 -26 -32 0 -73 41 -99 99 -16 33 -17 45 -7 68 10 24 80 80 103 82 3 1 26 -34 51 -76z m140 -201 c46 -46 47 -49 29 -65 -17 -16 -21 -14 -71 37 -49 49 -52 55 -35 65 27 15 25 16 77 -37z" />
        <path d="M719 648 c-44 -26 -42 -25 -35 -43 5 -15 10 -14 46 6 38 21 50 42 32 53 -5 3 -24 -4 -43 -16z" />
        <path d="M697 513 c-4 -3 -7 -10 -7 -15 0 -10 62 -38 85 -38 23 0 18 29 -7 39 -47 17 -65 21 -71 14z" />
      </g>
    </svg>
  )
}
