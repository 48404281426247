export function NewsIcon({ className = '' }) {
  return (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" className={className}>
      <mask id="mask0_1_662" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="27">
        <path d="M27.84 0H0.839966V27H27.84V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_662)">
        <path
          d="M25.59 7.5V21.75C25.59 22.3467 25.3529 22.919 24.931 23.341C24.509 23.763 23.9367 24 23.34 24M23.34 24C22.7432 24 22.1709 23.763 21.749 23.341C21.327 22.919 21.09 22.3467 21.09 21.75V4.5C21.09 4.10218 20.9319 3.72064 20.6506 3.43933C20.3693 3.15803 19.9878 3 19.59 3H4.58997C4.19214 3 3.81062 3.15803 3.52931 3.43933C3.24801 3.72064 3.08997 4.10218 3.08997 4.5V21.75C3.08997 22.3467 3.32702 22.919 3.74898 23.341C4.17093 23.763 4.74323 24 5.33997 24H23.34Z"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83997 16.5H17.34"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83997 19.5H12.84"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83997 7.5C6.83997 7.30109 6.91898 7.11032 7.05963 6.96967C7.20028 6.82901 7.39105 6.75 7.58997 6.75H16.59C16.7889 6.75 16.9796 6.82901 17.1203 6.96967C17.261 7.11032 17.34 7.30109 17.34 7.5V12C17.34 12.1989 17.261 12.3897 17.1203 12.5303C16.9796 12.671 16.7889 12.75 16.59 12.75H7.58997C7.39105 12.75 7.20028 12.671 7.05963 12.5303C6.91898 12.3897 6.83997 12.1989 6.83997 12V7.5Z"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
