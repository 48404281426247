import { useQuery } from '@tanstack/react-query'
import type { PageItemDto } from '@inphiz-shared/shared-models/src/F_Pages/page-item-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const PageItemProps = new DataCollectionApiResponse<PageItemDto>()
export type PageItemParam = typeof PageItemProps

interface MyDocumentParams {
  groupHandle?: string
}

export function useMyDocument({ groupHandle }: MyDocumentParams) {
  return useQuery<PageItemParam>({
    queryKey: [QueryKey.myPages],
    queryFn: () => {
      const qb = new QueryBuilder(APIConst.myDocument)
      if (groupHandle) {
        qb.add('ParametersWithValues[groupHandle]', groupHandle)
      }
      return APIFunctions.GET(qb.toString())
    },
  })
}
