import { Bar, Line, Pie } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { useState } from 'react'
import { BorderButton, CustomText, Divider } from '../../component'
import {
  AverageRating,
  ExcellentRating,
  GoodRating,
  PoorRating,
  RightIcon,
  ShareIcon,
} from '../../icons'

Chart.register(...registerables)

function CampaignStatistics() {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const barIcon = [PoorRating, AverageRating, GoodRating, ExcellentRating]

  const data = [
    {
      job_title: 'Driver',
      end_date: '2024-08-31',
      question: [
        {
          question: 'Did you drink any coffee today?',
          answer_type: 'Yes/No, Thumbs up/down',
          chart_type: 'pie',
        },
        {
          question: 'How was your day?',
          answer_type: 'Happy/Sad',
          chart_type: 'bar',
        },
        {
          question: 'How much do you like the coffee at the office?',
          answer_type: 'Happy/Sad',
          chart_type: 'bar',
        },
      ],
    },
  ]

  return (
    <div className="p-5 h-full flex flex-col  overflow-y-auto scrollbar">
      <div className="py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col">
        <CustomText variant="xlPoppinsBold">
          Feedback: [Description feedback] “Coffee routines Stockholm”
        </CustomText>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-1 mt-6">
          <div className="flex flex-col text-center">
            <CustomText variant="mdPoppinsSemiBold">“STATS MEASURED”</CustomText>
            <div className="h-[90%]">
              <Pie
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 1,
                }}
                data={{
                  labels: ['Dataset 1', 'Dataset 2'],
                  datasets: [
                    {
                      label: 'My First Dataset',
                      data: [200, 50],
                      backgroundColor: ['#48D4EBB2', '#E9EDF0'],
                      hoverOffset: 2,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="flex flex-col text-center">
            <CustomText variant="mdPoppinsSemiBold">“STATS MEASURED”</CustomText>
            <Line
              data={{
                labels: ['January', 'Ferbuary', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    label: 'Dataset 1',
                    data: [0, 100, 90, 140, 120, 170, 155],
                    backgroundColor: '#F2975A',
                    borderColor: '#F2975A',
                    tension: 0.1,
                  },
                  {
                    label: 'Dataset 2',
                    data: [0, 40, 10, 70, 55, 100, 80],
                    backgroundColor: '#4589B3B2',
                    borderColor: '#4589B3B2',
                    tension: 0.1,
                  },
                ],
              }}
            />
          </div>
          <div className="flex flex-col text-center items-center">
            <CustomText variant="mdPoppinsSemiBold"> </CustomText>
            <div className="w-full lg:w-3/4">
              <div className="bg-[#F4FBFA] px-3 py-4 rounded-lg">
                <CustomText variant="xlPoppinsSemiBold">4 surveys</CustomText>
                <CustomText variant="smPoppinsRegular" className="mt-2">
                  You have sent out 4 different surveys in this feedback event
                </CustomText>
              </div>
              <div className="bg-[#F4FBFA] px-3 py-4 rounded-lg mt-6">
                <CustomText variant="xlPoppinsSemiBold">4/5</CustomText>
                <CustomText variant="smPoppinsRegular" className="mt-2">
                  Answers the surveys
                </CustomText>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Survey section charts */}
      {data.map((item, index) => (
        <div
          key={index}
          className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col"
        >
          <div className="flex justify-between items-center mb-5">
            <CustomText variant="lgPoppinsBold" className="flex-[0.3]">
              {`Survey ${index + 1}`}
            </CustomText>
            <div className="hidden lg:flex flex-1">
              <CustomText variant="smPoppinsBold" className="flex-1">
                [Description survey]
              </CustomText>
              <div className="flex flex-1">
                <CustomText variant="smPoppinsBold">Job title: </CustomText>
                <CustomText variant="smPoppinsRegular">{item?.job_title}</CustomText>
              </div>
              <div className="flex flex-1">
                <CustomText variant="smPoppinsBold">End date: </CustomText>
                <CustomText variant="smPoppinsRegular">{item?.end_date}</CustomText>
              </div>
            </div>
            <div className="flex flex-[0.2] justify-end items-center">
              <ShareIcon />
              <CustomText variant="smPoppinsMedium" className="ml-2">
                Export
              </CustomText>
            </div>
          </div>
          <div className="flex lg:hidden flex-1 flex-col mb-5">
            <CustomText variant="smPoppinsBold" className="flex-1">
              [Description survey]
            </CustomText>
            <div className="flex flex-1 mt-1 mb-1">
              <CustomText variant="smPoppinsBold">Job title: </CustomText>
              <CustomText variant="smPoppinsRegular">{item?.job_title}</CustomText>
            </div>
            <div className="flex flex-1">
              <CustomText variant="smPoppinsBold">End date: </CustomText>
              <CustomText variant="smPoppinsRegular">{item?.end_date}</CustomText>
            </div>
          </div>
          <Divider />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-1 mt-5 mb-5">
            <div className="flex flex-col text-center">
              <CustomText variant="mdPoppinsSemiBold">“STATS MEASURED”</CustomText>
              <div className="h-[90%]">
                <Pie
                  options={{
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                  }}
                  data={{
                    labels: ['Dataset 1', 'Dataset 2'],
                    datasets: [
                      {
                        label: 'My First Dataset',
                        data: [30, 50],
                        backgroundColor: ['#EA7369B2', '#71CC55B2'],
                        hoverOffset: 2,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col text-center">
              <CustomText variant="mdPoppinsSemiBold">“STATS MEASURED”</CustomText>
              <Line
                data={{
                  labels: ['January', 'Ferbuary', 'March', 'April', 'May', 'June', 'July'],
                  datasets: [
                    {
                      label: 'Dataset 1',
                      data: [0, 100, 90, 140, 120, 170, 155],
                      backgroundColor: '#ECE971',
                      borderColor: '#ECE971',
                      tension: 0.1,
                    },
                    {
                      label: 'Dataset 2',
                      data: [0, 40, 10, 70, 55, 100, 80],
                      backgroundColor: '#9761CDB2',
                      borderColor: '#9761CDB2',
                      tension: 0.1,
                    },
                  ],
                }}
              />
            </div>
            <div className="flex flex-col text-center items-center">
              <CustomText variant="mdPoppinsSemiBold"> </CustomText>
              <div className="w-full lg:w-3/4">
                <div className="bg-[#F4FBFA] px-3 py-4 rounded-lg">
                  <CustomText variant="xlPoppinsSemiBold">4 questions</CustomText>
                  <CustomText variant="smPoppinsRegular" className="mt-2">
                    Survey contains 4 questions
                  </CustomText>
                </div>
                <div className="bg-[#F4FBFA] px-3 py-4 rounded-lg mt-6">
                  <CustomText variant="xlPoppinsSemiBold">{'Fridays\n15:00'}</CustomText>
                  <CustomText variant="smPoppinsRegular" className="mt-2">
                    Survey is sent out
                  </CustomText>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          {/* Question section charts */}
          {selectedIndex == index
          && item?.question?.map((questionItem, index) => (
            <div key={index}>
              <div className="flex justify-between items-start lg:items-center mb-5 mt-5 flex-col lg:flex-row">
                <CustomText variant="mdPoppinsSemiBold" className="flex-[0.4] text-center">
                  {`Question ${index + 1}`}
                </CustomText>
                <CustomText
                  variant="smPoppinsRegular"
                  className="flex-1 mr-4 max-lg:mt-1 max-lg:mb-1"
                >
                  {`[Question] "${questionItem?.question}"`}
                </CustomText>
                <div className="flex flex-1">
                  <CustomText variant="smPoppinsBold">Answer type: </CustomText>
                  <CustomText variant="smPoppinsRegular">{questionItem?.answer_type}</CustomText>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-6 flex-1 mt-5 mb-5">
                <div></div>
                <div className="flex flex-col text-center col-span-2 mr-5">
                  <CustomText variant="mdPoppinsSemiBold">Responses</CustomText>
                  {questionItem?.chart_type === 'pie' ? (
                    <div className="h-[90%]">
                      <Pie
                        options={{
                          maintainAspectRatio: false,
                          aspectRatio: 1,
                        }}
                        data={{
                          labels: ['Dataset 1', 'Dataset 2'],
                          datasets: [
                            {
                              label: 'My First Dataset',
                              data: [30, 50],
                              backgroundColor: ['#EA7369B2', '#71CC55B2'],
                              hoverOffset: 2,
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Bar
                        plugins={[
                          {
                            id: `emoji`,
                            afterDraw: (chart: any) => {
                              const ctx = chart?.ctx
                              ctx.save()
                              const xAxis = chart?.scales?.x
                              const yAxis = chart?.scales?.y
                              xAxis.ticks.forEach((value: any, index: number) => {
                                const x = xAxis.getPixelForTick(index)
                                const image = new Image()
                                image.src = barIcon[index] // provide the image URL
                                ctx.drawImage(image, x - 12, yAxis.bottom + 5, 20, 20)
                              })
                              ctx.restore()
                            },
                          },
                        ]}
                        options={{
                          scales: {
                            x: {
                              grid: {
                                display: false,
                              },
                            },
                          },
                        }}
                        data={{
                          labels: [1, 2, 3, 4],
                          datasets: [
                            {
                              label: 'Dataset 1',
                              data: [50, 100, 150, 200],
                              backgroundColor: '#4589B3',
                              barPercentage: 0.5,
                            },
                          ],
                        }}
                      />
                      <div className="ml-12 mr-4 mt-1 bg-gradient-to-r from-[#FF0000] via-[#FC912E] to-[#34C759] h-3 rounded-full" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col text-center  col-span-2">
                  <CustomText variant="mdPoppinsSemiBold">Responses over time</CustomText>
                  <Line
                    data={{
                      labels: ['January', 'Ferbuary', 'March', 'April', 'May', 'June', 'July'],
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: [0, 100, 90, 140, 120, 170, 155],
                          backgroundColor: '#ECE971',
                          borderColor: '#ECE971',
                          tension: 0.1,
                        },
                        {
                          label: 'Dataset 2',
                          data: [0, 40, 10, 70, 55, 100, 80],
                          backgroundColor: '#9761CDB2',
                          borderColor: '#9761CDB2',
                          tension: 0.1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="mt-5 self-center">
            <BorderButton
              onClick={() => {
                if (selectedIndex != -1) {
                  setSelectedIndex(-1)
                }
                else {
                  setSelectedIndex(index)
                }
              }}
              className="rounded-md border-bthDisable"
              children="Show less survey results"
              rightIcon={(
                <RightIcon
                  className={`${selectedIndex == index ? '-rotate-90' : 'rotate-90'} ml-2`}
                />
              )}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default CampaignStatistics
