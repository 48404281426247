import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

interface JobRequirementParams {
  handle?: string
  isJobTitle?: boolean
}

export function useDeleteJobRequirement({ handle, isJobTitle }: JobRequirementParams) {
  return useMutation({
    mutationFn: async (data: string[]) => {
      const params = {
        requirementHandles: data,
      }
      const result = await APIFunctions.DELETE(
        `${isJobTitle ? APIConst.employeesPerformance.getJobTitles : APIConst.employeesPerformance.getJobRolesLeadership}/${handle}/requirements`,
        params,
      )
      return result
    },
  })
}
