import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export interface Likes {
  handle: string
  author: {
    profileImageHandle: string | null
    profileImage: string | null
    displayName: string
    handle: string
    jobTitle: string
  }
  createdTime: string
}

export interface LikesData {
  items: Likes[]
  isSuccess: boolean
}

export function useGetLikesByHandle(handle: string) {
  const results = useQuery<LikesData>({
    queryKey: [QueryKey.likes, handle],
    queryFn: () => APIFunctions.GET(`${APIConst.getLikes}?ChildOf=${handle}`),
  })

  const response = results.isFetching ? null : results.data

  if (response && response?.isSuccess)
    return { ...results, data: response }
  else return { ...results, data: null }
}
