enum KnownUserMetadataTypes {
  Text = 'Text',
  Phone = 'Phone',
  City = 'City',
  State = 'State',
  Sms = 'Sms',
  Email = 'Email',
  Url = 'Url',
  StartDate = 'StartDate',
  FreeTextSelector = 'FreeTextSelector',
  Custom = 'Custom',
  CertificatesSummary = 'CertificatesSummary',
  SkypeForBusiness = 'SkypeForBusiness',
  MicrosoftTeams = 'MicrosoftTeams',
  ChatUserId = 'ChatUserId',
  HasJobRolesToUnlock = 'HasJobRolesToUnlock',
  HasJobRolesToAccept = 'HasJobRolesToAccept',
}

enum UserCertificateState {
  missingExperienceState = 'MissingExperienceState',
}

enum RequirementsTypes {
  certificate = 'Certificate',
  equipment = 'Equipment',
}

enum UserCertificatesConstantsLocal {
  required = 'cert-is-required',
}

enum UserJobRoleStatuses {
  locked = 'Locked',
  unlocked = 'Unlocked',
  inProgress = 'InProgress',
  active = 'Active',
  suspended = 'Suspended',
  removed = 'Removed', // For status history purpose
}

enum GetUserRoleModes {
  toUnlock = 'ToUnlock',
  toAccept = 'ToAccept',
  achievements = 'Achievements',
  all = 'All',
}

enum NotificationCategory {
  certificateAdded = 'CertificateAdded',
  userJobRoleIsSuspended = 'UserJobRoleIsSuspended',
  userJobRoleCanBeUnlocked = 'UserJobRoleCanBeUnlocked',
  certificateStatusUpdated = 'CertificateStatusUpdated',
  userJobRoleIsActivated = 'UserJobRoleIsActivated',
  userJobRoleIsAccepted = 'UserJobRoleIsAccepted',
  certificateExpiring = 'CertificateExpiring',
  likeAddedToPost = 'LikeAddedToPost',
  postAddedToGroup = 'PostAddedToGroup',
}

enum SocialPostType {
  readConfirmation = 'ReadConfirmation',
  default = 'default',
  readConfirmed = 'ReadConfirmed',
}

enum ContentType {
  like = 'like',
  comment = 'comment',
  news = 'news',
  groupwallpost = 'groupwallpost',
}

const RelationTypes = [
  {
    name: 'Mor',
    id: 0,
  },
  {
    name: 'Far',
    id: 1,
  },
  {
    name: 'Syster',
    id: 2,
  },
  {
    name: 'Bror',
    id: 3,
  },
  {
    name: 'Son',
    id: 4,
  },
  {
    name: 'Dotter',
    id: 5,
  },
  {
    name: 'Barn',
    id: 6,
  },
  {
    name: 'Vän',
    id: 7,
  },
  {
    name: 'Make/Maka',
    id: 8,
  },
  {
    name: 'Partner',
    id: 9,
  },
  {
    name: 'Familjemedlem',
    id: 10,
  },
  {
    name: 'Assistent',
    id: 11,
  },
  {
    name: 'Chef',
    id: 12,
  },
  {
    name: 'Rumskamrat',
    id: 13,
  },
  {
    name: 'Granne',
    id: 14,
  },
  {
    name: 'Läkare',
    id: 15,
  },
  {
    name: 'Vårdgivare',
    id: 16,
  },
  {
    name: 'Övrig',
    id: 17,
  },
]

enum UserMetadataKey {
  email = 'Email',
  mobile = 'Mobile',
  phone = 'Phone',
  startDate = 'StartDate',
}

export {
  KnownUserMetadataTypes,
  UserCertificateState,
  RequirementsTypes,
  UserCertificatesConstantsLocal,
  UserJobRoleStatuses,
  GetUserRoleModes,
  NotificationCategory,
  SocialPostType,
  ContentType,
  RelationTypes,
  UserMetadataKey,
}
