import { useQuery } from '@tanstack/react-query'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { LinkDto } from '@inphiz-shared/shared-models/src/F_Links/link-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

export interface ApplicationListProps {
  title: string
  data: ApplicationType[]
}

const MyApplicationType = new DataCollectionApiResponse<LinkDto>()
type MyApplicationProps = typeof MyApplicationType

const LinkDtoType = new LinkDto()
export type ApplicationType = typeof LinkDtoType

export function useMyApplication() {
  return useQuery<MyApplicationProps>({
    queryKey: [QueryKey.myApplication],
    queryFn: () => APIFunctions.GET(APIConst.myApplication),
  })
}
