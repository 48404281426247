/**
 * Example usage:
 *
 * const builder = new QueryBuilder('https://example.com', 'api', 'v1');
 * builder.add('queryParam', 'value').addHeader('Authorization', 'Bearer token');
 * builder.addSegment('users').addSegment('123');
 * console.log(builder.toString()); // Outputs: https://example.com/api/v1/users/123?queryParam=value
 */

export class QueryBuilder {
  private inputBasePath?: string
  private inputBasePathSegments?: string[]
  private compiled: boolean = false
  private compiledUrl: string = ''
  private inputDynamicPathSegments?: any[]
  private queryParametersStorage: Map<string, string[]> = new Map()
  private headersStorage: Map<string, string> = new Map()
  private baseUrl: string | null = null
  private inputBaseUri?: URL

  /**
   * Constructor for creating a new QueryBuilder instance.
   * @param inputBaseUriOrBasePathOrSegments - Either a base URL, a base path string, or an array of base path segments.
   * @param basePathSegments - Additional path segments, if the first parameter is a URL.
   */
  constructor(inputBaseUri: URL)
  constructor(basePath: string)
  constructor(inputBaseUri: URL, ...basePathSegments: string[])
  constructor(...basePathSegments: string[])
  constructor(
    inputBaseUriOrBasePathOrSegments?: URL | string | string[],
    ...basePathSegments: string[]
  ) {
    if (inputBaseUriOrBasePathOrSegments instanceof URL) {
      this.inputBaseUri = inputBaseUriOrBasePathOrSegments
      if (basePathSegments.length > 0) {
        this.inputBasePathSegments = basePathSegments
      }
    }
    else if (typeof inputBaseUriOrBasePathOrSegments === 'string') {
      this.inputBasePath = inputBaseUriOrBasePathOrSegments
    }
    else if (Array.isArray(inputBaseUriOrBasePathOrSegments)) {
      this.inputBasePathSegments = inputBaseUriOrBasePathOrSegments
    }
  }

  /**
   * Gets the values of a query parameter by name.
   * @param name - The name of the query parameter.
   * @returns The values of the query parameter, or undefined if not found.
   */
  get(name: string): string[] | undefined {
    return this.queryParametersStorage.get(name)
  }

  /**
   * Sets a query parameter.
   * @param name - The name of the parameter.
   * @param value - The value of the parameter.
   * @returns The QueryBuilder instance (for chaining).
   */
  set(name: string, value: any): QueryBuilder {
    this.add(name, value)
    return this
  }

  /**
   * Adds a query parameter if the condition is true.
   * @param name - The name of the parameter.
   * @param value - The value of the parameter.
   * @param condition - Whether to add the parameter or not (default: true).
   * @returns The QueryBuilder instance (for chaining).
   */
  add(name: string, value: any, condition: boolean = true): QueryBuilder {
    if (condition) {
      this.addQueryParameter(name, value)
    }
    return this
  }

  /**
   * Adds an HTTP header.
   * @param fieldName - The name of the header.
   * @param fieldValue - The value of the header.
   * @returns The QueryBuilder instance (for chaining).
   */
  addHeader(fieldName: string, fieldValue: any): QueryBuilder {
    this.headersStorage.set(fieldName, fieldValue.toString())
    this.compiled = false
    return this
  }

  /**
   * Gets all headers as a read-only map.
   * @returns A read-only map of headers.
   */
  get headers(): Readonly<Map<string, string>> {
    return new Map(this.headersStorage)
  }

  /**
   * Adds multiple query parameters from an object.
   * @param obj - The object containing key-value pairs to be added as query parameters.
   * @returns The QueryBuilder instance (for chaining).
   */
  addAsQueryParameters(obj: Record<string, any>): QueryBuilder {
    const parameters = this.asKeyValues(obj)

    parameters.forEach(([key, value]) => {
      this.addQueryParameter(key, value, false)
    })

    return this
  }

  /**
   * Converts an object to key-value pairs for query parameters.
   * @param obj - The object to convert.
   * @returns An array of key-value pairs.
   */
  private asKeyValues(obj: Record<string, any>): [string, string][] {
    const result: [string, string][] = []
    if (obj == null) {
      return result
    }

    for (const key of Object.keys(obj)) {
      const value = obj[key]
      if (value != null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            result.push([key, encodeURIComponent(item.toString())])
          })
        }
        else if (typeof value === 'object' && !(value instanceof URL)) {
          // Handle nested objects or dictionaries if necessary
        }
        else {
          result.push([key, encodeURIComponent(value.toString())])
        }
      }
    }

    return result
  }

  /**
   * Adds a segment to the path.
   * @param segment - The path segment to add.
   * @param condition - Whether to add the segment or not (default: true).
   * @returns The QueryBuilder instance (for chaining).
   */
  addSegment(segment: any, condition: boolean = true): QueryBuilder {
    if (condition) {
      this.addPathSegment(segment)
    }
    return this
  }

  /**
   * Adds a query parameter to the internal storage.
   * @param key - The key of the query parameter.
   * @param value - The value of the query parameter.
   * @param encode - Whether to encode the value (default: true).
   */
  private addQueryParameter(key: string, value: any, encode: boolean = true) {
    if (!key) {
      throw new Error('Key cannot be null or empty')
    }

    if (value != null) {
      if (encode) {
        key = encodeURIComponent(key)
        value = encodeURIComponent(value.toString())
      }

      const existingValues = this.queryParametersStorage.get(key)
      if (existingValues) {
        existingValues.push(value.toString())
      }
      else {
        this.queryParametersStorage.set(key, [value.toString()])
      }
      this.compiled = false
    }
  }

  /**
   * Adds a path segment to the internal storage.
   * @param segment - The segment to add.
   */
  private addPathSegment(segment: any) {
    if (segment == null) {
      return
    }

    if (!this.inputDynamicPathSegments) {
      this.inputDynamicPathSegments = []
    }

    this.inputDynamicPathSegments.push(segment)
    this.compiled = false
  }

  /**
   * Iterator for the query parameters.
   * @returns An iterator for the query parameters.
   */
  [Symbol.iterator](): Iterator<any> {
    return this.queryParametersStorage.entries()
  }

  /**
   * Compiles and returns the full URL string.
   * @param baseUrl - The base URL to use.
   * @returns The compiled URL as a string.
   */
  toString(baseUrl?: string): string {
    if (!baseUrl && this.inputBaseUri) {
      baseUrl = this.inputBaseUri.toString()
    }

    if (this.baseUrl !== baseUrl) {
      this.compiled = false
    }

    if (!this.compiled) {
      try {
        const host = baseUrl
        const path0 = this.inputBasePath
        const path1 = this.inputBasePathSegments
          ?.filter(x => !!x)
          .map(x => encodeURIComponent(x!))
          .join('/')
        const path2 = this.inputDynamicPathSegments
          ?.filter(x => !!x)
          .map(x => encodeURIComponent(x.toString()))
          .join('/')
        const query = Array.from(this.queryParametersStorage.entries())
          .map(([key, values]) => values.map(value => `${key}=${value}`).join('&'))
          .join('&')

        const compiled = [host, path0, path1, path2]
          .filter((x): x is string => !!x)
          .map(x => x.replace(/^\/|\/$/g, ''))
          .join('/')

        this.baseUrl = baseUrl || null
        this.compiled = true
        this.compiledUrl = query ? `${compiled}?${query}` : compiled
      }
      catch (e) {
        console.error(e)
      }
    }

    return this.compiledUrl
  }
}
