import { useTranslation } from 'react-i18next'
import type { KeyboardEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomPagination, CustomTable, CustomText, Spinner } from '../../component'
import { CloseIcon, SearchIcon } from '../../icons'
import { useRolePositions } from '../../packages/api-web/RoleManagement'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: any[]
}

function RolePositionList() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [searchTxt, setSearchTxt] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [selectedItem, setSelectedItem] = useState()
  const { isLoading, data, refetch } = useRolePositions({
    itemPerPage,
    skip: skipItem,
    searchTxt,
  })

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  const tableHeaderList = useMemo(
    () => [
      { title: t('positionType'), id: 1 },
      { title: t('shortDescription'), id: 2 },
      { title: t('created'), id: 3 },
    ],
    [t],
  )

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      refetch()
  }

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('positions')}</CustomText>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['title', 'description', 'createdTime']}
              onClick={(item) => {
                navigate(`/role_management/position/job-title/${item.handle}`)
              }}
            />
          </div>
          {data && data?.items && data?.items.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RolePositionList
