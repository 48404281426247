import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'

export interface NewGroupItem {
  title: string
  description: string
  allowedGroupSubscribers: number
  subscriptionFlowType: number
  createdTime: string
  modifiedTime: string
  isDeleted: boolean
  handle: string
  contentType: string
}

const FeedbackGroupDataDto = new DataCollectionApiResponse<NewGroupItem>()
type FeedbackGroupData = typeof FeedbackGroupDataDto

export function useNewCategoryList() {
  return useQuery<FeedbackGroupData>({
    queryKey: [QueryKey.newsCategory],
    queryFn: async () =>
      APIFunctions.GET(
        `${APIConst.newCategory}?UseContinuationToken=False&Cursor=0&Take=2147483647&IncludeChildCount=0&OrderByProperty=Title&IsOrderAscending=True&OnlyCount=False&ParametersWithValues[HidePersonalGroups]=True&ParametersWithValues[AllowedSubscriberContentType]=news`,
      ),
  })
}
