import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { JobTitleItem } from './useRolePositions'

interface JobTitleRelationsInfoParams {
  isJobTitle?: boolean
}

export function usePostJobTitleRelationsInfo({ isJobTitle }: JobTitleRelationsInfoParams) {
  return useMutation({
    mutationFn: async (data: JobTitleItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(
        isJobTitle
          ? APIConst.employeesPerformance.jobTitlesRelationsInfo
          : APIConst.employeesPerformance.jobRolesRelationsInfo,
        params,
      )
      return result
    },
  })
}
