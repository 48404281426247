import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { JobRequirementTypeDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/job-requirement-type-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const JobRequirementTypeItemDto = new JobRequirementTypeDto()
export type JobRequirementTypeItem = typeof JobRequirementTypeItemDto

const RoleTypeDto = new DataCollectionApiResponse<JobRequirementTypeItem>()
export type RoleRequirementTypeData = typeof RoleTypeDto

export function useJobType() {
  return useQuery<RoleRequirementTypeData>({
    queryKey: [QueryKey.roleRequirementType],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.jobRequirementTypes)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
