import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { OrganizationUnitDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/organization-unit-dto'

const OrganizationUnitItemDto = new OrganizationUnitDto()
export type OrganizationUnitItem = typeof OrganizationUnitItemDto

const OrganizationUnitsDto = new DataCollectionApiResponse<OrganizationUnitItem>()
export type OrganizationUnitData = typeof OrganizationUnitsDto

export function useOrganizationUnits() {
  return useQuery<OrganizationUnitData>({
    queryKey: [QueryKey.organizationUnit],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.getOrganizationUnit).add('take', 2147483647)
      const result = await APIFunctions.GET(qb.toString())
      return {
        ...result,
        items: result.items.sort((a: { title: string }, b: { title: string }) =>
          a.title.localeCompare(b.title),
        ),
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
