import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import type { JobRequirementItem } from './useJobRequirements'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.roleRequirements] })
}

interface JobRequirementParams {
  handle?: string
  isJobTitle?: boolean
}

export function usePostJobRequirement({ handle, isJobTitle }: JobRequirementParams) {
  return useMutation({
    mutationFn: async (data: JobRequirementItem[]) => {
      const params = {
        requirements: data,
      }
      const result = await APIFunctions.POST(
        `${isJobTitle ? APIConst.employeesPerformance.getJobTitles : APIConst.employeesPerformance.getJobRolesLeadership}/${handle}/requirements`,
        params,
      )
      return result
    },
    onSuccess,
  })
}
