import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useRef, useState } from 'react'
import { ChatBubbleView, ChatEmpty } from '../../molecules'
import { CustomText, TextWithIcon } from '../../atoms'
import { SendMessage } from '../../../icons'
import { UserContext } from '../../../contexts/UserContext'
import { usePostMessage } from '../../../packages/api-web'
import type { History } from '../../../database'
import { Stores, addDB, getStoreData, updateDB } from '../../../database'
import type { HistoryListItem } from '../HistoryList'

interface IMessage {
  id: string
  text: string
  createdAt: Date | number
  user: {
    id: string
  }
}
interface ChatListProps {
  historyItem?: HistoryListItem
  isDashboard?: boolean
}

export function ChatList({ historyItem, isDashboard = false }: ChatListProps) {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const { mutate, isPending } = usePostMessage()
  const [messages, setMessages] = useState<IMessage[]>([])
  const [historyId, setHistoryId] = useState<number>(0)
  const [sendMessage, setSendMessage] = useState<string>('')
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const event = new CustomEvent('updateHistory')

  useEffect(() => {
    if (historyItem) {
      const parseData = JSON.parse(historyItem.data.chats)
      const sortData = parseData.sort(
        (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      )
      setMessages(sortData)
      setHistoryId(historyItem.data.id)
    }
    else {
      setMessages([])
      setHistoryId(0)
    }
  }, [historyItem])

  useEffect(() => {
    if (isPending) {
      const array = {
        id: 'ID_0',
        text: t('thinkingAnswer'),
        createdAt: new Date(),
        user: {
          id: '',
        },
      }
      setMessages(oldArray => [...oldArray, array])
    }
  }, [isPending])

  const renderItem = (item: any, idx: string) => {
    return (
      <ChatBubbleView
        key={idx}
        item={item}
        userId={user?.userData?.handle ?? ''}
        isDashboard={isDashboard}
      />
    )
  }

  const chatEmptyView = () => (
    <ChatEmpty
      onPressMessage={async (item: string) => {
        const localHistoryData = await getStoreData<History>(Stores.History)
        const id = localHistoryData?.length + 1
        setHistoryId(id)
        addMessageDB(id, item)
        postMessageToServer(item, id)
      }}
    />
  )

  const postMessageToServer = (message: string, id: number) => {
    const newmsg = {
      id: `${new Date().getTime()}`,
      text: message,
      createdAt: new Date(),
      user: {
        id: user?.userData?.handle ?? '',
      },
    }
    const pastMsg: IMessage[] = [...messages].slice(0, 10)
    setMessages(oldArray => [...oldArray, newmsg])
    const requestParams = {
      question: message,
      history: pastMsg.reverse().map(i => (i.user.id ? `user: ${i.text}` : `assistant: ${i.text}`)),
    }
    mutate(requestParams, {
      onSuccess: async (data) => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        const newmsg = {
          id: `${new Date().getTime()}`,
          text: data?.answer,
          createdAt: new Date(),
          user: {
            id: '',
          },
        }
        const localHistoryData = await getStoreData<History>(Stores.History)
        setMessages((previousMessages) => {
          const filter = previousMessages.filter(obj => obj.id !== 'ID_0')
          const array = [...[newmsg], ...filter]
          const toUpdate = localHistoryData.filter((obj: History) => obj?.id === id)
          if (toUpdate && toUpdate.length > 0) {
            const data = {
              id,
              question: toUpdate[0].question,
              chats: JSON.stringify(array),
              createdDate: `${toUpdate[0].createdDate}`,
            }
            updateData(id, data)
          }
          return [...filter, newmsg]
        })
      },
    })
  }

  const updateData = async (id: number, data: any) => {
    await updateDB(Stores.History, id, data)
    window.dispatchEvent(event)
  }

  const onSend = async () => {
    let idForHistory
    if (messages.length > 0) {
      idForHistory = historyId
    }
    else {
      const localHistoryData = await getStoreData<History>(Stores.History)
      const id = localHistoryData?.length + 1
      setHistoryId(id)
      idForHistory = id
      addMessageDB(id, sendMessage)
    }
    setSendMessage('')
    postMessageToServer(sendMessage, idForHistory)
  }

  const addMessageDB = async (id: number, text: string) => {
    try {
      const data = {
        id,
        question: text,
        chats: JSON.stringify(messages),
        createdDate: `${new Date().getTime()}`,
      }
      await addDB(Stores.History, data)
      window.dispatchEvent(event)
    }
    catch (err: unknown) {
      if (err instanceof Error) {
        console.log('add data : ', err.message)
      }
      else {
        console.log('Something went wrong')
      }
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 border border-stroke rounded-md m-5 overflow-y-auto scrollbar">
        <div className="flex flex-1" ref={messagesEndRef}>
          <ul className="flex flex-col-reverse flex-1 pt-4">
            <FlatList
              list={messages}
              renderItem={renderItem}
              reversed
              renderWhenEmpty={() => {
                if (isDashboard) {
                  return (
                    <div className="flex flex-col flex-1 justify-center items-center text-center px-3">
                      <CustomText variant="smPoppinsSemiBold">{t('giveAnswersTime')}</CustomText>
                      <CustomText className="pt-2" variant="xsmPoppinsRegular">
                        {t('giveAnswersDes')}
                      </CustomText>
                    </div>
                  )
                }
                else {
                  return chatEmptyView()
                }
              }}
            />
          </ul>
        </div>
      </div>
      <div className="border border-stroke mx-5 mb-5 p-4 rounded-md">
        <TextWithIcon
          leftIcon={(
            <input
              type="text"
              className="w-full mr-5 pl-1 outline-white"
              placeholder={t('wantToAskToday')}
              value={sendMessage}
              onChange={(e) => {
                setSendMessage(e.target.value)
              }}
            />
          )}
          rightIcon={(
            <button
              onClick={() => {
                if (sendMessage?.length > 0) {
                  onSend()
                }
              }}
            >
              <SendMessage className="fill-secondary" />
            </button>
          )}
        />
      </div>
    </>
  )
}
