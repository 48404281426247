import { CustomText } from '../CustomText'

export interface CustomTextAreaProps extends React.ComponentPropsWithoutRef<'textarea'> {
  className?: string
  title?: string
}

export function CustomTextArea({ title, className, ...props }: CustomTextAreaProps) {
  return (
    <div className="flex flex-col">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <textarea
        rows={3}
        cols={40}
        className={`mt-3 py-1.5 px-3 block rounded-lg border-0 text-gray-900 outline outline-1 outline-stroke focus:outline-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${className}`}
        {...props}
      />
    </div>
  )
}
