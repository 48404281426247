import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'

interface ManagerFilterListParam {
  isSuccess: boolean
  items: {
    displayName: string
    handle: string
  }[]
}

export function useManagerFilterList({ filterTxt = '' }) {
  return useQuery<ManagerFilterListParam>({
    queryKey: [QueryKey.managerFilterList],
    queryFn: async () => {
      const result = await APIFunctions.POST(APIConst.managerList, {
        take: 20,
        filter: filterTxt,
      })
      return {
        ...result,
        items: result.items.sort((a: { displayName: string }, b: { displayName: string }) =>
          a.displayName.localeCompare(b.displayName),
        ),
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
