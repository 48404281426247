import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import type { EquipmentCategoryItem } from './useEquipmentCategory'

function onCategorySuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.equipmentCategory] })
}

export function usePostCategory() {
  return useMutation({
    mutationFn: async (data: EquipmentCategoryItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.equipment.getEquipmentCategory, params)
      return result
    },
    onSuccess: onCategorySuccess,
  })
}
