import { SharedStorage } from '@inphiz/core-utilities/src/Storage/SharedStorage'
import { SharedSecureStorage } from '@inphiz/core-utilities/src/Storage/SharedSecureStorage'
import { SecureStorageImpl } from '@inphiz/core-utilities-web/src/Storage/SecureStorageImpl'
import { StorageImpl } from '@inphiz/core-utilities-web/src/Storage/StorageImpl'
import { ApiSharedAuthProviderInstance } from '@inphiz/api/src/Networking/IApiSharedAuthProvider'
import { ApiWebAuthProvider } from '@inphiz/api-web/src/Networking/ApiWebAuthProvider'

export default function initializeServices() {
  SharedStorage.setImplementation(new StorageImpl())
  SharedSecureStorage.setImplementation(new SecureStorageImpl())
  ApiSharedAuthProviderInstance.setImplementation(new ApiWebAuthProvider())
}
