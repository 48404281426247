import { useTranslation } from 'react-i18next'
import { CustomText } from '../CustomText'

export interface CustomInputProps extends React.ComponentPropsWithoutRef<'input'> {
  title?: string
  className?: string
}

export function CustomInput({ title, className = '', ...props }: CustomInputProps) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <input
        type="text"
        className={`mt-3 block rounded-lg border-0 py-1.5 p-3 text-gray-900 outline outline-1 outline-stroke focus:outline-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${className}`}
        placeholder={t('typeHere')}
        {...props}
      />
    </div>
  )
}
