export function RoundedRight() {
  return (
    <svg width={24} height={25} viewBox="0 0 24 25" fill="none">
      <path
        d="M5.90991 13.8156L8.35989 17.2926C8.45283 17.4315 8.57758 17.5457 8.72375 17.6266C8.86993 17.7075 9.03331 17.7525 9.20029 17.7575C9.36727 17.7625 9.53301 17.7274 9.68375 17.6554C9.83449 17.5834 9.96584 17.4764 10.0669 17.3434L17.9099 7.42056"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9099 23.8404C14.135 23.8404 16.31 23.1805 18.1601 21.9444C20.0101 20.7082 21.4521 18.9512 22.3036 16.8955C23.155 14.8399 23.3778 12.5778 22.9438 10.3955C22.5097 8.21325 21.4382 6.20862 19.8649 4.63528C18.2915 3.06194 16.287 1.99075 14.1047 1.55667C11.9224 1.12259 9.66037 1.34532 7.60471 2.19681C5.54904 3.04829 3.79204 4.49031 2.55588 6.34036C1.31971 8.19041 0.659912 10.3653 0.659912 12.5904C0.659912 15.574 1.84517 18.4357 3.95496 20.5454C6.06474 22.6552 8.92623 23.8404 11.9099 23.8404Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
