let request: IDBOpenDBRequest
let db: IDBDatabase
let version = 1 // also change in addDB.ts / updateDB.ts
export const dbName = 'myDB'

export interface History {
  id: number
  question: string
  chats: string
  createdDate: string
}

export enum Stores {
  History = 'history',
}

export function initDB(): Promise<boolean> {
  return new Promise((resolve) => {
    // open the connection
    request = indexedDB.open(dbName)

    request.onupgradeneeded = () => {
      db = request.result

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(Stores.History)) {
        console.log('Creating users store')
        db.createObjectStore(Stores.History, { keyPath: 'id' })
      }
      // no need to resolve here
    }

    request.onsuccess = () => {
      db = request.result
      version = db.version
      console.log('request.onsuccess - initDB', version)
      resolve(true)
    }

    request.onerror = () => {
      resolve(false)
    }
  })
}
