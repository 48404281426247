class AppInfo {
  private static instance: AppInfo
  private appVersion: string
  private platform: string
  private osType: string
  private osVersion: string
  private appBuild: string
  private appVersionInfo: string
  private bundleId: string

  // Private constructor to prevent instantiation from outside
  private constructor() {
    this.appVersion = ''
    this.platform = ''
    this.osType = ''
    this.osVersion = ''
    this.appBuild = ''
    this.appVersionInfo = ''
    this.bundleId = ''
  }

  // Static method to get the singleton instance
  public static getInstance(): AppInfo {
    if (!AppInfo.instance) {
      AppInfo.instance = new AppInfo()
    }
    return AppInfo.instance
  }

  // Methods to set app information
  public setAppVersion(version: string): void {
    this.appVersion = version
  }

  public setPlatform(platform: string): void {
    this.platform = platform
  }

  public setOsType(osType: string): void {
    this.osType = osType
  }

  public setOsVersion(osVersion: string): void {
    this.osVersion = osVersion
  }

  public setAppBuild(appBuild: string): void {
    this.appBuild = appBuild
  }

  public setAppVersionInfo(appVersionInfo: string): void {
    this.appVersionInfo = appVersionInfo
  }

  public setBundleId(bundleId: string): void {
    this.bundleId = bundleId
  }

  // Methods to get app information
  public getAppVersion(): string {
    return this.appVersion
  }

  public getPlatform(): string {
    return this.platform
  }

  public getOsType(): string {
    return this.osType
  }

  public getOsVersion(): string {
    return this.osVersion
  }

  public getAppBuild(): string {
    return this.appBuild
  }

  public getAppVersionInfo(): string {
    return this.appVersionInfo
  }

  public getBundleId(): string {
    return this.bundleId
  }

  // Method to get all info as an object, useful for sending in each HTTP request
  public getAllAppInfo(): Record<string, string> {
    return {
      appVersion: this.getAppVersion(),
      platform: this.getPlatform(),
      osType: this.getOsType(),
      osVersion: this.getOsVersion(),
      appBuild: this.getAppBuild(),
      appVersionInfo: this.getAppVersionInfo(),
      bundleId: this.getBundleId(),
    }
  }
}

export default AppInfo
