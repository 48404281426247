export function RoundCloseIcon() {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none">
      <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" />
      <path
        d="M13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5Z"
        stroke="#000000"
      />
      <path d="M10.2249 10.2246L15.7761 15.7759" stroke="#000000" />
      <path d="M15.776 10.2246L10.225 15.7756" stroke="#000000" />
    </svg>
  )
}
