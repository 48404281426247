import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme' // replaced from require('tailwindcss/defaultTheme')
import { AddComment, CommentItem, CustomText, DeleteAlert, SocialItem } from '../../component'
import type {
  Comments,
  PostCommentParams,
  Social,
  SocialDetails,
  UploadResponse,
} from '../../packages/api-web'
import {
  setCurrentUserCount,
  useDeleteLikeMutation,
  useDeletePost,
  useGetCommentByHandle,
  usePostCommentMutation,
  usePostLikeMutation,
  usePostReadMutation,
  useSocialDetails,
  useUploadMedia,
} from '../../packages/api-web'
import { ContentType } from '../../helpers'
import { UserContext } from '../../contexts/UserContext'
import { useDeleteComment } from '../../packages/api-web/Social/useDeleteComment'
import { CloseIcon } from '../../icons'

interface SocialDetailsProps {
  socialHandle: string
  typeHandle: string | undefined
  onDeleteSuccess?: () => void
}

interface Images {
  uri: string
  type: string | null
  name: string | null
  createdTime?: string
}

function SocialDetailsScreen({ socialHandle, typeHandle, onDeleteSuccess }: SocialDetailsProps) {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const { state } = useLocation()
  const { isFetching, data, refetch } = useSocialDetails(
    state?.socialHandle ? state?.socialHandle : socialHandle,
  )
  const { data: commentList } = useGetCommentByHandle(
    state?.socialHandle ? state?.socialHandle : socialHandle,
  )
  const { mutate: deleteLikePost } = useDeleteLikeMutation(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: likePost } = usePostLikeMutation(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: deleteCommentMutate } = useDeleteComment(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const {
    mutate: postComment,
    isPending,
    isSuccess,
  } = usePostCommentMutation(state?.typeHandle ? state?.typeHandle : typeHandle)
  const { mutate: deletePostMutate, isPending: isDelete } = useDeletePost(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: confirmReadMutate } = usePostReadMutation(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: uploadMediaMute } = useUploadMedia()
  const [socialDetails, setSocialDetails] = useState<SocialDetails | undefined>(undefined)
  const [tempReplyComment, setTempReplyComment] = useState<Comments>()
  const [tempCommentTxt, setTempCommentTxt] = useState('')
  const [replyComment, setReplyComment] = useState<Comments>()
  const [deleteComment, setDeleteComment] = useState<Comments>()
  const [deletePostItem, setDeletePostItem] = useState<Social | SocialDetails>()
  const [tempImages, setTempImages] = useState()
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [commentImage, setCommentImage] = useState()

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (socialHandle || state?.socialHandle) {
      refetch()
    }
  }, [socialHandle, state?.socialHandle])

  useEffect(() => {
    if (data) {
      setSocialDetails(data)
      if (data.actionsInfo) {
        setCurrentUserCount(
          state?.socialHandle ? state?.socialHandle : socialHandle,
          state?.typeHandle ? state?.typeHandle : typeHandle,
          data.actionsInfo?.currentCount,
        )
      }
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      setTempCommentTxt('')
      setTempReplyComment(undefined)
      setTempImages(undefined)
    }
    if (isPending) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [isPending, isSuccess])

  const onClickLike = () => {
    if (socialDetails?.metadata?.IsLikedByUser == 'True') {
      const data = {
        ownerHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
        contentType: ContentType.like,
        ownerContentType: ContentType.groupwallpost,
        authorHandle: '@me',
        userHandle: user.userData.handle,
      }
      deleteLikePost(data)
      return
    }
    const data = {
      ownerHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
      ownerContentType: ContentType.groupwallpost,
      authorHandle: '@me',
      contentType: ContentType.like,
    }
    likePost(data)
  }

  const onSend = (message: string) => {
    const requestParams: FormData = new FormData()
    if (commentImage) {
      requestParams.append('files', commentImage)
    }
    const data: PostCommentParams = {
      content: message,
      ownerHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
      ownerContentType: ContentType.groupwallpost,
      authorHandle: '@me',
      parentCommentHandle: replyComment?.handle || undefined,
    }
    if (commentImage) {
      uploadMediaMute(requestParams, {
        onSuccess: (response: UploadResponse) => {
          if (response.isSuccess) {
            data.coverMediaHandles = response.items?.map(i => i.handle)?.filter(Boolean) as string[]
            postComment(data)
          }
        },
      })
    }
    else {
      postComment(data)
    }
    setTempCommentTxt(message)
    setCommentImage(undefined)
    setReplyComment(undefined)
  }

  if ((isFetching && !socialDetails) || isDelete)
    return null

  return (
    <div className="h-full flex">
      <div className="relative flex flex-1 flex-col bg-white border border-stroke rounded-lg">
        <div className="flex flex-col flex-1 overflow-y-auto scrollbar">
          <SocialItem
            item={socialDetails}
            onClickLike={onClickLike}
            onPressDelete={() => {
              setDeletePostItem(socialDetails)
              setIsShowAlert(true)
            }}
            onReadConfirmed={(requestObj) => {
              const newObj = {
                ...requestObj,
                value: 'True',
              }
              confirmReadMutate(newObj)
            }}
          />
          <div ref={messagesEndRef}>
            {socialDetails
            && commentList
            && commentList?.items
              .slice()
              .reverse()
              ?.map((comment, index) => {
                return (
                  <CommentItem
                    key={index}
                    item={comment}
                    isPending={isPending}
                    pendingItem={{
                      handle: tempReplyComment ? tempReplyComment.handle : '',
                      isChildData: !!tempReplyComment,
                      content: tempCommentTxt,
                      createdTime: new Date(),
                      author: {
                        displayName: user?.userData?.displayName,
                        profileImage: user?.userData?.profileImage,
                        handle: user?.userData?.handle,
                      },
                      metadata: {
                        IsLikedByUser: 'False',
                      },
                      likeCount: 0,
                      childs: [],
                    }}
                    onLike={(item: Comments) => {
                      if (item?.metadata?.IsLikedByUser == 'True') {
                        const data = {
                          ownerHandle: item?.handle ?? '',
                          contentType: ContentType.like,
                          ownerContentType: ContentType.comment,
                          authorHandle: '@me',
                          userHandle: user.userData.handle,
                          itemHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
                          parentCommentHandle: item?.parentCommentHandle
                            ? item?.parentCommentHandle
                            : undefined,
                        }
                        deleteLikePost(data)
                      }
                      else {
                        const data = {
                          ownerHandle: item?.handle ?? '',
                          contentType: ContentType.like,
                          ownerContentType: ContentType.comment,
                          authorHandle: '@me',
                          itemHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
                          parentCommentHandle: item?.parentCommentHandle
                            ? item?.parentCommentHandle
                            : undefined,
                        }
                        likePost(data)
                      }
                    }}
                    onChildComment={(item: Comments) => {
                      setReplyComment(item)
                      setTempReplyComment(item)
                    }}
                    onMore={(item: Comments) => {
                      setDeleteComment(item)
                      setIsShowAlert(true)
                    }}
                  />
                )
              })}
            {isPending && !tempReplyComment && (
              <div style={{ opacity: 0.5 }}>
                <CommentItem
                  item={{
                    content: tempCommentTxt,
                    createdTime: new Date(),
                    author: {
                      displayName: user?.userData?.displayName,
                      profileImage: user?.userData?.profileImage,
                      handle: user?.userData?.handle,
                    },
                    metadata: {
                      IsLikedByUser: 'False',
                    },
                    likeCount: 0,
                    childs: [],
                    coverMedia: tempImages ? [{ url: URL.createObjectURL(tempImages) }] : [],
                  }}
                />
              </div>
            )}
            {isShowAlert && (
              <DeleteAlert
                title={t('deleteComment')}
                subTitle={t('deleteCommentDetails')}
                leftButtonText={t('cancel')}
                rightButtonText={t('deletePosts')}
                onCloseAlert={() => {
                  setIsShowAlert(false)
                  setDeleteComment(undefined)
                  setDeletePostItem(undefined)
                }}
                onLeftButton={() => {
                  setDeleteComment(undefined)
                  setDeletePostItem(undefined)
                }}
                onRightButton={() => {
                  if (deleteComment) {
                    const data = {
                      ownerHandle: state?.socialHandle ? state?.socialHandle : socialHandle,
                      contentType: ContentType.comment,
                      ownerContentType: ContentType.groupwallpost,
                      authorHandle: '@me',
                      handle: deleteComment?.handle,
                      parentCommentHandle: deleteComment?.parentCommentHandle
                        ? deleteComment?.parentCommentHandle
                        : undefined,
                    }
                    deleteCommentMutate(data)
                  }
                  if (deletePostItem) {
                    const data = {
                      type: 'default',
                      handle: deletePostItem?.handle,
                      contentType: ContentType.groupwallpost,
                    }
                    deletePostMutate(data, {
                      onSuccess: () => {
                        if (
                          screenWidth
                          < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                        ) {
                          navigate(-1)
                        }
                        else {
                          onDeleteSuccess?.()
                        }
                      },
                    })
                  }
                  setTimeout(() => {
                    setIsShowAlert(false)
                  }, 250)
                }}
              />
            )}
          </div>
          <div className="flex-1"></div>
        </div>
        <div
          className={`py-2 items-center justify-center flex px-5 flex-col ${commentList && commentList?.items.length > 0 ? 'border-t border-t-stroke' : ''}`}
        >
          {replyComment && (
            <div className="mb-2 pb-2 w-full flex items-center border-b border-b-stroke">
              <CustomText variant="smPoppinsRegular">{t('replyingTo')}</CustomText>
              <CustomText variant="smPoppinsBold" className="flex-1">
                {` ${replyComment?.author?.displayName}`}
              </CustomText>
              <CustomText
                variant="smPoppinsBold"
                onClick={() => {
                  setReplyComment(undefined)
                }}
              >
                ✕
              </CustomText>
            </div>
          )}
          {commentImage && (
            <div className="h-16 w-16 mb-2 self-start relative">
              <img src={URL.createObjectURL(commentImage)} className="h-16 w-16 rounded-lg" />
              <div
                className="bg-white absolute -top-1 -right-1 rounded-full p-1 border"
                onClick={() => {
                  setCommentImage(undefined)
                }}
              >
                <CloseIcon className="h-2 w-2" />
              </div>
            </div>
          )}
          <AddComment
            onTapPublish={(message) => {
              onSend(message)
            }}
            selectedImage={(item) => {
              setCommentImage(item)
              setTempImages(item)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SocialDetailsScreen
