import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export function useCampaignDelete() {
  return useMutation({
    mutationFn: async (handle: string) =>
      APIFunctions.DELETE(`${APIConst.createCampaign}/${handle}`, {}),
  })
}
