import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'

export interface FeedbackGroupItem {
  title: string
  description: string
  ownerHandle: any
  ownerContentType: any
  authorHandle: any
  author: any
  allowedGroupSubscribers: number
  subscriptionFlowType: number
  permissions: any
  createdTime: string
  modifiedTime: string
  isDeleted: boolean
  deletedTime: any
  handle: string
  contentType: string
}

const FeedbackGroupDataDto = new DataCollectionApiResponse<FeedbackGroupItem>()
type FeedbackGroupData = typeof FeedbackGroupDataDto

export function useFeedbackGroupList() {
  return useQuery<FeedbackGroupData>({
    queryKey: [QueryKey.feedbackGroup],
    queryFn: () =>
      APIFunctions.GET(
        `${APIConst.getFeedbackGroups}?UseContinuationToken=False&Cursor=0&Take=2147483647&IncludeChildCount=0&OrderByProperty=Title&IsOrderAscending=True&OnlyCount=False&ParametersWithValues[HidePersonalGroups]=True&ParametersWithValues[AllowedSubscriberContentType]=feedback-campaign`,
      ),
    staleTime: Number.POSITIVE_INFINITY,
  })
}
