import { useQuery } from '@tanstack/react-query'
import { CommentModelDto } from '@inphiz-shared/shared-models/src/Dto/comment-model-dto'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export interface CommentsData {
  readonly items: ReadonlyArray<Comments>
}

const CommentsType = new CommentModelDto()
export type Comments = typeof CommentsType

export function useGetCommentByHandle(handle: string) {
  return useQuery<CommentsData>({
    queryKey: [QueryKey.comments, handle],
    queryFn: () => APIFunctions.GET(`${APIConst.getComments}?ChildOf=${handle}`),
  })
}
