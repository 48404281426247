import { useQuery } from '@tanstack/react-query'
// import { CommentModelDto } from '@inphiz-shared/shared-models/src/Dto/comment-model-dto';
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { NewsList } from '../News'

interface DashboardNewsListParams {
  itemPerPage?: number
  skip?: number
}

export function useDashboardNewsList({ itemPerPage = 20, skip = 0 }: DashboardNewsListParams) {
  return useQuery<NewsList>({
    queryKey: [QueryKey.dashboardNews],
    queryFn: () => {
      const url = `?cursor=${skip}&take=${itemPerPage}&isorderascending=False&parameters=status&parameters=Draft,Published,null`
      return APIFunctions.GET(APIConst.getNews + url)
    },
    enabled: false,
  })
}
