import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { EquipmentCategoryDto } from '@inphiz-shared/shared-models/src/F_Equipment/equipment-category-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'

const EquipmentCategoryItemDto = new EquipmentCategoryDto()
export type EquipmentCategoryItem = typeof EquipmentCategoryItemDto

const EquipmentCategoryListDto = new DataCollectionApiResponse<EquipmentCategoryItem>()
export type EquipmentCategoryList = typeof EquipmentCategoryListDto

export function useEquipmentCategory() {
  return useQuery<EquipmentCategoryList>({
    queryKey: [QueryKey.equipmentCategory],
    queryFn: async () => {
      const result = await APIFunctions.GET(
        `${APIConst.equipment.getEquipmentCategory}?take=2147483647`,
      )
      return {
        ...result,
        items: result?.items
          ?.filter((i: EquipmentCategoryItem) => i?.parentEquipmentCategoryHandle == null)
          ?.sort((a: EquipmentCategoryItem, b: EquipmentCategoryItem) =>
            a?.title?.localeCompare(b?.title ?? ''),
          ),
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
