import { extractChatMessage } from '../../../helpers'
import { AIChatIcon } from '../../../icons'
import { CustomText } from '../../atoms'

interface ChatBubbleViewProps {
  item: any
  userId: string
  isDashboard?: boolean
}

export function ChatBubbleView({ item, userId, isDashboard }: ChatBubbleViewProps) {
  const commonClasses = 'pb-4 w-4/5'
  const rightView = `${isDashboard ? 'py-1 mr-2' : 'py-3 mr-6'} px-4 rounded-md bg-bgColor shadow-md`
  const leftView = `${isDashboard ? 'py-1 ml-5' : 'py-3 ml-8'} px-4 rounded-md bg-white border border-stroke shadow-md`

  return (
    <li className={`${commonClasses} ${item?.user?.id === userId && 'ml-auto'} cursor-pointer`}>
      <div className={item?.user?.id === userId ? rightView : leftView}>
        {item?.user?.id !== userId && <AIChatIcon />}
        <CustomText className="my-2" variant="smPoppinsRegular">
          {extractChatMessage(item.text).remaining}
        </CustomText>
        {item?.user?.id !== userId && extractChatMessage(item.text).extracted.length > 0 && (
          <>
            <CustomText className="mb-2" variant="smPoppinsSemiBold">
              Reference:
            </CustomText>
            {extractChatMessage(item.text).extracted.map((i, index) => {
              return (
                <div className="p-2 mb-2 last:mb-0 bg-bgColor rounded-lg" key={index}>
                  <CustomText variant="smPoppinsRegular">{i}</CustomText>
                </div>
              )
            })}
          </>
        )}
      </div>
    </li>
  )
}
