import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { EquipmentItem } from './useEquipmentList'

export function useEquipmentDelete() {
  return useMutation({
    mutationFn: async (equipment: EquipmentItem) =>
      APIFunctions.DELETE(`${APIConst.equipment.deleteEquipment}`, { item: equipment }),
  })
}
