import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { News } from './newList'

export function useDeleteNew() {
  return useMutation({
    mutationFn: async (newObj: News) =>
      APIFunctions.DELETE(`${APIConst.createNew}`, { item: newObj }),
  })
}
