export class MenuItem {
  public title: string = ''
  public icon: JSX.Element = (<></>)
  public value: string = ''
  public id: number = -1
  public items: MenuItem[] = []
  public isVisible: boolean = true
  public isEnabled: boolean = true

  constructor(init?: Partial<MenuItem>) {
    Object.assign(this, init)
  }
}
