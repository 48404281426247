import * as React from 'react'

export function SendMessage({ className = '' }) {
  return (
    <svg width={22} height={20} viewBox="0 0 22 20" fill="none">
      <path
        d="M21.6333 9.29948C21.6367 9.20215 21.6126 9.10608 21.5644 9.02478C21.5162 8.94348 21.4463 8.88104 21.3646 8.84623L1.05149 0.233666C0.972969 0.202802 0.888369 0.196389 0.80682 0.215127C0.725272 0.233865 0.649868 0.277038 0.588745 0.339988C0.527622 0.402937 0.483107 0.483279 0.459993 0.572332C0.436879 0.661386 0.436045 0.755771 0.457588 0.845311L2.27993 7.86759C2.29056 7.91368 2.31371 7.95498 2.34623 7.98586C2.37875 8.01674 2.41904 8.0357 2.46159 8.04013L14.0079 9.15594C14.062 9.16003 14.1125 9.1873 14.1489 9.232C14.1852 9.27671 14.2046 9.33536 14.2029 9.39564C14.2012 9.45732 14.1797 9.51628 14.1423 9.56181C14.1049 9.60735 14.0541 9.63645 13.999 9.64384L2.40189 11.2857C2.3585 11.292 2.3176 11.3119 2.28397 11.3431C2.25035 11.3743 2.22537 11.4154 2.21196 11.4618L0.128564 18.5753C0.103047 18.659 0.0989244 18.7489 0.116646 18.8351C0.134366 18.9212 0.173232 19.0002 0.228921 19.0632L0.240358 19.0759C0.301507 19.1374 0.377134 19.1782 0.458367 19.1934C0.5396 19.2087 0.623063 19.1979 0.698957 19.1622L21.3424 9.75769C21.4253 9.72049 21.4968 9.65749 21.5484 9.57623C21.6 9.49498 21.6294 9.3989 21.6333 9.29948Z"
        fill="#08A64A"
        className={className}
      />
    </svg>
  )
}
