import { CustomImg, CustomText } from '../../atoms'

export interface ActionsCardProps {
  className?: string
  userName?: string
  image?: string
  title?: string
  subTitle?: string
  status?: string
}

export function ActionsCard({
  className,
  userName,
  image,
  title,
  subTitle,
  status,
}: ActionsCardProps) {
  return (
    <div className="flex-1 flex border-y border-r border-stroke rounded-lg overflow-hidden">
      <div className={`w-2 ${className}`}></div>
      <div className="ml-4 py-3">
        <div className="flex items-center mb-4">
          <CustomImg
            name={userName}
            imgSrc={image}
            altText="user_image"
            className="h-9 w-9 rounded-full"
            imgClassName="rounded-full"
          />
          <CustomText variant="smPoppinsRegular" className="ml-2">
            {userName ?? ' '}
          </CustomText>
        </div>
        <CustomText variant="lgPoppinsSemiBold">{title ?? ' '}</CustomText>
        <CustomText variant="mdPoppinsRegular" className="mt-1 mb-2">
          {subTitle ?? ' '}
        </CustomText>
        <CustomText variant="smPoppinsRegular">{status ?? ' '}</CustomText>
      </div>
    </div>
  )
}
