export function EquipmentIcon({ className = '' }) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" className={className}>
      <path
        d="M8.808 11.42L7.76 10.376C6.9129 10.782 5.96079 10.9154 5.03475 10.7579C4.1087 10.6003 3.25424 10.1596 2.58904 9.49641C1.92383 8.83317 1.48059 7.97996 1.3203 7.05439C1.16001 6.12882 1.29055 5.17637 1.69399 4.32807L3.11898 5.74909C3.25882 5.89229 3.42568 6.00624 3.60988 6.08454C3.79408 6.16284 3.99198 6.20394 4.19213 6.20527C4.39228 6.2066 4.5907 6.16821 4.77593 6.09235C4.96115 6.0165 5.1295 5.90471 5.27123 5.76337C5.41296 5.62204 5.52527 5.454 5.60164 5.26899C5.67801 5.08398 5.71694 4.88565 5.71617 4.68549C5.71541 4.48534 5.67494 4.28727 5.59716 4.10285C5.51937 3.91843 5.4058 3.75127 5.26299 3.61103L3.83898 2.19099C4.68583 1.78597 5.63739 1.65331 6.56273 1.81123C7.48807 1.96914 8.34174 2.40999 9.00634 3.07294C9.67093 3.7359 10.1138 4.58843 10.274 5.51337C10.4342 6.43832 10.3039 7.39013 9.90099 8.23798L10.871 9.20502"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8199 14.136L17.2419 15.554C18.0888 15.1484 19.0406 15.0153 19.9663 15.1729C20.892 15.3304 21.746 15.7709 22.4111 16.4337C23.0762 17.0966 23.5195 17.9493 23.6801 18.8744C23.8407 19.7996 23.7107 20.7518 23.308 21.6L21.883 20.18C21.5984 19.9005 21.2151 19.7447 20.8163 19.7462C20.4175 19.7478 20.0355 19.9065 19.7531 20.1881C19.4707 20.4697 19.3108 20.8514 19.3081 21.2502C19.3055 21.6489 19.4603 22.0327 19.7389 22.318L21.1629 23.7379C20.3162 24.1428 19.3648 24.2754 18.4397 24.1175C17.5145 23.9595 16.661 23.5189 15.9965 22.8561C15.3319 22.1933 14.8891 21.341 14.7287 20.4163C14.5683 19.4916 14.6984 18.5399 15.101 17.692L13.679 16.274"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.94504 23.596C1.73644 23.3884 1.57092 23.1416 1.45798 22.8699C1.34503 22.5982 1.28687 22.3068 1.28687 22.0125C1.28687 21.7182 1.34503 21.4268 1.45798 21.1551C1.57092 20.8833 1.73644 20.6366 1.94504 20.429L6.64502 15.743C6.7856 15.6028 6.97602 15.5242 7.17453 15.5242C7.37304 15.5242 7.56346 15.6028 7.70404 15.743L9.82202 17.854C9.89144 17.9233 9.94652 18.0056 9.9841 18.0962C10.0217 18.1868 10.041 18.2839 10.041 18.382C10.041 18.4801 10.0217 18.5772 9.9841 18.6678C9.94652 18.7584 9.89144 18.8407 9.82202 18.9099L5.12204 23.596C4.70016 24.0162 4.12898 24.2521 3.53354 24.2521C2.9381 24.2521 2.36692 24.0162 1.94504 23.596Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.086 2.18301L23.307 8.38297C23.4461 8.52126 23.5566 8.68571 23.632 8.86685C23.7074 9.048 23.7462 9.24231 23.7462 9.43851C23.7462 9.63471 23.7074 9.82902 23.632 10.0102C23.5566 10.1913 23.4461 10.3558 23.307 10.4941L21.19 12.607C20.9088 12.8873 20.528 13.0447 20.131 13.0447C19.734 13.0447 19.3532 12.8873 19.072 12.607L12.851 6.40701C12.7122 6.26852 12.6021 6.104 12.5269 5.92288C12.4518 5.74176 12.4131 5.54756 12.4131 5.35147C12.4131 5.15538 12.4518 4.9613 12.5269 4.78018C12.6021 4.59906 12.7122 4.43455 12.851 4.29605L14.968 2.18497C15.2489 1.90441 15.6296 1.74674 16.0266 1.74637C16.4236 1.74599 16.8046 1.90299 17.086 2.18301Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.028 9.57596L8.65894 16.7021"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
