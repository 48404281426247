import type { KeyboardEvent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FlatList from 'flatlist-react'
import { Checkbox } from 'rsuite'
import { CloseIcon, RightIcon, SearchIcon } from '../../../icons'
import {
  BorderButton,
  CustomAlert,
  CustomButton,
  CustomMenu,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../atoms'
import type { filterItem } from '../../../screen/RoleSpecializationsList'
import type { JobRequirementItem } from '../../../packages/api-web/RoleManagement'
import {
  useJobAllRequirements,
  usePostJobRequirementInfo,
} from '../../../packages/api-web/RoleManagement'

interface JobTitleAddAlertProps {
  isJobTitle?: boolean
  jobTitleHandle?: string
  selectedData?: JobRequirementItem[]
  filterDataItems?: filterItem[]
  onCloseAlert?: () => void
  onSuccessInfo?: (data: JobRequirementItem[]) => void
}

export function JobTitleAddAlert({
  isJobTitle,
  selectedData,
  jobTitleHandle,
  filterDataItems,
  onCloseAlert,
  onSuccessInfo,
}: JobTitleAddAlertProps) {
  const { t } = useTranslation('common')
  const [searchTxt, setSearchTxt] = useState('')
  const [filterData, setFilterData] = useState<filterItem[]>(filterDataItems ?? [])
  const [selectedItems, setSelectedItems] = useState<JobRequirementItem[]>(selectedData ?? [])
  const { isLoading, isFetching, data, refetch } = useJobAllRequirements({
    filter: searchTxt,
    filterData,
  })
  const { isPending: isLoadInfo, mutate } = usePostJobRequirementInfo({
    handle: jobTitleHandle,
    isJobTitle,
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      refetch()
    }
  }

  const renderItem = (item: JobRequirementItem, idx: string) => {
    return (
      <div className="items-center flex h-8 border-b border-b-stroke px-4">
        <Checkbox
          checked={selectedItems.some(val => item.sourceHandle === val.sourceHandle)}
          onChange={() => {
            if (selectedItems.some(val => item.sourceHandle === val.sourceHandle)) {
              setSelectedItems((oldValues) => {
                return oldValues.filter(val => item.sourceHandle !== val.sourceHandle)
              })
            }
            else {
              setSelectedItems([...selectedItems, item])
            }
          }}
          className="cm-checkbox"
        >
          {item?.title}
        </Checkbox>
      </div>
    )
  }

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      <CustomText variant="lgPoppinsSemiBold">{t('addToJobTitle')}</CustomText>
      <div className="border-t border-t-stroke mt-3 mb-3" />
      <div className="mt-3 mb-6 px-5 py-3 bg-bgColor rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    if (index == 0) {
                      const updateItem = { ...newFilter[1], data: value?.subTypes }
                      newFilter[1] = updateItem
                    }
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={t(item?.header)}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              if (index == 0) {
                                const updateItem = {
                                  ...newFilter[1],
                                  data: [],
                                  selectedData: null,
                                }
                                newFilter[1] = updateItem
                              }
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-3 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="max-h-96 overflow-y-auto scrollbar">
        <div className="flex items-center w-auto gap-4">
          <SearchIcon />
          <input
            type="text"
            value={searchTxt}
            placeholder={t('searchPlaceholder')}
            className="outline-0 flex-1 min-w-[100px]"
            onChange={e => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {searchTxt && (
            <button
              onClick={() => {
                setSearchTxt('')
                setTimeout(() => {
                  refetch()
                }, 1000)
              }}
            >
              <CloseIcon className="h-5" />
            </button>
          )}
        </div>
        <div className="border border-b-0 mt-4 border-stroke">
          <div className="bg-bgColor flex items-center px-4 h-8 border-b border-b-stroke">
            <CustomText variant="mdPoppinsRegular">{`${t('selectMandatoryRequirements')}:`}</CustomText>
          </div>
          <FlatList
            list={data?.items ?? []}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 items-center justify-center flex">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
      </div>
      <div className="mt-3 flex gap-4 justify-end">
        <CustomButton
          children={t('save')}
          backgroundColor="bg-secondary"
          textColor="text-textWhite"
          className="!px-6"
          onClick={() => {
            mutate(selectedItems, {
              onSuccess: () => {
                onCloseAlert?.()
                onSuccessInfo?.(selectedItems)
              },
            })
          }}
        />
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          className="!px-6"
          textColor="text-textPrimary"
          onClick={() => {
            onCloseAlert?.()
          }}
        />
      </div>
      {(isLoading || isFetching || isLoadInfo) && <Spinner />}
    </CustomAlert>
  )
}
