export function ExperiencesIcon({ className = '' }) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" className={className}>
      <path
        d="M1.25 9C1.25 8.60218 1.40803 8.22064 1.68933 7.93933C1.97064 7.65803 2.35218 7.5 2.75 7.5H22.25C22.6478 7.5 23.0294 7.65803 23.3107 7.93933C23.592 8.22064 23.75 8.60218 23.75 9V21C23.75 21.3978 23.592 21.7794 23.3107 22.0607C23.0294 22.342 22.6478 22.5 22.25 22.5H2.75C2.35218 22.5 1.97064 22.342 1.68933 22.0607C1.40803 21.7794 1.25 21.3978 1.25 21V9Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7.5C17 6.30653 16.5259 5.16191 15.6819 4.31799C14.838 3.47408 13.6935 3 12.5 3C11.3065 3 10.1619 3.47408 9.31799 4.31799C8.47408 5.16191 8 6.30653 8 7.5"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 13.5H11"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.5H23.75"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.75C12.1022 15.75 11.7206 15.592 11.4393 15.3107C11.158 15.0294 11 14.6478 11 14.25V12.75C11 12.3522 11.158 11.9706 11.4393 11.6893C11.7206 11.408 12.1022 11.25 12.5 11.25C12.8978 11.25 13.2794 11.408 13.5607 11.6893C13.842 11.9706 14 12.3522 14 12.75V14.25C14 14.6478 13.842 15.0294 13.5607 15.3107C13.2794 15.592 12.8978 15.75 12.5 15.75Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
