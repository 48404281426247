import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Checkbox, CheckboxGroup } from 'rsuite'
import type { ValueType } from 'rsuite/esm/Checkbox'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomNumberInput,
  CustomText,
  CustomTextArea,
  ImagePicker,
} from '../../component'

function CreateCertificate() {
  const { t } = useTranslation('common')
  const checkBoxArray = [
    { handle: 1, title: t('regulatoryCompliance') },
    { handle: 2, title: t('requiresAttachment') },
  ]
  const [selectCheck, setSelectCheck] = useState<ValueType[]>()

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('experiences')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {t('createNewCertificate')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg lg:mr-3">
              <CustomText variant="smPoppinsSemiBold">{t('numberOfUsersExperience')}</CustomText>
              <CustomText variant="smPoppinsSemiBold" className="!text-secondary">
                6
              </CustomText>
            </div>
            <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg max-lg:mt-3">
              <CustomText variant="smPoppinsSemiBold">{t('numberOfExperiencesExpired')}</CustomText>
              <CustomText variant="smPoppinsSemiBold" className="!text-secondary">
                1
              </CustomText>
            </div>
          </div>
          <div className="border border-stroke rounded-lg mt-3">
            <div className="py-3 px-5 border-b border-b-stroke">
              <CustomText variant="mdPoppinsSemiBold">{t('description')}</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <CustomInput
                title={t('title')}
                value=""
                onChange={(e) => {}}
                placeholder={t('exHotWork')}
                className="mb-3"
              />
              <CustomDropDown
                values={[]}
                className="!mt-3"
                title={`${t('category')}`}
                options={[
                  {
                    handle: 1,
                    title: 'Leanne Graham',
                  },
                  {
                    handle: 2,
                    title: 'Ervin Howell',
                  },
                ]}
                labelField="title"
                valueField="handle"
                onChange={(values) => {}}
                placeholder={t('selectExperienceType')}
              />
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <ImagePicker
                  title={`${t('chooseImageArticle')} ${t('maxPixels')}`}
                  buttonTitle={`${t('choosePicture')}`}
                  selectedImage={(item) => {}}
                />
                <div className="ml-8 max-lg:mt-3" />
                <ImagePicker
                  title={`${t('chooseImageArticle')} ${t('maxPixels')}`}
                  buttonTitle={`${t('choosePicture')}`}
                  selectedImage={(item) => {}}
                />
              </div>
              <CustomTextArea
                title={`${t('description')} ${t('maxCharacters')}`}
                placeholder={`${t('writeDescription')}`}
                aria-multiline
                value=""
                onChange={(e) => {}}
              />
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    values={[]}
                    className="!mt-3"
                    title={`${t('validityType')}`}
                    options={[
                      {
                        handle: 1,
                        title: 'Year',
                      },
                      {
                        handle: 2,
                        title: 'Month',
                      },
                    ]}
                    labelField="title"
                    valueField="handle"
                    onChange={(values) => {}}
                    placeholder={`${t('select')} ${t('validityType').toLowerCase()}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomNumberInput
                    className="!mt-[7px] h-9"
                    title={t('periodValidity')}
                    max={31}
                    value={1}
                    onChange={(value) => {}}
                  />
                </div>
              </div>
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    values={[]}
                    className="!mt-3"
                    title={`${t('industry')}`}
                    options={[
                      {
                        handle: 1,
                        title: 'Decontamination',
                      },
                      {
                        handle: 2,
                        title: 'Decontamination',
                      },
                    ]}
                    labelField="title"
                    valueField="handle"
                    onChange={(values) => {}}
                    placeholder={`${t('select')} ${t('industry').toLowerCase()}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomDropDown
                    values={[]}
                    className="!mt-3"
                    title={`${t('costPerCertificate')}`}
                    options={[
                      {
                        handle: 1,
                        title: 'Amount',
                      },
                      {
                        handle: 2,
                        title: 'Amount',
                      },
                    ]}
                    labelField="title"
                    valueField="handle"
                    onChange={(values) => {}}
                    placeholder={`${t('select')}`}
                  />
                </div>
              </div>
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    values={[]}
                    className="!mt-3"
                    title={`${t('reminderCertificateExpires')}`}
                    options={[
                      {
                        handle: 1,
                        title: 'Year',
                      },
                      {
                        handle: 2,
                        title: 'Month',
                      },
                    ]}
                    labelField="title"
                    valueField="handle"
                    onChange={(values) => {}}
                    placeholder={`${t('select')}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomNumberInput
                    className="!mt-[7px] h-9"
                    title={t('enterQuantity')}
                    max={31}
                    value={1}
                    onChange={(value) => {}}
                  />
                </div>
              </div>
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1 lg:mr-5">
                  <CustomInput
                    title={t('timeConsumption')}
                    value=""
                    onChange={(e) => {}}
                    placeholder={`ex. ${t('hours')}`}
                    className="mb-3"
                  />
                </div>
                <div className="flex-1 max-lg:mt-3">
                  <CustomText variant="mdPoppinsSemiBold">{t('otherSettings')}</CustomText>
                  <CheckboxGroup
                    name="checkbox-group"
                    value={selectCheck}
                    onChange={(value) => {
                      setSelectCheck(value)
                    }}
                    className="min-w-fit max-h-60 overflow-auto scrollbar"
                  >
                    {checkBoxArray
                    && checkBoxArray.map(i => (
                      <div className="flex items-center">
                        <CustomText
                          wrapText={false}
                          variant="smPoppinsRegular"
                          className="mr-10 w-1/2"
                        >
                          {i.title}
                        </CustomText>
                        <Checkbox value={i.handle} />
                      </div>
                    ))}
                  </CheckboxGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-between items-center py-4">
          <CustomButton
            children={t('remove')}
            className="hidden sm:block"
            backgroundColor="bg-bthDisable"
            textColor="text-textPrimary"
            onClick={() => {}}
          />
          <div className="flex gap-4 flex-wrap">
            <CustomButton
              children={t('remove')}
              className="!px-4 sm:!px-5 block sm:hidden flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {}}
            />
            <BorderButton
              children={t('cancel')}
              className="!px-2 sm:!px-5 flex-1"
              textColor="text-textPrimary"
              onClick={() => {}}
            />
            <CustomButton
              children={t('restore')}
              className="!px-4 md:!px-14 flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {}}
            />
            <CustomButton
              children={t('save')}
              className="flex-auto"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCertificate
