enum EncryptedStorageKey {
  userSession = 'user_session',
  isFirstTime = 'is_first_time',
  certificateCategoryList = 'certificate_category_list',
  isLogOut = 'is_logout',

  certificateType = 'certificate_type',
  menuList = 'menu_list',
  languageCode = 'language_code',
}

const DisplayKey = [
  'Email',
  'Personnummer',
  'Personnummer1',
  'Phone',
  'Mobile',
  'City',
  'StartDate',
  'Department',
  'OfficeLocation',
  'EmergencyContacts',
  'State',
  'Manager',
]

export { EncryptedStorageKey, DisplayKey }
