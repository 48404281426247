export function RoleManagementIcon({ className = '' }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className={className}>
      <path
        d="M5 9.25002C5.44501 9.25002 5.88005 9.1181 6.25006 8.87087C6.62007 8.62363 6.90844 8.27224 7.07874 7.8611C7.24903 7.44997 7.2936 6.99751 7.20679 6.56105C7.11997 6.1246 6.90567 5.72374 6.591 5.40908C6.27634 5.09441 5.87542 4.88005 5.43896 4.79323C5.00251 4.70641 4.55011 4.75099 4.13898 4.92128C3.72784 5.09158 3.37644 5.38001 3.12921 5.75002C2.88198 6.12003 2.75 6.55501 2.75 7.00002C2.75 7.59675 2.98704 8.169 3.409 8.59096C3.83095 9.01292 4.40326 9.25002 5 9.25002Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10.75C4.00544 10.75 3.05159 11.1451 2.34833 11.8484C1.64507 12.5517 1.25 13.5054 1.25 14.5V16.75H2.75L3.5 22.75H6.5"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 9.25002C20.445 9.25002 20.8801 9.1181 21.2501 8.87087C21.6201 8.62363 21.9084 8.27224 22.0787 7.8611C22.249 7.44997 22.2936 6.99751 22.2068 6.56105C22.12 6.1246 21.9057 5.72374 21.591 5.40908C21.2763 5.09441 20.8754 4.88005 20.439 4.79323C20.0025 4.70641 19.5501 4.75099 19.139 4.92128C18.7278 5.09158 18.3764 5.38001 18.1292 5.75002C17.882 6.12003 17.75 6.55501 17.75 7.00002C17.75 7.59675 17.987 8.169 18.409 8.59096C18.831 9.01292 19.4033 9.25002 20 9.25002Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 10.75C20.9946 10.75 21.9484 11.1451 22.6517 11.8484C23.3549 12.5517 23.75 13.5054 23.75 14.5V16.75H22.25L21.5 22.75H18.5"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.75001C13.0933 7.75001 13.6733 7.57404 14.1667 7.2444C14.66 6.91475 15.0446 6.44626 15.2717 5.89808C15.4987 5.34991 15.5581 4.74663 15.4424 4.16469C15.3266 3.58274 15.0409 3.04823 14.6213 2.62868C14.2018 2.20912 13.6672 1.92339 13.0853 1.80763C12.5033 1.69187 11.9001 1.75134 11.3519 1.97841C10.8038 2.20547 10.3353 2.58992 10.0056 3.08326C9.67597 3.57661 9.5 4.15667 9.5 4.75001C9.5 5.54566 9.81605 6.30874 10.3787 6.87135C10.9413 7.43396 11.7044 7.75001 12.5 7.75001Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 14.5C17.75 13.1076 17.1968 11.7723 16.2123 10.7877C15.2277 9.80315 13.8924 9.25 12.5 9.25C11.1076 9.25 9.77229 9.80315 8.78772 10.7877C7.80315 11.7723 7.25 13.1076 7.25 14.5V16.75H9.5L10.25 24.25H14.75L15.5 16.75H17.75V14.5Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
