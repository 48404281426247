export function ImagePickerIcon({ className = '' }) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" className={className}>
      <path
        d="M17.5955 19.6952H6.3605C5.88841 19.6943 5.42315 19.5824 5.00227 19.3686C4.58138 19.1547 4.21665 18.845 3.9375 18.4642H17.3635C17.8939 18.4642 18.4026 18.2535 18.7777 17.8785C19.1528 17.5034 19.3635 16.9947 19.3635 16.4642V3.23125C19.3635 3.16525 19.3635 3.09825 19.3535 3.03125C19.7369 3.31008 20.049 3.6755 20.2645 4.09776C20.48 4.52002 20.5927 4.98719 20.5935 5.46125V16.6952C20.5935 17.4906 20.2777 18.2533 19.7155 18.8159C19.1534 19.3784 18.3908 19.6947 17.5955 19.6952Z"
        fill="#F8F8FC"
      />
      <path
        d="M17.5951 19.6954H6.36036C5.40684 19.6954 4.50111 19.2351 3.9375 18.4642H17.3638C18.467 18.4642 19.3645 17.5671 19.3645 16.4644V3.22988C19.3645 3.16384 19.3612 3.09701 19.3547 3.03125C20.1318 3.59497 20.5948 4.50362 20.5948 5.46098V16.6948C20.5948 18.3493 19.2491 19.6954 17.5951 19.6954Z"
        fill="black"
      />
      <path
        d="M15.1334 0H3.89844C2.24158 0 0.898438 1.34315 0.898438 3V14.234C0.898438 15.8909 2.24158 17.234 3.89844 17.234H15.1334C16.7903 17.234 18.1334 15.8909 18.1334 14.234V3C18.1334 1.34315 16.7903 0 15.1334 0Z"
        fill="#F8F8FC"
      />
      <path
        d="M15.1334 0.75H3.89844C2.6558 0.75 1.64844 1.75736 1.64844 3V14.234C1.64844 15.4766 2.6558 16.484 3.89844 16.484H15.1334C16.3761 16.484 17.3834 15.4766 17.3834 14.234V3C17.3834 1.75736 16.3761 0.75 15.1334 0.75Z"
        stroke="black"
        strokeWidth={1.5}
      />
      <path
        d="M2.70508 15.6819C2.70508 15.6819 8.02638 9.66407 8.31738 9.37207C8.46271 9.22226 8.63688 9.10306 8.8291 9.02173C9.02132 8.94039 9.22781 8.89844 9.43652 8.89844C9.64524 8.89844 9.85173 8.94039 10.0439 9.02173C10.2362 9.10306 10.4103 9.22226 10.5557 9.37207C11.4797 10.2941 16.2461 15.6819 16.2461 15.6819"
        stroke="black"
        strokeWidth={1.5}
      />
      <path
        d="M14.4786 5.01761C14.4786 5.19917 14.4247 5.37665 14.3239 5.52762C14.223 5.67858 14.0796 5.79624 13.9119 5.86572C13.7441 5.9352 13.5595 5.95339 13.3815 5.91797C13.2034 5.88255 13.0398 5.79512 12.9114 5.66673C12.7831 5.53835 12.6956 5.37478 12.6602 5.1967C12.6248 5.01863 12.643 4.83405 12.7125 4.66631C12.7819 4.49856 12.8996 4.35519 13.0506 4.25432C13.2015 4.15345 13.379 4.09961 13.5606 4.09961C13.804 4.09961 14.0375 4.19633 14.2097 4.36848C14.3818 4.54064 14.4786 4.77414 14.4786 5.01761Z"
        fill="black"
      />
    </svg>
  )
}
