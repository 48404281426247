export function AddIcon() {
  return (
    <svg width={30} height={30} viewBox="0 0 37 37" fill="none">
      <path
        d="M18.8535 11.6426V25.3594"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9941 18.5H25.7111"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.70703 5.92529C1.70703 4.71272 2.18873 3.5498 3.04614 2.69238C3.90356 1.83497 5.06645 1.35352 6.27902 1.35352H31.426C32.6386 1.35352 33.8015 1.83497 34.6589 2.69238C35.5163 3.5498 35.998 4.71272 35.998 5.92529V31.0728C35.998 31.6732 35.8798 32.2676 35.65 32.8223C35.4202 33.377 35.0835 33.8806 34.6589 34.3052C34.2344 34.7297 33.7304 35.0666 33.1757 35.2964C32.621 35.5262 32.0264 35.6445 31.426 35.6445H6.27902C5.67862 35.6445 5.08409 35.5262 4.52939 35.2964C3.97469 35.0666 3.47069 34.7297 3.04614 34.3052C2.62159 33.8806 2.28482 33.377 2.05505 32.8223C1.82529 32.2676 1.70703 31.6732 1.70703 31.0728V5.92529Z"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
