import * as React from 'react'

export function CheckedIcon({ className = '' }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" className={className}>
      <rect x={0.5} y={0.5} width={19} height={19} rx={3.5} fill="#34C759" stroke="#34C759" />
      <path
        d="M5 10.3077L8.15789 14L15 6"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
