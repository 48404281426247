import { BorderButton, CustomAlert, CustomButton, CustomText } from '../../atoms'

interface DeleteAlertProps {
  title?: string
  subTitle?: string
  leftButtonText?: string
  rightButtonText?: string
  singleButtonText?: string
  onCloseAlert?: () => void
  onLeftButton?: () => void
  onRightButton?: () => void
  onSingleButton?: () => void
}

export function DeleteAlert({
  title,
  subTitle,
  leftButtonText,
  rightButtonText,
  singleButtonText,
  onCloseAlert,
  onLeftButton,
  onRightButton,
  onSingleButton,
}: DeleteAlertProps) {
  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      <CustomText variant="mdPoppinsSemiBold">{title ?? ''}</CustomText>
      <CustomText variant="mdPoppinsRegular" className="mt-2">
        {subTitle ?? ''}
      </CustomText>
      <div className="justify-between flex mt-4">
        {leftButtonText && (
          <BorderButton
            style={{ width: '47%' }}
            onClick={() => {
              onLeftButton?.()
            }}
          >
            {leftButtonText ?? ''}
          </BorderButton>
        )}
        {rightButtonText && (
          <CustomButton
            style={{ width: '50%', padding: 0 }}
            onClick={() => {
              onRightButton?.()
            }}
          >
            {rightButtonText ?? ''}
          </CustomButton>
        )}
        {singleButtonText && (
          <BorderButton
            style={{ width: '100%' }}
            onClick={() => {
              onSingleButton?.()
            }}
          >
            {singleButtonText ?? ''}
          </BorderButton>
        )}
      </div>
    </CustomAlert>
  )
}
