import type { InfiniteData } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { WallItemModelDto } from '@inphiz-shared/shared-models/src/Dto/wall-item-model-dto'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { DataCollectionApiRequest } from '@inphiz-shared/shared-models/src/Api/data-collection-api-request'
import { QueryBuilder } from '@inphiz/core-utilities'
import { queryClient } from '../../../App'
import type { Group } from './useFollowersQuery'

export interface SocialList {
  items: ReadonlyArray<Social>
  nextCursor: number
}

interface FetchNewsProps {
  selectedSocialGroupHandle: string | undefined
  isEnabled?: boolean
}

const SocialType = new WallItemModelDto()
export type Social = typeof SocialType

const currentTakeItem = 30

export function setCurrentUserCount(
  socialHandle: string,
  typeHandle: string,
  count: number | undefined,
) {
  queryClient.setQueryData<InfiniteData<SocialList>>(
    [QueryKey.socialList, typeHandle],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      return {
        ...requestsHistory,
        pages: requestsHistory.pages.map(page => ({
          ...page,
          items: page.items.map((i) => {
            if (socialHandle == i.handle) {
              return {
                ...i,
                actionsInfo: {
                  ...i.actionsInfo,
                  currentCount: count,
                },
              }
            }
            return i
          }),
        })),
      }
    },
  )
}

export function useSocialList({ selectedSocialGroupHandle, isEnabled = true }: FetchNewsProps) {
  const tabData = queryClient.getQueryData([QueryKey.groupsList, 'groupwallpost']) as Group[]
  const result = useInfiniteQuery({
    queryKey: [QueryKey.socialList, selectedSocialGroupHandle],
    queryFn: async ({ pageParam }) => {
      const rqb = new QueryBuilder(APIConst.socials.socials)
      let childOf: string[] = []
      if (selectedSocialGroupHandle) {
        childOf = [selectedSocialGroupHandle]
      }
      else {
        // filter out empty handles, group "all" handle is empty
        childOf = tabData.map(i => i.handle ?? '').filter(x => x !== '') ?? []
      }

      const rp = new DataCollectionApiRequest()
      rp.childOf = childOf
      rp.take = currentTakeItem
      rp.cursor = pageParam.toString()
      rp.isOrderAscending = false
      rp.onlyCount = false

      const url = rqb.addAsQueryParameters(rp).toString()

      const results = await APIFunctions.GET(url)
      const nextCursorValue = pageParam + currentTakeItem
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
    enabled: isEnabled,
  })
  return result
}
