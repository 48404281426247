import { useQuery } from '@tanstack/react-query'
import { CommentModelDto } from '@inphiz-shared/shared-models/src/Dto/comment-model-dto'
import { HtmlHelpers, QueryBuilder } from '@inphiz/core-utilities'
import { DataCollectionApiRequest } from '@inphiz-shared/shared-models/src/Api/data-collection-api-request'
import type { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const CommentsType = new CommentModelDto()
export type Comments = typeof CommentsType

export function useGetCommentByHandle(handle: string) {
  return useQuery<DataCollectionApiResponse<CommentModelDto>>({
    queryKey: [QueryKey.comments, handle],
    queryFn: async () => {
      // var query = new DataCollectionApiRequest()
      // {
      //     ChildOf = new[] { parent.Handle },
      //     //Filter = text,
      //     Take = pageSize,
      //     Cursor = cursor.ToString(),
      //     IncludeChildCount = includeChildCount,
      //     Parameters = parameters?.SelectMany(x => new List<string> { x.Key, x.Value })?.ToArray()
      // };

      const p = new DataCollectionApiRequest()
      p.childOf = [handle]
      p.take = 1000
      p.includeChildCount = 100
      // p.

      const qb = new QueryBuilder(APIConst.getComments).addAsQueryParameters(p)

      const response = (await APIFunctions.GET(
        qb.toString(),
      )) as DataCollectionApiResponse<CommentModelDto>

      response.items?.forEach((comment) => {
        comment.content = HtmlHelpers.processReceivedContentToDisplay(comment.content)
        comment?.childs?.forEach((child) => {
          child.content = HtmlHelpers.processReceivedContentToDisplay(child.content)
        })
      })

      return response
    },
  })
}
