import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { filterItem } from '../../../screen/RoleSpecializationsList'
import type { RequirementData } from './useJobRequirements'

interface RolePositionParams {
  filter?: string
  filterData?: filterItem[]
  jobTitleHandle?: string
}

export function useJobRoleRequirements({
  filter = '',
  filterData = [],
  jobTitleHandle = '',
}: RolePositionParams) {
  return useQuery<RequirementData>({
    queryKey: [QueryKey.roleRequirements],
    queryFn: async () => {
      const qb = new QueryBuilder(
        `${APIConst.employeesPerformance.getJobRolesLeadership}/${jobTitleHandle}/requirements`,
      ).add('Filter', filter)
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('Type', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('Subtype', filterData[1]?.selectedData)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
