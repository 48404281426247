import type { InfiniteData } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import type { SocialAction, SocialDetails } from './useSocialDetails'
import type { SocialList } from './useSocialList'

function onReadConfirm({
  result,
  ownerHandle,
  handle,
  groupType,
}: {
  result: SocialAction
  ownerHandle: string
  handle: string
  groupType: string | undefined
}) {
  queryClient.setQueryData(
    [QueryKey.socialDetails, ownerHandle],
    (socialDetails: SocialDetails) => {
      if (socialDetails === undefined)
        return

      return {
        ...socialDetails,
        actionsInfo: {
          ...socialDetails.actionsInfo,
          currentCount:
            socialDetails.actionsInfo?.currentCount && socialDetails.actionsInfo?.currentCount + 1,
          socialActions: socialDetails.actionsInfo?.socialActions?.map((i: any) => {
            if (i.handle == handle)
              return result

            return i
          }),
        },
      }
    },
  )

  queryClient.setQueryData<InfiniteData<SocialList>>(
    [QueryKey.socialList, groupType],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      return {
        ...requestsHistory,
        pages: requestsHistory.pages.map(page => ({
          ...page,
          items: page.items.map((social) => {
            if (social.handle === ownerHandle) {
              return {
                ...social,
                actionsInfo: {
                  ...social.actionsInfo,
                  currentCount:
                    social.actionsInfo?.currentCount && social.actionsInfo?.currentCount + 1,
                  socialActions: social.actionsInfo?.socialActions?.map((i: any) => {
                    if (i.handle == handle)
                      return result

                    return i
                  }),
                },
              }
            }
            return social
          }),
        })),
      }
    },
  )
}

export function usePostReadMutation(groupType: string | undefined) {
  return useMutation({
    mutationFn: async (data: SocialAction) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.socials.readConfirmed, params)
      return { ...result, data: result.item as SocialAction }
    },
    onSuccess: (response, params) => {
      if (response.isSuccess) {
        onReadConfirm({
          result: response.data,
          ownerHandle: params.ownerHandle ?? '',
          handle: params.handle ?? '',
          groupType,
        })
      }
    },
  })
}
