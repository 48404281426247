export const QueryKey = {
  performance: 'performance',
  jobRolesArchived: 'jobRolesArchived',
  userCertificates: 'userCertificates',
  userContacts: 'userContacts',
  myTeam: 'myTeam',
  jobRequirementsAll: 'jobRequirementsAll',
  newsList: 'newsList',
  notifications: 'notifications',
  totalNotifications: 'totalNotifications',
  policyList: 'policyList',
  postPolicyList: 'postPolicyList',
  onBoarding: 'onBoarding',
  onBoardWelcome: 'onBoardWelcome',
  pollData: 'pollData',
  groupsList: 'groupsList',
  comments: 'comments',
  likes: 'likes',
  loginDate: 'loginDate',
  loginData: 'loginData',
  allReadNotifications: 'allReadNotifications',
  readNotification: 'readNotification',
  deleteLike: 'deleteLike',
  postComment: 'postComment',
  deleteComment: 'deleteComment',
  postLike: 'postLike',
  certificateCategoryList: 'certificateCategoryList',
  mediaUpload: 'mediaUpload',
  createCertificate: 'createCertificate',
  archivingReasonsList: 'archivingReasonsList',
  addArchiveCertificate: 'addArchiveCertificate',
  certificateDetails: 'certificateDetails',
  userJobRoles: 'userJobRoles',
  jobRoleDetails: 'jobRoleDetails',
  socialList: 'socialList',
  deleteSocialPost: 'deleteSocialPost',
  socialDetails: 'socialDetails',
  readingUserList: 'readingUserList',
  allEmployeeList: 'allEmployeeList',
  getMyTeamEmployeeList: 'getMyTeamEmployeeList',
  userDetails: 'userDetails',
  emergencyContacts: 'emergencyContacts',
  quickStart: 'quickStart',
  certificateType: 'certificateType',
  myApplication: 'myApplication',
  ssnCodeData: 'ssnCodeData',
  postAIMessage: 'postAIMessage',
  myPages: 'myPages',
  notificationSetting: 'notificationSetting',
  setNotificationSetting: 'setNotificationSetting',
  searchList: 'searchList',
  newsDetails: 'newsDetails',
  publicNewsList: 'publicNewsList',
  campaignList: 'campaignList',
  campaignDetails: 'campaignDetails',
  questionDetails: 'questionDetails',
  cardList: 'cardList',
  feedbackList: 'feedbackList',
  tableCardList: 'tableCardList',
  organizationUnit: 'organizationUnit',
  employeesStates: 'employeesStates',
  feedbackGroup: 'feedbackGroup',
  jobTitles: 'jobTitles',
  managerFilterList: 'managerFilterList',
  equipmentList: 'equipmentList',
  equipmentCategory: 'equipmentCategory',
  equipmentUser: 'equipmentUser',
  equipmentDetails: 'equipmentDetails',
  deleteEquipmentCategory: 'deleteEquipmentCategory',
  dashboardNews: 'dashboardNews',
  newsCategory: 'newsCategory',
  dashboardNewDetails: 'dashboardNewDetails',
  rolePositions: 'rolePositions',
  roleSpecializations: 'roleSpecializations',
  roleAssign: 'roleAssign',
  roleTitleDetail: 'roleTitleDetail',
  roleRequirementType: 'roleRequirementType',
  roleRequirements: 'roleRequirements',
  roleAllRequirements: 'roleAllRequirements',
  jobRoleDetail: 'jobRoleDetail',
  departmentList: 'departmentList',
  getAllUsers: 'getAllUsers',
  getCertificate: 'getCertificate',
}
