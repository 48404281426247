import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'

interface DeletePostParams {
  type: string
  handle?: string
  contentType: string
}

function onPostDeleteSuccess() {
  queryClient.refetchQueries({ queryKey: [QueryKey.socialList] })
}

export function useDeletePost(selectedSocialGroupHandle: string | undefined) {
  return useMutation({
    mutationKey: [QueryKey.deleteSocialPost],
    mutationFn: async (data: DeletePostParams) => {
      const params = {
        item: data,
      }
      await APIFunctions.DELETE(APIConst.socials.socials, params)
      return { selectedSocialGroupHandle, postHandle: data.handle }
    },
    onSuccess: onPostDeleteSuccess,
  })
}
