import * as React from 'react'

export function UnLike({ className = '' }) {
  return (
    <svg width={18} height={16} viewBox="0 0 18 16" fill="none" className={className}>
      <path
        d="M0.998379 5.58287C0.959819 4.35366 1.41051 3.15937 2.25155 2.26209C3.09258 1.36481 4.25525 0.837856 5.48438 0.796875C6.17097 0.802104 6.84672 0.968637 7.45711 1.28304C8.06751 1.59745 8.59546 2.05092 8.99838 2.60687C9.4013 2.05092 9.92925 1.59745 10.5396 1.28304C11.15 0.968637 11.8258 0.802104 12.5124 0.796875C13.7415 0.837856 14.9042 1.36481 15.7452 2.26209C16.5862 3.15937 17.0369 4.35366 16.9984 5.58287C17.0021 6.23167 16.8777 6.87483 16.6323 7.47544C16.3869 8.07605 16.0254 8.62231 15.5684 9.08288L10.4464 14.5649C10.2671 14.7584 10.0509 14.9139 9.81042 15.0223C9.56997 15.1307 9.31022 15.1898 9.04654 15.1961C8.78286 15.2023 8.5206 15.1556 8.27529 15.0587C8.02998 14.9618 7.8066 14.8166 7.61838 14.6319L7.55338 14.5659L2.42938 9.08887C1.97141 8.62774 1.6092 8.08056 1.3636 7.47884C1.11801 6.87713 0.993882 6.23277 0.998379 5.58287Z"
        stroke="#333333"
      />
    </svg>
  )
}
