import { useMutation } from '@tanstack/react-query'
import { SaveItemApiRequest } from '@inphiz-shared/shared-models/src/Api/save-item-api-request'
import type { WallItemModelDto } from '@inphiz-shared/shared-models/src/Dto/wall-item-model-dto'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

const { item } = new SaveItemApiRequest<WallItemModelDto>()
export type CreateSocialPostParam = typeof item

export function useCreateSocialPostMutation() {
  const result = useMutation({
    mutationFn: async (data: CreateSocialPostParam) =>
      APIFunctions.POST(APIConst.socials.socials, { item: data }),
  })
  return { ...result, createPostMutate: result.mutate }
}
