import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export interface PostMessageParams {
  question: string
  history?: string[]
}

export function usePostMessage() {
  return useMutation({
    mutationKey: [QueryKey.postAIMessage],
    mutationFn: async (data: PostMessageParams) => {
      const result = await APIFunctions.AICHAT_POST(APIConst.assistantChat, data)
      return result
    },
  })
}
