import { useTranslation } from 'react-i18next'
import FlatList from 'flatlist-react'
import { useCallback, useMemo, useState } from 'react'
import { APIConst } from '@inphiz/api'
import { BorderButton, CustomImg, CustomText, Spinner } from '../../component'
import { BellIcon } from '../../icons'
import type { Notification } from '../../packages/api-web'
import { useNotificationsQuery, useReadNotificationMutation } from '../../packages/api-web'
import { dateFormat } from '../../helpers'

function NotificationList() {
  const { t } = useTranslation('common')
  const { data: notificationList, hasNextPage, fetchNextPage } = useNotificationsQuery()
  const { mutate, isPending } = useReadNotificationMutation()
  const [isUnRead, setIsUnRead] = useState<boolean>(false)

  const notificationData = useMemo(() => {
    const result = notificationList?.pages?.flatMap(i => i.items)
    return result
  }, [notificationList])

  const unReadData = useMemo(() => {
    const result = notificationList?.pages
      ?.flatMap(i => i.items)
      ?.filter(i => i?.status?.toLowerCase()?.includes('unread'))
    return result
  }, [notificationList])

  const setReadNotification = useCallback(
    (handle: string) => {
      const data = {
        handle,
        status: 'Read',
      }
      mutate(data)
    },
    [mutate],
  )

  const renderItem = (item: Notification, idx: string) => {
    const unread = item.status !== 'Read'
    return (
      <li
        key={idx}
        className={`mt-2 cursor-pointer flex py-3 px-3 ${unread ? 'bg-bthDisable' : 'bg-white'}`}
        onClick={() => {
          setReadNotification(item?.handle)
        }}
      >
        <div className="flex h-14 w-14 bg-bgColor rounded-full relative items-center justify-center">
          {item?.author?.displayName ? (
            <>
              <CustomImg
                name={item?.author?.displayName}
                imgSrc={
                  item.author?.profileImageHandle
                    ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item?.author?.profileImageHandle}`
                    : ''
                }
                altText="user_image"
                className="h-14 w-14 rounded-full"
                imgClassName="rounded-full"
              />
              <div className="h-4 w-4 bg-secondary rounded-full absolute right-0 bottom-0">
                <img src={item?.originImage} />
              </div>
            </>
          ) : (
            <img src={item?.originImage} />
          )}
        </div>
        <div className="flex flex-col flex-1 justify-center mx-2">
          {item.messageFormat == 'Html' ? (
            <div
              dangerouslySetInnerHTML={{ __html: item?.message ?? '' }}
              style={{ fontFamily: 'Poppins-Regular' }}
            />
          ) : (
            <CustomText className="line-clamp-2" variant="smPoppinsRegular" wrapText={true}>
              {item?.message}
            </CustomText>
          )}
          <CustomText variant="xsmPoppinsRegular" textColor="text-textSecondary" className="mt-1">
            {dateFormat(item.createdTime)}
          </CustomText>
        </div>
        {unread && <div className="h-2 w-2 rounded-full bg-secondary" />}
      </li>
    )
  }

  return (
    <div className="flex-1 mt-3 bg-white border border-stroke rounded-lg overflow-hidden">
      <div className="flex border-b border-b-stroke items-center px-5 py-3">
        <CustomText variant="lgPoppinsSemiBold">{t('stayUpdated')}</CustomText>
        <div className="flex ml-3 h-6 w-6 bg-secondary rounded-full items-center justify-center">
          <BellIcon className="fill-white stroke-white !h-4 !w-4" />
        </div>
      </div>
      <BorderButton
        children={t('showUnreadOnly')}
        className={`px-2 py-1 ml-5 my-3 !rounded-full border-secondary ${isUnRead ? 'bg-secondary' : 'bg-white'}`}
        textColor={isUnRead ? 'text-textWhite' : 'text-textPrimary'}
        onClick={() => {
          setIsUnRead(!isUnRead)
        }}
      />
      <FlatList
        list={(isUnRead ? unReadData : notificationData) ?? []}
        renderItem={renderItem}
        hasMoreItems={hasNextPage}
        loadMoreItems={fetchNextPage}
        paginationLoadingIndicator={<div />}
      />
      {isPending && <Spinner />}
    </div>
  )
}

export default NotificationList
