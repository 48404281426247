import * as React from 'react'

export function ImagePlaceHolder({ className = '' }) {
  return (
    <svg width={192} height={192} fill="none" className={className}>
      <path
        d="M180 0H8C3.58172 0 0 3.58172 0 8V180C0 184.418 3.58172 188 8 188H180C184.418 188 188 184.418 188 180V8C188 3.58172 184.418 0 180 0Z"
        fill="white"
      />
      <path
        d="M180 0.5H8C3.85786 0.5 0.5 3.85786 0.5 8V180C0.5 184.142 3.85786 187.5 8 187.5H180C184.142 187.5 187.5 184.142 187.5 180V8C187.5 3.85786 184.142 0.5 180 0.5Z"
        stroke="#D2D7DD"
      />
      <path
        d="M68.4542 59.6152C66.1108 59.6179 63.8641 60.55 62.207 62.207C60.55 63.8641 59.6179 66.1108 59.6152 68.4542V119.169C59.6179 121.513 60.55 123.759 62.207 125.416C63.8641 127.073 66.1108 128.006 68.4542 128.008H119.169C121.513 128.006 123.759 127.073 125.416 125.416C127.073 123.759 128.006 121.513 128.008 119.169V68.4542C128.006 66.1108 127.073 63.8641 125.416 62.207C123.759 60.55 121.513 59.6179 119.169 59.6152H68.4542ZM119.169 124.881H75.9792L105.223 96.2432C106.349 95.1385 107.843 94.4879 109.419 94.4162C110.995 94.3444 112.542 94.8564 113.763 95.8542L124.882 104.919V119.169C124.881 120.684 124.278 122.136 123.207 123.206C122.137 124.277 120.684 124.88 119.169 124.881ZM124.881 68.4552V100.887L115.738 93.4332C113.921 91.9504 111.621 91.1895 109.278 91.2961C106.935 91.4028 104.713 92.3694 103.038 94.0112L71.5102 124.881H68.4542C66.9398 124.88 65.4879 124.277 64.417 123.206C63.3461 122.136 62.7438 120.684 62.7422 119.169V68.4542C62.7438 66.9398 63.3461 65.4879 64.417 64.417C65.4879 63.3461 66.9398 62.7438 68.4542 62.7422H119.169C120.684 62.7438 122.136 63.3461 123.206 64.417C124.277 65.4879 124.88 66.9398 124.881 68.4542V68.4552Z"
        fill="#D6DBE0"
      />
      <path
        d="M83.1173 73.2168C81.246 73.2168 79.4169 73.7717 77.861 74.8113C76.3052 75.8509 75.0925 77.3285 74.3764 79.0572C73.6603 80.786 73.473 82.6883 73.838 84.5235C74.2031 86.3588 75.1042 88.0446 76.4273 89.3677C77.7505 90.6909 79.4363 91.5919 81.2715 91.957C83.1068 92.3221 85.0091 92.1347 86.7378 91.4186C88.4666 90.7025 89.9442 89.4899 90.9838 87.934C92.0234 86.3782 92.5783 84.549 92.5783 82.6778C92.5783 80.1686 91.5815 77.7621 89.8072 75.9879C88.0329 74.2136 85.6265 73.2168 83.1173 73.2168ZM83.1173 89.0168C81.8646 89.0176 80.6397 88.6469 79.5977 87.9516C78.5557 87.2563 77.7432 86.2677 77.263 85.1106C76.7829 83.9536 76.6566 82.6802 76.9001 81.4514C77.1437 80.2226 77.7461 79.0936 78.6313 78.2072C79.5164 77.3207 80.6446 76.7167 81.873 76.4714C83.1015 76.2261 84.3751 76.3506 85.5328 76.8291C86.6905 77.3077 87.6803 78.1187 88.3771 79.1598C89.0738 80.2008 89.4463 81.4251 89.4473 82.6778C89.4473 84.357 88.7805 85.9675 87.5935 87.1552C86.4065 88.3429 84.7964 89.0107 83.1173 89.0118V89.0168Z"
        fill="#D6DBE0"
      />
    </svg>
  )
}
