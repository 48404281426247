import type { TextColorVariants, TextVariants } from '../CustomText'
import { CustomText } from '../CustomText'

export type BGColorVariants = 'bg-primary' | 'bg-secondary' | 'bg-bthDisable'

export interface CompButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  readonly children: string
  readonly textColor?: TextColorVariants
  readonly textVariant?: TextVariants
  readonly style?: React.CSSProperties
  readonly backgroundColor?: BGColorVariants
  readonly onClick?: () => void
}

export function CustomButton({
  children,
  textColor = 'text-textWhite',
  textVariant = 'mdPoppinsMedium',
  backgroundColor = 'bg-primary',
  style,
  onClick,
  ...props
}: CompButtonProps) {
  return (
    <button
      {...props}
      type="button"
      className={`rounded-md px-14 py-2 shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black ${backgroundColor} ${props.className}`}
      style={style}
      onClick={(event) => {
        event.stopPropagation()
        onClick?.()
      }}
    >
      <CustomText variant={textVariant} textColor={textColor}>
        {children}
      </CustomText>
    </button>
  )
}
