import type { ISecuredStorage } from '@inphiz/core-utilities/src/Storage/ISecuredStorage'

class SecureStorageImpl implements ISecuredStorage {
  public async setItem(key: string, value: string | object): Promise<void> {
    return Promise.resolve()
  }

  public async getItem(key: string): Promise<string | null> {
    return null
  }

  public async removeItem(key: string): Promise<void> {
    return Promise.resolve()
  }

  public async clear(): Promise<void> {
    return Promise.resolve()
  }
}

export { SecureStorageImpl }
