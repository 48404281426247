export interface IApiSharedAuthProvider {
  getAuthToken: () => Promise<string | null>
  sessionExpiredAlert: (title: string, message: string) => Promise<void>
  checkSessionExpired: () => Promise<boolean>
  resetSession: () => Promise<void>
}

export class ApiSharedAuthProviderImpl {
  private _provider: IApiSharedAuthProvider | null = null
  public async setImplementation(provider: IApiSharedAuthProvider) {
    this._provider = provider
  }

  public getInstance(): IApiSharedAuthProvider {
    if (!this._provider) {
      throw new Error('ApiSharedAuthProviderImpl not initialized')
    }
    return this._provider
  }
}

export const ApiSharedAuthProviderInstance = new ApiSharedAuthProviderImpl()
