import { CustomText } from '../../atoms'

export interface HistoryItemProps {
  title: string
}

export function HistoryItem({ title }: HistoryItemProps) {
  return (
    <div className="flex w-full px-5 py-3 text-start">
      <CustomText variant="smPoppinsRegular" className="line-clamp-1">
        {title}
      </CustomText>
    </div>
  )
}
