import type { ReactNode } from 'react'

export interface CustomAlertProps {
  onClose?: () => void
  children?: ReactNode
}

export function CustomAlert({ onClose, children }: CustomAlertProps) {
  return (
    <div
      onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="bg-white p-4 rounded-lg items-center justify-center text-center w-4/5  md:w-[58%] lg:w-[50%] xl:w-[40%]"
      >
        {children}
      </div>
    </div>
  )
}
