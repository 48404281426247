import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { EquipmentTypeDto } from '@inphiz-shared/shared-models/src/F_Equipment/equipment-type-dto'
import type { filterItem } from '../../../screen/EquipmentList'

const EquipmentItemDto = new EquipmentTypeDto()
export type EquipmentItem = typeof EquipmentItemDto

const EquipmentListDto = new DataCollectionApiResponse<EquipmentItem>()
export type EquipmentData = typeof EquipmentListDto

interface EquipmentListParams {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  filterData?: filterItem[]
}

export function useEquipmentList({
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
  filterData = [],
}: EquipmentListParams) {
  return useQuery<EquipmentData>({
    queryKey: [QueryKey.equipmentList],
    queryFn: () => {
      let url = `?cursor=${skip}&take=${itemPerPage}&filter=${searchTxt}&isorderascending=False`
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        const outputString = `ParametersWithValues[ParentEquipmentCategoryHandle]=${filterData[0]?.selectedData}`
        url = `${url}&${outputString}`
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        const outputString = `ParametersWithValues[UserHandle]=${filterData[1]?.selectedData}`
        url = `${url}&${outputString}`
      }
      return APIFunctions.GET(APIConst.equipment.getEquipmentTypes + url)
    },
    enabled: false,
  })
}
