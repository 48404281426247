import { useTranslation } from 'react-i18next'
import { BorderButton, CustomButton, CustomInput, CustomText, CustomTextArea } from '../../atoms'

function CertificateType() {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col flex-1 border border-stroke rounded-md overflow-y-auto scrollbar bg-white">
      <div className="px-3 flex justify-between h-10 border-b border-stroke items-center">
        <CustomText variant="smPoppinsSemiBold">{t('typeInformation')}</CustomText>
      </div>
      <div className="px-5 py-5 flex-1">
        <CustomInput
          title={t('title')}
          value=""
          onChange={(e) => {}}
          placeholder={t('exHotWork')}
          className="mb-3"
        />
        <CustomTextArea
          title={`${t('description')} ${t('maxCharacters')}`}
          placeholder={`${t('writeDescription')}`}
          aria-multiline
          value=""
          onChange={(e) => {}}
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-between items-center py-4 px-3">
        <CustomButton
          children={t('remove')}
          className="hidden sm:block"
          backgroundColor="bg-bthDisable"
          textColor="text-textPrimary"
          onClick={() => {}}
        />
        <div className="flex gap-4 flex-wrap">
          <CustomButton
            children={t('remove')}
            className="!px-4 sm:!px-5 block sm:hidden flex-1"
            backgroundColor="bg-bthDisable"
            textColor="text-textPrimary"
            onClick={() => {}}
          />
          <BorderButton
            children={t('cancel')}
            className="!px-2 sm:!px-5 flex-1"
            textColor="text-textPrimary"
            onClick={() => {}}
          />
          <CustomButton
            children={t('save')}
            className="flex-auto"
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default CertificateType
