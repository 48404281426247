import { dbName } from './db'

const version = 1 // also change in db.ts / add.ts
let request: IDBOpenDBRequest
let db: IDBDatabase

export function updateDB<T>(storeName: string, key: number, data: T): Promise<T | string | null> {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName, version)

    request.onsuccess = () => {
      console.log('request.onsuccess - updateData', key)
      db = request.result
      const tx = db.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)
      const res = store.get(key)
      console.log('res : ', res)
      res.onsuccess = () => {
        const newData = { ...res.result, ...data }
        store.put(newData)
        resolve(newData)
      }
      res.onerror = () => {
        resolve(null)
      }
    }
  })
}
