import { useTranslation } from 'react-i18next'
import CertificateType from '../../component/templates/CertificateType'

function CertificateTypeInfo() {
  const { t } = useTranslation('common')

  return (
    <div className="p-5 h-full flex flex-col bg-white border border-stroke rounded-lg">
      <CertificateType />
    </div>
  )
}

export default CertificateTypeInfo
