import { useQuery } from '@tanstack/react-query'
import { FeedbackCampaignDto } from '@inphiz-shared/shared-models/src/F_Feedback/feedback-campaign-dto'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'

const FeedbackListDto = new FeedbackCampaignDto()
export type FeedbackList = typeof FeedbackListDto

const FeedbackDataDto = new DataCollectionApiResponse<FeedbackList>()
export type FeedbackData = typeof FeedbackDataDto

export function useFeedbackList({ itemPerPage = 20, skip = 0, searchTxt = '' }) {
  return useQuery<FeedbackData>({
    queryKey: [QueryKey.feedbackList],
    queryFn: () =>
      APIFunctions.GET(
        `${APIConst.getFeedbacks}?cursor=${skip}&take=${itemPerPage}&filter=${searchTxt}&isorderascending=False`,
      ),
    enabled: false,
  })
}
