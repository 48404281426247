import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import type { JobRoleItem } from './useRoleSpecializations'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.rolePositions] })
}

export function usePostJobRole() {
  return useMutation({
    mutationFn: async (data: JobRoleItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(
        APIConst.employeesPerformance.getJobRolesLeadership,
        params,
      )
      return result
    },
    onSuccess,
  })
}
