import { useState } from 'react'
import { CustomText } from '../../atoms'

interface WeeklyOptionProps {
  title?: string
  onChange?: (data: string[]) => void
  selectedData: string[]
}

const weekList = [
  {
    day: 'MON',
    id: 1,
  },
  {
    day: 'TUE',
    id: 2,
  },
  {
    day: 'WED',
    id: 3,
  },
  {
    day: 'THU',
    id: 4,
  },
  {
    day: 'FRI',
    id: 5,
  },
  {
    day: 'SAT',
    id: 6,
  },
  {
    day: 'SUN',
    id: 7,
  },
]

function WeeklyOption({ title, selectedData, onChange }: WeeklyOptionProps) {
  const [selectedWeek, setSelectedWeek] = useState<string[]>(selectedData)

  return (
    <div className="flex flex-col">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <div className="flex gap-1 mt-1">
        {weekList.map(i => (
          <button
            onClick={() => {
              let data = [...selectedWeek]
              if (data.includes(i.day)) {
                data = data.filter(day => day !== i.day) // Remove selected day
              }
              else {
                data.push(i.day) // Add unselected day
              }
              setSelectedWeek(data)
              onChange?.(weekList.filter(i => data.includes(i.day)).map(i => i.day))
            }}
            className={`size-9 flex justify-center items-center opacity-65 ${selectedWeek.includes(i.day) ? 'bg-red' : 'bg-grey'} rounded-md`}
          >
            <CustomText textColor="text-textWhite" wrapText={false} variant="mdPoppinsSemiBold">
              {i.day.charAt(0)}
            </CustomText>
          </button>
        ))}
      </div>
    </div>
  )
}
export default WeeklyOption
