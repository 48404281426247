import { useMutation } from '@tanstack/react-query'
import { SaveSocialSecurityNumberApiRequest } from '@inphiz-shared/shared-models/src/F_SocialSecurityNumber/save-social-security-number-api-request'
import { queryClient } from '@inphiz/api/src'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import { QueryKey } from '../QueryKey'
import type { SSNResponse } from './getSSNCode'

const AddSSNCodeType = new SaveSocialSecurityNumberApiRequest()
export type AddSSNCodeParam = typeof AddSSNCodeType

function updateGetSSNCode(variables: AddSSNCodeParam) {
  queryClient.setQueryData([QueryKey.ssnCodeData], (ssnCodeData: SSNResponse) => {
    if (ssnCodeData === undefined)
      return

    return {
      ...ssnCodeData,
      socialSecurityNumber: variables.socialSecurityNumber,
    }
  })
}

export function addSSNCode() {
  return useMutation({
    mutationFn: (param: AddSSNCodeParam) =>
      APIFunctions.POST(APIConst.usersDirectory.sensitiveData, param),
    onSuccess: (data, variables: AddSSNCodeParam) => {
      if (data.isSuccess)
        updateGetSSNCode(variables)
    },
  })
}
