import type { InfiniteData } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { CommentModelDto } from '@inphiz-shared/shared-models/src/Dto/comment-model-dto'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { News, NewsList } from '../News'
import { queryClient } from '../../../App'
import { ContentType } from '../../../helpers'
import type { CommentsData } from './useGetCommentByHandle'
import type { SocialList } from './useSocialList'

interface PostCommentSuccessProps {
  readonly selectedNewsGroupHandle: string
  readonly params: DeleteCommentParams
}

const DeleteCommentParamsType = new CommentModelDto()
type DeleteCommentParams = typeof DeleteCommentParamsType

function onPostCommentSuccess({ selectedNewsGroupHandle, params }: PostCommentSuccessProps) {
  queryClient.setQueryData<CommentsData>(
    [QueryKey.comments, params.ownerHandle],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      if (params.parentCommentHandle) {
        return {
          ...requestsHistory,
          items: requestsHistory.items.map((item) => {
            if (item.handle == params.parentCommentHandle) {
              return {
                ...item,
                childs: item.childs?.filter((item: any) => params.handle !== item.handle),
              }
            }
            return item
          }),
        }
      }
      else {
        return {
          ...requestsHistory,
          items: requestsHistory.items.filter(item => params.handle !== item.handle),
        }
      }
    },
  )

  if (params.ownerContentType == ContentType.groupwallpost) {
    queryClient.setQueryData([QueryKey.socialDetails, params.ownerHandle], (socialDetails: any) => {
      if (socialDetails === undefined)
        return

      return {
        ...socialDetails,
        commentCount: socialDetails?.commentCount && socialDetails?.commentCount - 1,
      }
    })

    queryClient.setQueryData<InfiniteData<SocialList>>(
      [QueryKey.socialList, selectedNewsGroupHandle],
      (requestsHistory) => {
        if (requestsHistory === undefined)
          return

        return {
          ...requestsHistory,
          pages: requestsHistory.pages.map(page => ({
            ...page,
            items: page.items.map((social) => {
              if (social.handle === params.ownerHandle) {
                return {
                  ...social,
                  commentCount: social.commentCount && social.commentCount - 1,
                }
              }
              return social
            }),
          })),
        }
      },
    )
  }
  else {
    queryClient.setQueryData([QueryKey.newsDetails], (newsDetails: News) => {
      if (newsDetails === undefined)
        return

      return {
        ...newsDetails,
        commentCount: newsDetails.commentCount - 1,
      }
    })

    queryClient.setQueryData<InfiniteData<NewsList>>(
      [QueryKey.newsList, selectedNewsGroupHandle],
      (requestsHistory) => {
        if (requestsHistory === undefined)
          return

        return {
          ...requestsHistory,
          pages: requestsHistory.pages.map(page => ({
            ...page,
            items: page?.items?.map((news) => {
              if (news.handle === params.ownerHandle) {
                return {
                  ...news,
                  commentCount: news.commentCount - 1,
                }
              }
              return news
            }),
          })),
        }
      },
    )
  }
}

export function useDeleteComment(selectedNewsGroupHandle: string) {
  return useMutation({
    mutationKey: [QueryKey.deleteComment],
    mutationFn: async (data: DeleteCommentParams) => {
      const params = {
        item: data,
      }
      await APIFunctions.DELETE(APIConst.getComments, params)
      return { params: data, selectedNewsGroupHandle }
    },
    onSuccess: onPostCommentSuccess,
  })
}
