import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, CheckboxGroup, DateRangePicker } from 'rsuite'
import type { ValueType } from 'rsuite/esm/Checkbox'
import { CloseIcon, SearchIcon } from '../../../icons'
import { CustomText } from '../CustomText'

interface CustomMultiSelectMenuProps {
  buttonComponent: ReactNode
  menuList?: { [key: string]: any }[]
  className?: string
  selectedValue?: ValueType[]
  calender?: boolean
  onSelectedValue?: (data: ValueType[]) => void
  onFilterChange?: (searchTxt: string) => void
  onFilterClear?: () => void
}

export function CustomMultiSelectMenu({
  buttonComponent,
  menuList,
  className = '',
  selectedValue,
  calender = false,
  onSelectedValue,
  onFilterChange,
  onFilterClear,
}: CustomMultiSelectMenuProps) {
  const { t } = useTranslation('common')
  const [showMore, setShowMore] = useState(false)
  const [menu, setMenu] = useState<{ [key: string]: any }[]>([])
  const [searchTxt, setSearchTxt] = useState('')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const toggleMenu = () => {
    setShowMore(!showMore)
  }

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node
    if (buttonRef.current && !buttonRef.current.contains(target)) {
      setShowMore(false)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setSearchTxt(newValue)
    onFilterChange?.(newValue)
  }

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      setMenu(menuList)
    }
  }, [menuList])

  useEffect(() => {
    if (showMore) {
      document.addEventListener('click', handleClickOutside)
    }
    else {
      document.removeEventListener('click', handleClickOutside)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showMore])

  useEffect(() => {
    // Debounce logic
    if (menuList && menuList?.length > 0 && !onFilterChange) {
      const debounce = setTimeout(() => {
        const filteredData = menuList?.filter(item =>
          item.title?.toLowerCase().includes(searchTxt.toLowerCase()),
        )
        setMenu(filteredData ?? [])
      }, 500)
      return () => clearTimeout(debounce)
    }
  }, [searchTxt])

  const handleSelect = (selectedValue: any[] | null) => {
    if (selectedValue)
      onSelectedValue?.(selectedValue)
    setShowMore(false)
  }

  return (
    <div className="relative items-center flex">
      <button ref={buttonRef} onClick={toggleMenu}>
        {buttonComponent}
      </button>
      {selectedValue && selectedValue.length > 0 && (
        <button onClick={onFilterClear}>
          <CloseIcon className="h-5 w-5 ml-2.5" />
        </button>
      )}
      {showMore && calender && (
        <div onClick={e => e.stopPropagation()}>
          <DateRangePicker
            open={showMore}
            ranges={[]}
            showHeader={false}
            value={
              selectedValue && selectedValue.length
                ? [new Date(selectedValue[0]), new Date(selectedValue[1])]
                : null
            }
            format="yyyy-MM-dd"
            onOk={handleSelect}
            style={{ visibility: 'hidden', position: 'absolute', zIndex: -1, left: 0 }}
          />
        </div>
      )}
      {showMore && !calender && (
        <div
          className={`absolute top-[44px] z-10 rounded-md bg-white shadow-md shadow-gray-900/10 menu ${showMore ? 'visible' : ''} ${className}`}
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-1 items-center py-3 px-4" onClick={e => e.stopPropagation()}>
            <SearchIcon className="h-5 w-5 mr-2" />
            <input
              type="text"
              value={searchTxt}
              placeholder={t('searchPlaceholder')}
              className="outline-0"
              onChange={handleSearchChange}
            />
          </div>
          <CheckboxGroup
            name="checkbox-group"
            value={selectedValue}
            onChange={onSelectedValue}
            className="pl-4 min-w-fit max-h-60 overflow-auto scrollbar"
          >
            <>
              {menu
                .filter(i => selectedValue?.includes(i.handle))
                .map(i => (
                  <Checkbox key={i.handle} value={i.handle}>
                    <CustomText wrapText={false} variant="smPoppinsRegular">
                      {i.title}
                    </CustomText>
                  </Checkbox>
                ))}

              {menu
                .filter(i => !selectedValue?.includes(i.handle))
                .map(i => (
                  <Checkbox key={i.handle} value={i.handle}>
                    <CustomText wrapText={false} variant="smPoppinsRegular">
                      {i.title}
                    </CustomText>
                  </Checkbox>
                ))}
            </>
          </CheckboxGroup>
        </div>
      )}
    </div>
  )
}
