import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { queryClient } from '../../../App'
import type { CampaignDetailsParam } from './useCampaignDetails'

interface CampaignStatus {
  campaignHandle: string
  status: number
}

function onStatusChange(result: CampaignStatus) {
  queryClient.setQueryData([QueryKey.campaignDetails], (campaignDetails: CampaignDetailsParam) => {
    if (campaignDetails === undefined)
      return
    return {
      ...campaignDetails,
      item: {
        ...campaignDetails.item,
        status: result.status,
      },
    }
  })
}

export function useCampaignStatus() {
  return useMutation({
    mutationFn: async (data: CampaignStatus) => {
      await APIFunctions.POST(`${APIConst.campaignStatus}`, data)
      return data
    },
    onSuccess: onStatusChange,
  })
}
