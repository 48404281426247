import { useLocation } from 'react-router-dom'
import CreateEquipmentCategory from '../../component/templates/CreateEquipmentCategory'

function CreateEquipmentCateScreen() {
  const { state } = useLocation()

  return (
    <div className="p-5 h-full flex flex-col bg-white border border-stroke rounded-lg">
      <CreateEquipmentCategory
        categoryList={state?.categoryList}
        selectedItem={state?.selectedItem}
      />
    </div>
  )
}

export default CreateEquipmentCateScreen
