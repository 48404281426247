import { useQuery } from '@tanstack/react-query'
import { GetSocialSecurityNumberApiResponse } from '@inphiz-shared/shared-models/src/F_SocialSecurityNumber/get-social-security-number-api-response'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const SSNResponseType = new GetSocialSecurityNumberApiResponse()
export type SSNResponse = typeof SSNResponseType

export function getSSNCode(userHandle: string) {
  return useQuery<SSNResponse>({
    queryKey: [QueryKey.ssnCodeData],
    queryFn: () =>
      APIFunctions.GET(`${APIConst.usersDirectory.sensitiveData}?ownerHandle=${userHandle}`),
  })
}
