import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { useMutation } from '@tanstack/react-query'
import type { EquipmentItem } from './useEquipmentList'

export function useEquipmentCreate() {
  return useMutation({
    mutationFn: async (data: EquipmentItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.equipment.createEquipment, params)
      return result
    },
  })
}
