export function MyDayIcon({ className = '' }) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" className={className}>
      <path
        d="M8.75 18.25H2.75C2.35218 18.25 1.97064 18.0919 1.68933 17.8105C1.40803 17.5292 1.25 17.1478 1.25 16.75V4.75C1.25 4.35218 1.40803 3.97076 1.68933 3.68945C1.97064 3.40815 2.35218 3.25 2.75 3.25H16.25C16.6478 3.25 17.0294 3.40815 17.3107 3.68945C17.592 3.97076 17.75 4.35218 17.75 4.75V9.25"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.25 7.75H17.75" stroke="white" strokeWidth={1.5} strokeLinejoin="round" />
      <path
        d="M5.75 4.75V1.75"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 4.75V1.75"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 24.25C18.9367 24.25 20.0967 23.8981 21.0834 23.2388C22.0701 22.5795 22.8391 21.6425 23.2933 20.5462C23.7474 19.4498 23.8662 18.2433 23.6347 17.0794C23.4032 15.9155 22.8318 14.8465 21.9926 14.0074C21.1535 13.1682 20.0844 12.5968 18.9205 12.3653C17.7566 12.1337 16.5502 12.2527 15.4539 12.7068C14.3575 13.1609 13.4205 13.9298 12.7612 14.9165C12.1019 15.9032 11.75 17.0633 11.75 18.25C11.75 19.8413 12.3821 21.3675 13.5074 22.4927C14.6326 23.6179 16.1587 24.25 17.75 24.25Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 16V19.75H20"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
