import { ERecommendedActionType } from '@inphiz-shared/shared-models/src/F_RecomededActions/e-recommended-action-type'
import i18n from '../translations'

export function extractChatMessage(input: string): { extracted: string[], remaining: string } {
  const regex = /\[([^\]]+)\]/g
  const extracted: string[] = []
  let remaining = input
  let match = regex.exec(input)

  while (match !== null) {
    extracted.push(match[1] ?? '')
    remaining = remaining.replace(match[0], '')
    match = regex.exec(input)
  }

  remaining = remaining.trim() // Remove leading/trailing spaces

  return { extracted, remaining }
}

export function getActionDescription(id: number) {
  switch (id) {
    case ERecommendedActionType.UserCertificateIsExpired:
      return i18n.t('userCertificateIsExpired')
    case ERecommendedActionType.UserPolicyIsNotAccepted:
      return i18n.t('userPolicyIsNotAccepted')
    case ERecommendedActionType.UserJobTitleRequirementIsMissing:
      return i18n.t('userJobTitleRequirementIsMissing')
    case ERecommendedActionType.UserJobRoleRequirementIsMissing:
      return i18n.t('userJobRoleRequirementIsMissing')
    case ERecommendedActionType.UserCertificateIsNotApproved:
      return i18n.t('userCertificateIsNotApproved')
    case ERecommendedActionType.UserCertificateIsExpiring:
      return i18n.t('userCertificateIsExpiring')
    case ERecommendedActionType.UserStartDateMissing:
      return i18n.t('userStartDateMissing')
    case ERecommendedActionType.UserProfileInfoIsMissing:
      return i18n.t('userProfileInfoIsMissing')
    case ERecommendedActionType.UserIsNotLoggedIn:
      return i18n.t('userIsNotLoggedIn')
    case ERecommendedActionType.UserDidNotPassOnboarding:
      return i18n.t('userDidNotPassOnboarding')
    case ERecommendedActionType.ArchivedUserIsNotRemovedFromAD:
      return i18n.t('archivedUserIsNotRemovedFromAD')
    case ERecommendedActionType.VehicleIsIdling:
      return i18n.t('vehicleIsIdling')
    case ERecommendedActionType.VehicleCostExceed:
      return i18n.t('vehicleCostExceed')
    case ERecommendedActionType.MissingTimereports:
      return i18n.t('missingTimereports')
    case ERecommendedActionType.NotPlannedEmptyings:
      return i18n.t('notPlannedEmptyings')
    case ERecommendedActionType.NotHandledEmptyings:
      return i18n.t('notHandledEmptyings')
    default:
      return ''
  }
}
