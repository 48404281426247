import type { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'rsuite'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  CustomTextArea,
  DeleteAlert,
  ImagePicker,
  JobCompanyAlert,
  JobTitleAddAlert,
  Spinner,
  TextWithIcon,
} from '../../component'
import type { filterItem } from '../RoleSpecializationsList'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import type { JobRequirementItem, JobTitleItem } from '../../packages/api-web/RoleManagement'
import {
  useDeleteJobRequirement,
  useJobRequirements,
  useJobTitleDetails,
  useJobType,
  usePostJobRequirement,
  usePostJobTitleRelationsInfo,
  usePutJobTitle,
} from '../../packages/api-web/RoleManagement'
import type { OrganizationUnitItem, UploadResponse } from '../../packages/api-web'
import { useOrganizationUnits, useUploadMedia } from '../../packages/api-web'

function RolePositionEdit() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchTxt, setSearchTxt] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [description, setDescription] = useState<string>('')
  const [isCompliance, setIsCompliance] = useState<boolean>(false)
  const [icon, setIcon] = useState<string>()
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [isShowProceed, setIsShowProceed] = useState<boolean>(false)
  const [isClearImage, setIsClearImage] = useState<boolean>(false)
  const [isJobTitleAddAlert, setIsJobTitleAddAlert] = useState<boolean>(false)
  const [isShowRequirementsProceed, setIsShowRequirementsProceed] = useState<boolean>(false)
  const [updateJobTitleObj, setUpdateJobTitleObj] = useState<JobTitleItem>({})
  const [updateJobRequirementObj, setUpdateJobRequirementObj] = useState<JobRequirementItem[]>([])
  const [selectedCompany, setSelectedCompany] = useState<OrganizationUnitItem[]>([])
  const { isLoading, data: jobDetails } = useJobTitleDetails({
    handle: id ?? '',
  })
  const { isLoading: isJobTypeLoad, data: jobRequirement } = useJobType()
  const { data: organizationList, isLoading: isOrganizationLoad } = useOrganizationUnits()
  const { mutate, isPending } = usePostJobTitleRelationsInfo({ isJobTitle: true })
  const { mutate: updateMutate, isPending: updateIsPending } = usePutJobTitle({ isJobTitle: true })
  const { mutate: updateJobRequirementMutate, isPending: updateJobRequirementIsPending }
    = usePostJobRequirement({
      handle: id ?? '',
      isJobTitle: true,
    })
  const { mutate: deleteJobRequirementMutate, isPending: deleteJobRequirementIsPending }
    = useDeleteJobRequirement({
      handle: id ?? '',
      isJobTitle: true,
    })
  const { mutate: uploadMediaMute } = useUploadMedia()

  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: 'type',
      data: [],
      selectedData: null,
    },
    {
      id: 2,
      header: 'category',
      data: [],
      selectedData: null,
    },
  ])
  const {
    isLoading: isRequirementLoad,
    data: requirementsData,
    refetch,
  } = useJobRequirements({
    filter: searchTxt,
    filterData,
    jobTitleHandle: id,
  })
  const tableHeaderList = [
    { title: t('tableTitle'), id: 1 },
    { title: t('type'), id: 2 },
    { title: t('category'), id: 3 },
    { title: t('compliance'), id: 4 },
  ]

  useEffect(() => {
    setDescription(jobDetails?.item?.description ?? '')
    setIsCompliance(jobDetails?.item?.isCompliant ?? false)
    setSelectedCompany(jobDetails?.item?.organizationUnits ?? [])
  }, [jobDetails])

  const jobTypeData = useMemo(() => {
    const result = jobRequirement?.items?.map((i) => {
      const data
        = i?.subTypes
        && i?.subTypes?.length > 0
        && i?.subTypes?.map((j) => {
          return {
            title: j,
            handle: j,
          }
        })
      return { ...i, handle: i?.title, subTypes: data }
    })
    return result
  }, [jobRequirement])

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = jobTypeData
    setFilterData(newFilter)
  }, [jobTypeData])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      refetch()
  }

  const saveJobDetails = () => {
    const paramsObj = {
      title: jobDetails?.item?.title,
      description,
      handle: id,
      isCompliant: isCompliance,
      organizationUnits: selectedCompany,
    }
    const requestParams: FormData = new FormData()
    if (icon) {
      requestParams.append('files', icon)
      uploadMediaMute(requestParams, {
        onSuccess: (response: UploadResponse) => {
          if (response.isSuccess) {
            const newObject = {
              ...paramsObj,
              mediaItemHandles: response.items?.map(i => i.handle)?.filter(Boolean) as string[],
            }
            mutate(newObject, {
              onSuccess: () => {
                setUpdateJobTitleObj(newObject)
                setIsShowRequirementsProceed(false)
                setIsShowProceed(true)
              },
            })
          }
        },
      })
    }
    else {
      const newObject = {
        ...paramsObj,
        ...(!isClearImage
          && jobDetails?.item?.media
          && jobDetails?.item?.media?.length > 0 && {
          mediaItemHandles: jobDetails?.item?.media
            ?.map(i => i.handle)
            ?.filter(Boolean) as string[],
        }),
      }
      mutate(newObject, {
        onSuccess: () => {
          setUpdateJobTitleObj(newObject)
          setIsShowRequirementsProceed(false)
          setIsShowProceed(true)
        },
      })
    }
  }

  if (isLoading || isJobTypeLoad || isRequirementLoad || isOrganizationLoad)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPending
        || updateIsPending
        || updateJobRequirementIsPending
        || deleteJobRequirementIsPending) && <Spinner />}
      {isShowProceed && (
        <DeleteAlert
          title={t('youWantToProceed')}
          subTitle={
            isShowRequirementsProceed ? t('noUsersWillBeAffected') : t('nothingWillBeRemoved')
          }
          leftButtonText={t('cancel')}
          rightButtonText={t('save')}
          onLeftButton={() => {
            setIsShowProceed(!isShowProceed)
          }}
          onRightButton={() => {
            setIsShowProceed(!isShowProceed)
            if (isShowRequirementsProceed) {
              const result = requirementsData?.items
                ?.filter(
                  item1 => !updateJobRequirementObj.some(item2 => item2.handle === item1.handle),
                )
                .map(i => i.handle) as string[]
              if (result && result?.length > 0) {
                deleteJobRequirementMutate(result, {
                  onSuccess: () => {
                    updateJobRequirementMutate(updateJobRequirementObj)
                  },
                })
              }
              else {
                updateJobRequirementMutate(updateJobRequirementObj)
              }
            }
            else {
              updateMutate(updateJobTitleObj)
            }
          }}
        />
      )}
      {isShowAlert && (
        <JobCompanyAlert
          data={organizationList?.items}
          selectedData={selectedCompany}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onSelectedData={(values) => {
            setSelectedCompany(values)
          }}
        />
      )}
      {isJobTitleAddAlert && (
        <JobTitleAddAlert
          isJobTitle={true}
          selectedData={requirementsData?.items}
          jobTitleHandle={id}
          filterDataItems={filterData}
          onCloseAlert={() => {
            setIsJobTitleAddAlert(false)
          }}
          onSuccessInfo={(data) => {
            setUpdateJobRequirementObj(data)
            setIsShowRequirementsProceed(true)
            setIsShowProceed(true)
          }}
        />
      )}
      <CustomText variant="xlPoppinsBold">{`${t('roleManagement')}  / ${t('jobTitleList')}  / ${t('edit')}`}</CustomText>
      <div className="overflow-hidden overflow-y-auto scrollbar">
        <div className="mt-6 py-3 px-5 bg-white rounded-lg border border-stroke">
          <CustomText variant="mdPoppinsSemiBold">{t('totalUsersWithTheJobTitle')}</CustomText>
          <CustomText variant="mdPoppinsRegular" className="!text-[#2F5BFD]">
            {`${jobDetails?.item?.totalPrimaryUserJobTitlesCount}`}
          </CustomText>
        </div>
        <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <CustomTextArea
              title={`${t('description')} ${t('maxCharacters')}`}
              placeholder={`${t('writeDescription')}`}
              aria-multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              maxLength={160}
            />
            <div className="flex lg:justify-center">
              <ImagePicker
                defaultImage={
                  jobDetails?.item?.media && jobDetails?.item?.media?.length > 0
                    ? jobDetails?.item?.media[0].url
                    : undefined
                }
                title={`${t('uploadIcon')} ${t('maxPixels200')}`}
                buttonTitle={`${t('choosePicture')}`}
                selectedImage={(item) => {
                  setIsClearImage(false)
                  setIcon(item)
                }}
                onClearImage={() => {
                  setIsClearImage(true)
                }}
              />
            </div>
            <div>
              <CustomText variant="mdPoppinsSemiBold">{t('addCompanyJobTitle')}</CustomText>
              <div className="flex mt-3 mb-3">
                <CustomText variant="mdPoppinsRegular">{`${t('chooseFromTheList')} `}</CustomText>
                <CustomText variant="mdPoppinsRegular" className="underline">
                  {t('youHaveSelected', {
                    count: selectedCompany?.length ?? 0,
                  })}
                </CustomText>
              </div>
              <CustomButton
                children={t('edit')}
                backgroundColor="bg-primary"
                textColor="text-textWhite"
                onClick={() => {
                  setIsShowAlert(true)
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <CustomText variant="mdPoppinsSemiBold">{t('compliance')}</CustomText>
            <Checkbox
              checked={isCompliance}
              onChange={() => {
                setIsCompliance(!isCompliance)
              }}
              className="cm-checkbox mt-3"
            >
              {t('includeJobTitleCompliance')}
            </Checkbox>
          </div>
          <div className="mt-3 flex gap-4 justify-end">
            <CustomButton
              children={t('save')}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              className="!px-6"
              onClick={saveJobDetails}
            />
            <CustomButton
              children={t('cancel')}
              backgroundColor="bg-bthDisable"
              className="!px-6"
              textColor="text-textPrimary"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
        <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((item, index) => {
              return (
                <div className="flex items-center gap-3" key={index}>
                  <CustomMenu
                    menuList={item?.data ?? []}
                    selectedValue={item?.selectedData ?? null}
                    onClick={(value) => {
                      const updateItem = { ...item, selectedData: value?.handle }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      if (index == 0) {
                        const updateItem = { ...newFilter[1], data: value?.subTypes }
                        newFilter[1] = updateItem
                      }
                      setFilterData(newFilter)
                    }}
                    buttonComponent={(
                      <div className="flex">
                        <TextWithIcon
                          text={t(item?.header)}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item?.selectedData && (
                          <>
                            <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                              <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                                1
                              </CustomText>
                            </div>
                            <button
                              onClick={() => {
                                const updateItem = { ...item, selectedData: null }
                                const newFilter: any = [...filterData]
                                newFilter[index] = updateItem
                                if (index == 0) {
                                  const updateItem = {
                                    ...newFilter[1],
                                    data: [],
                                    selectedData: null,
                                  }
                                  newFilter[1] = updateItem
                                }
                                setFilterData(newFilter)
                              }}
                            >
                              <CloseIcon className="h-5" />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  />
                  <div className="h-8 bg-black/10 w-[1px]" />
                </div>
              )
            })}
          </div>
          <BorderButton
            children={t('filter')}
            className="px-7 self-end"
            onClick={() => {
              refetch()
            }}
          />
        </div>
        <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-col sm:flex-row mb-4 gap-4">
            <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
              {`${t('total')}: ${requirementsData?.totalCount}`}
            </CustomText>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center w-auto gap-4">
                <SearchIcon />
                <input
                  type="text"
                  value={searchTxt}
                  placeholder={t('searchPlaceholder')}
                  className="outline-0 flex-1 min-w-[100px]"
                  onChange={e => setSearchTxt(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {searchTxt && (
                  <button
                    onClick={() => {
                      setSearchTxt('')
                      setTimeout(() => {
                        refetch()
                      }, 1000)
                    }}
                  >
                    <CloseIcon className="h-5" />
                  </button>
                )}
              </div>
              <CustomButton
                children={t('add')}
                textVariant="smPoppinsMedium"
                className="px-4 sm:px-8 bg-secondary"
                onClick={() => {
                  setIsJobTitleAddAlert(true)
                }}
              />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
            <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
              <CustomTable
                tableHeaderList={tableHeaderList}
                tableDataList={requirementsData?.items?.slice(skipItem, skipItem + itemPerPage)}
                displayKeys={['title', 'type', 'subtype', 'isCompliant']}
              />
            </div>
            {requirementsData && requirementsData?.items && requirementsData?.items.length > 0 && (
              <CustomPagination
                totalItem={requirementsData?.totalCount}
                itemPerPage={itemPerPage}
                onChange={setItemPage}
                onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
                skip={skipItem}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolePositionEdit
