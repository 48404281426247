import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { CertificateDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const CertificateItemDto = new CertificateDto()
export type CertificateItem = typeof CertificateItemDto

const CertificateListDto = new DataCollectionApiResponse<CertificateItem>()
export type CertificateData = typeof CertificateListDto

export function useCertificateList() {
  return useQuery<CertificateData>({
    queryKey: [QueryKey.getCertificate],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.certificateCategory)
        .add('cursor', 0)
        .add('take', 2147483647)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
