import { useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowMore, type ShowMoreRef, type ShowMoreToggleLinesFn } from '@re-dev/react-truncate'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { BorderButton, CustomButton, CustomImg, CustomText, TextWithIcon } from '../../atoms'
import {
  CheckedIcon,
  Comment,
  DeleteIcon,
  NewsCommentIcon,
  NewsLikeIcon,
  UnLike,
  UncheckIcon,
} from '../../../icons'
import '../../atoms/CustomText/CustomText.css'
import {
  type Group,
  type SocialAction,
  type SocialDetails,
  usePostReadingUserList,
} from '../../../packages/api-web'
import { SocialPostType, dateFormat } from '../../../helpers'
import { queryClient } from '../../../App'
import { UserContext } from '../../../contexts/UserContext'
import { ReadUnreadListModal, UserLikeListModal } from '../../molecules'

export interface SocialItemProps {
  item?: SocialDetails
  onClickLike?: () => void
  onPressDelete?: () => void
  onReadConfirmed?: (requestObj: SocialAction) => void
}

export function SocialItem({ item, onClickLike, onPressDelete, onReadConfirmed }: SocialItemProps) {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const [isVisible, setIsVisible] = useState(false)
  const [isReadVisible, setIsReadVisible] = useState(false)
  const ref = useRef<ShowMoreRef>(null)
  const socialTab = queryClient.getQueryData([QueryKey.groupsList, 'groupwallpost']) as Group[]
  const { data } = usePostReadingUserList({ isReading: true, postHandle: item?.handle ?? '' })

  const readingUserList = useMemo(() => {
    const result = data?.pages?.flatMap(i => i.items)
    return result
  }, [data])

  const tabName: Group | undefined = socialTab?.find(i => i?.handle === item?.ownerHandle)
  const [selectCheckBox, setSelectCheckBox] = useState(false)

  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    ref.current?.toggleLines(e)
  }

  return (
    <div className="pt-4">
      <div className="px-5 mb-3">
        {item?.type === SocialPostType.readConfirmation && (
          <div className="flex flex-row items-center justify-between mb-4">
            <BorderButton children={t('requiresReading')} />
            {item.authorHandle === user.userData.handle && (
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  onPressDelete?.()
                }}
              >
                <DeleteIcon className="fill-textSecondary" />
              </button>
            )}
          </div>
        )}
        <div className="mb-2 flex">
          <CustomImg
            name={item?.author?.displayName ?? 'Anonym'}
            imgSrc={
              item?.author?.profileImageHandle
                ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item.author.profileImageHandle}`
                : ''
            }
            altText="user_image"
            className="h-10 w-10 rounded-md"
            imgClassName="rounded-md"
          />
          <div className="flex flex-col ml-3 flex-1">
            <div className="flex items-center">
              <CustomText variant="smPoppinsSemiBold">
                {`${item?.author?.displayName ?? 'Anonym'}`}
              </CustomText>
              <CustomText variant="smPoppinsRegular">{` ${t('publishedTo')}`}</CustomText>
              <CustomText variant="smPoppinsSemiBold">{` ${tabName?.title}`}</CustomText>
            </div>
            <CustomText variant="smPoppinsRegular" textColor="text-textSecondary" className="mt-1">
              {dateFormat(item?.createdTime ?? '')}
            </CustomText>
          </div>
          {!item?.actionsInfo && item?.authorHandle === user.userData.handle && (
            <button
              className="self-start"
              onClick={(event) => {
                event.stopPropagation()
                onPressDelete?.()
              }}
            >
              <DeleteIcon className="fill-textSecondary" />
            </button>
          )}
        </div>
        <ShowMore
          ref={ref}
          lines={2}
          className="smPoppinsRegular text-textPrimary"
          more={(
            <CustomText variant="smPoppinsRegular" onClick={toggleLines} className="underline">
              {t('readMore')}
            </CustomText>
          )}
          less={(
            <CustomText variant="smPoppinsRegular" onClick={toggleLines} className="underline">
              {t('readLess')}
            </CustomText>
          )}
        >
          {item?.content}
        </ShowMore>
      </div>
      {item && item.coverMedia && item.coverMedia.length > 0 && (
        <img src={item.coverMedia[0].url} alt="cover_img" className="new-img" />
      )}
      <div className="px-5">
        {item?.actionsInfo
        && item?.actionsInfo?.socialActions?.find(
          (i: any) => i.userHandle === user.userData.handle && i.value === `False`,
        ) && (
          <>
            <div className="bg-bgColor p-3 mt-4 mb-2">
              <CustomText variant="smPoppinsMedium" className="mb-2">
                {`${t('confirmReadInfo')}.`}
              </CustomText>
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  setSelectCheckBox(!selectCheckBox)
                }}
              >
                <TextWithIcon
                  leftIcon={
                    selectCheckBox ? (
                      <CheckedIcon className="mr-2" />
                    ) : (
                      <UncheckIcon className="mr-2" />
                    )
                  }
                  text={t('readAndAgree')}
                  textVariant="smPoppinsRegular"
                />
              </button>
            </div>
            <div className="flex flex-1 justify-between items-center">
              <CustomButton
                disabled={!selectCheckBox}
                children={t('confirm')}
                backgroundColor={selectCheckBox ? 'bg-primary' : 'bg-bthDisable'}
                textColor={selectCheckBox ? 'text-textWhite' : 'text-textSecondary'}
                onClick={() => {
                  const requestObj = item?.actionsInfo?.socialActions?.find((i: any) => {
                    return i.userHandle === user.userData.handle
                  })
                  if (requestObj)
                    onReadConfirmed?.(requestObj as SocialAction)
                }}
              />
              {item?.type === SocialPostType?.readConfirmation && (
                <div
                  onClick={() => setIsReadVisible(true)}
                  className="flex items-center mt-4 pb-3"
                >
                  <CustomText className="mr-4" variant="smPoppinsRegular">
                    {`${t('acknowledgedBy')}:`}
                  </CustomText>
                  <div className="flex items-center">
                    {readingUserList
                      ?.slice(0, 5)
                      .map(i => (
                        <CustomImg
                          key={i?.user?.handle}
                          name={i?.user?.displayName ?? 'Anonym'}
                          imgSrc={
                            i?.user?.profileImageHandle
                              ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${i.user.profileImageHandle}`
                              : ''
                          }
                          altText="user_image"
                          className="h-6 w-6 rounded-full -ml-2 border-2 border-white"
                          textClassName="!text-[10px]"
                          imgClassName="rounded-full"
                        />
                      ))}
                    <CustomText className="ml-2" variant="smPoppinsRegular">
                      {`${item?.actionsInfo?.currentCount}/${item?.actionsInfo?.totalCount}`}
                    </CustomText>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {item?.actionsInfo
        && item?.actionsInfo?.socialActions?.find(
          (i: any) => i.userHandle === user.userData.handle && i.value === `True`,
        ) && (
          <div className="flex justify-between mt-4 pb-3">
            <CustomText variant="smPoppinsRegular">{`${t('acknowledgedBy')}:`}</CustomText>
            <div onClick={() => setIsReadVisible(true)} className="flex items-center">
              {readingUserList
                ?.slice(0, 10)
                .map(i => (
                  <CustomImg
                    key={i?.user?.handle}
                    name={i?.user?.displayName ?? 'Anonym'}
                    imgSrc={
                      i?.user?.profileImageHandle
                        ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${i.user.profileImageHandle}`
                        : ''
                    }
                    altText="user_image"
                    className="h-6 w-6 rounded-full -ml-2 border-2 border-white"
                    textClassName="!text-[10px]"
                    imgClassName="rounded-full"
                  />
                ))}
              <CustomText className="ml-4" variant="smPoppinsRegular">
                {`${item?.actionsInfo?.currentCount}/${item?.actionsInfo?.totalCount}`}
              </CustomText>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between px-5 py-3">
        <button
          onClick={() => {
            if ((item?.likeCount ?? 0) > 0) {
              setIsVisible(true)
            }
          }}
          className="flex items-center"
        >
          <NewsLikeIcon className="h-5 w-5 mr-2" />
          <CustomText className="!text-[12px]" variant="smPoppinsRegular">
            {`${item?.likeCount} ${t('like')}`}
          </CustomText>
        </button>
        <div className="flex items-center">
          <NewsCommentIcon className="h-5 w-5 mr-2" />
          <CustomText className="!text-[12px]" variant="smPoppinsRegular">
            {`${item?.commentCount} ${t('comments')}`}
          </CustomText>
        </div>
      </div>
      <div className="flex py-3 border-y border-y-stroke">
        <div className="flex flex-1 justify-center items-center">
          <button
            onClick={(event) => {
              event.stopPropagation()
              onClickLike?.()
            }}
          >
            <TextWithIcon
              leftIcon={(
                <UnLike
                  className={`mr-2 ${item?.metadata?.IsLikedByUser === `True` ? 'fill-secondary' : 'fill-white'}`}
                />
              )}
              text={t('likeU')}
              textVariant="smPoppinsRegular"
              className="!text-[12px]"
            />
          </button>
        </div>
        <div className="flex flex-1 justify-center items-center">
          <TextWithIcon
            leftIcon={<Comment className="mr-2" />}
            text={t('comment')}
            textVariant="smPoppinsRegular"
            className="!text-[12px]"
          />
        </div>
      </div>
      <UserLikeListModal
        handle={item?.handle ?? ''}
        likeCount={item?.likeCount}
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false)
        }}
      />
      <ReadUnreadListModal
        handle={item?.handle ?? ''}
        isVisible={isReadVisible}
        onClose={() => {
          setIsReadVisible(false)
        }}
      />
    </div>
  )
}
