import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import type { JobRoleItem } from './useRoleSpecializations'

const RolePositionsDto = new DataItemApiResponse<JobRoleItem>()
export type JobRoleData = typeof RolePositionsDto

interface JobRoleParams {
  handle: string
}

export function useJobRoleDetails({ handle }: JobRoleParams) {
  return useQuery<JobRoleData>({
    queryKey: [QueryKey.jobRoleDetail],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getJobRoleItemDetails).add(
        'Handle',
        handle,
      )
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
