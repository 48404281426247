import { SocialNoDataIcon } from '../../../icons'
import { CustomButton, CustomText } from '../../atoms'

interface SocialEmptyProps {
  title?: string
  subTitle?: string
  btnTitle?: string
  onEmptyBtnPress?: () => void
}

export function SocialEmpty({ title, subTitle, btnTitle, onEmptyBtnPress }: SocialEmptyProps) {
  return (
    <div className="absolute top-20 bottom-0 right-0 left-0 z-10 flex flex-col flex-1 justify-center items-center bg-white rounded-lg border border-stroke">
      <SocialNoDataIcon />
      <CustomText className="mt-3" variant="mdPoppinsBold">
        {title || ''}
      </CustomText>
      <CustomText className="mt-3 mb-4 text-center sm:w-60 lg:w-96" variant="mdPoppinsRegular">
        {subTitle || ''}
      </CustomText>
      <CustomButton
        backgroundColor="bg-secondary"
        children={btnTitle || ''}
        onClick={onEmptyBtnPress}
      />
    </div>
  )
}
