import type { TextVariants } from '../CustomText'
import { CustomText } from '../CustomText'

export interface TextWithIconProps {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  text?: string
  textVariant?: TextVariants
  className?: string
}

export function TextWithIcon({
  leftIcon,
  rightIcon,
  text,
  textVariant = 'mdPoppinsRegular',
  className = '',
}: TextWithIconProps) {
  return (
    <div className="flex-row flex justify-between items-center">
      {leftIcon && leftIcon}
      {text && (
        <CustomText variant={textVariant} className={`flex-1 ${className}`}>
          {text}
        </CustomText>
      )}
      {rightIcon && rightIcon}
    </div>
  )
}
