import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import defaultTheme from 'tailwindcss/defaultTheme'
import { useNavigate } from 'react-router-dom'
import { BorderButton, ChatList, CustomText, HistoryList } from '../../component'
import type { HistoryListItem } from '../../component'
import { UserContext } from '../../contexts/UserContext'

function Chat() {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [historyItem, setHistoryItem] = useState<HistoryListItem>()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsSemiBold">{`${t('welcomeBack')} ${user?.userData?.displayName}`}</CustomText>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-6 mb-4 items-center">
        <div>
          <CustomText className="mb-[5px]" variant="smPoppinsSemiBold">
            {t('history')}
          </CustomText>
        </div>
        <div className="col-span-2 flex ml-5 max-lg:hidden">
          <CustomText className="mb-[5px]" variant="smPoppinsSemiBold">
            {t('chat')}
          </CustomText>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden">
        <div className="overflow-y-auto relative flex flex-col border border-stroke rounded-lg">
          <div className="overflow-y-auto scrollbar flex-1 bg-white">
            <HistoryList
              onPress={(item) => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate('/chatdetails', {
                    state: { historyItem: item },
                  })
                }
                else {
                  setHistoryItem(item)
                }
              }}
            />
          </div>
          <div className="px-5 py-5 bg-white">
            <BorderButton
              onClick={() => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate('/chatdetails', {
                    state: { historyItem: undefined },
                  })
                }
                else {
                  setHistoryItem(undefined)
                }
              }}
              className="w-full rounded-md"
              children={t('newQuestion')}
            />
          </div>
        </div>
        <div className="ml-5 col-span-2 relative flex flex-col max-lg:hidden overflow-hidden bg-white border border-stroke rounded-lg">
          <ChatList historyItem={historyItem} />
        </div>
      </div>
    </div>
  )
}

export default Chat
