import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { ValueType } from 'rsuite/esm/Checkbox'
import moment from 'moment'

export interface MyActionItemProps {
  id: string
  title: string
  description: string
  additionalData: string
  entityHandle: string
  entityImageHandle?: string
  severity: number
  status: number
  entityType: number
  type: number
}

interface MyActionListProps {
  items: MyActionItemProps[]
  totalCount: number
  isSuccess: boolean
}

interface ActionListProps {
  readonly actionType?: string
  searchTxt?: string
  stateNames?: ValueType[]
  organizationUnitHandles?: ValueType[]
  managerHandles?: ValueType[]
  startDate?: string
  endDate?: string
}

export function useActionList({
  actionType = '',
  searchTxt = '',
  stateNames = [],
  organizationUnitHandles = [],
  managerHandles = [],
  startDate = '',
  endDate = '',
}: ActionListProps) {
  return useQuery<MyActionListProps>({
    queryKey: [QueryKey.tableCardList],
    queryFn: async () => {
      let url = `?ActionType=${actionType}&EntityName=${searchTxt}&Take=2147483647`
      if (stateNames.length > 0) {
        const outputString = stateNames.map(i => `StateNames=${i || null}`).join('&')
        url = `${url}&${outputString}`
      }
      if (organizationUnitHandles.length > 0) {
        const outputString = organizationUnitHandles
          .map(i => `OrganizationUnitHandles=${i || null}`)
          .join('&')
        url = `${url}&${outputString}`
      }
      if (managerHandles.length > 0) {
        const outputString = managerHandles.map(i => `ManagerHandles=${i || null}`).join('&')
        url = `${url}&${outputString}`
      }
      if (startDate) {
        url = `${url}&startDate=${moment(startDate).format('yyyy-MM-DD')}`
      }
      if (endDate) {
        url = `${url}&endDate=${moment(endDate).format('yyyy-MM-DD')}`
      }
      const results = await APIFunctions.GET(APIConst.getActionCard + url)
      return results
    },
    enabled: false,
  })
}
