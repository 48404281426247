import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export function useCampaignCreate() {
  return useMutation({
    mutationFn: async (data: any) => {
      const params = {
        item: data,
      }
      await APIFunctions.POST(APIConst.createCampaign, params)
    },
  })
}
