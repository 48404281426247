import type { Axios, AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { appConfig } from '@inphiz/app-config/src/AppConfig'
import AppInfo from '@inphiz/app-core-shared/src/AppInfo'
import i18n from 'i18next'
import { queryClient } from '@inphiz/api/src'
import { AuthenticationSharedClient } from '@inphiz/auth/src/AuthFactory/AuthenticationSharedClient'
import { Logger } from '@inphiz/logger'
import { Headers } from '@inphiz-shared/shared-models/src/NetworkConstants/headers'
import uuid from 'react-native-uuid'
import { APIConst } from './APIServices'
import { logErrorResponse, logRequest, logResponse } from './HttpLogging'

import { ApiSharedAuthProviderInstance } from './IApiSharedAuthProvider'

// main axios instance for making api calls from the application
const httpClient = axios.create()

applyLoggingInterceptorsToAxios(httpClient)

if (!appConfig.properties.AlwaysUseAuthorizationApiKey) {
  // setup axios interceptor for adding authorization header
  httpClient.interceptors.request.use(
    async (config) => {
      config.baseURL = APIConst.baseURL
      // auth client can be null.
      // TBD we need to check the current state of the application and get the token from the authClient

      let authToken: string | null = null

      const authClient
        = AuthenticationSharedClient.instance ?? ApiSharedAuthProviderInstance.getInstance()
      if (!authClient) {
        Logger.Log.warn('AuthClient instance is null')
      }
      else {
        authToken = await authClient.getAuthToken()
        if (authToken === null) {
          Logger.Log.warn('Auth token is null. Expected to be a valid token')
        }
      }

      if (authToken !== null) {
        config.headers.Authorization = `Bearer ${authToken}`
      }
      else {
        config.headers.Authorization = `ApiKey ${APIConst.ApiKey}`
      }

      applyHeadersToTheRequest(config)

      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )
}
else {
  // setup axios interceptor for using authorization api key only
  httpClient.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `ApiKey ${APIConst.ApiKey}`
      config.baseURL = APIConst.baseURL
      applyHeadersToTheRequest(config)
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )
}

let isSingleAlert = false
httpClient.interceptors.response.use(
  async (response) => {
    // axious won't send here anything except 2XX status code
    // so this code won't be executed
    if (response.status == 500)
      await SessionExpired()

    if (response && response?.data) {
      if (response.data.isSuccess != undefined && response.data.isSuccess != true) {
        const message = response?.data?.message
        if (!isSingleAlert) {
          await ApiSharedAuthProviderInstance.getInstance().sessionExpiredAlert(
            i18n.t('warning'),
            message || i18n.t('somethingWentWrong'),
          )
          isSingleAlert = true
          resetAlert()
        }
      }
    }
    return response
  },
  async (error) => {
    await SessionExpired()
    if (!isSingleAlert) {
      await ApiSharedAuthProviderInstance.getInstance().sessionExpiredAlert(
        i18n.t('warning'),
        error.response.data.message || i18n.t('somethingWentWrong'),
      )
      isSingleAlert = true
      resetAlert()
    }
    Promise.reject(error)
  },
)

// axios.interceptors.request.use(async config => {
//   logRequest(config)
//   return config
// })
// axios.interceptors.response.use(logResponse, error => {
//   return logError(error)
// })

function applyLoggingInterceptorsToAxios(instance: Axios) {
  instance.interceptors.request.use(async (config) => {
    logRequest(config)
    return config
  })
  instance.interceptors.response.use(logResponse, (error) => {
    return logErrorResponse(error)
  })
}

function applyHeadersToTheRequest(config: AxiosRequestConfig<any>) {
  if (!config.headers) {
    config.headers = {}
  }

  // todo use this also
  // foreach (var customHeader in queryBuilder.Headers)
  //   request.Headers.TryAddWithoutValidation(customHeader.Key, customHeader.Value);

  //   // Set language
  // var language = _currentCultureService.GetCurrentCultureInfo()?.TwoLetterISOLanguageName.ToLower();
  // request.Headers.Add(NetworkConstants.Headers.Language, language);
  // //

  // request.Headers.Add(NetworkConstants.Headers.CORRELATION_ID, Guid.NewGuid().ToString());

  // if (FakeUserHeadersHelper.UseFakeUserHandle)
  // {
  //     request.Headers.Add(FakeUserHeadersHelper.FakeUserHandleHeader, FakeUserHeadersHelper.FakeUserHandle);
  // }

  //   request.Headers
  //     .TryAddWithoutValidation("X-BUILD-VERSION-INFO", _headers_app_params.Value.appBuildInfo);
  // request.Headers
  //     .TryAddWithoutValidation(NetworkConstants.Headers.APP_VERSION, _headers_app_params.Value.app_ver);
  // request.Headers
  //     .TryAddWithoutValidation(NetworkConstants.Headers.APP_BUILD, _headers_app_params.Value.app_build);
  // request.Headers
  //     .TryAddWithoutValidation(NetworkConstants.Headers.OS_TYPE, _headers_app_params.Value.os);
  // request.Headers
  //     .TryAddWithoutValidation(NetworkConstants.Headers.OS_VERSION, _headers_app_params.Value.os_ver);

  // config.headers[Headers.JSON_NO_DEFAULTS] = '' // to disable default values in the backend json serialization
  config.headers[Headers.APP_VERSION] = AppInfo.getInstance().getAppVersion()
  config.headers[Headers.APP_BUILD] = AppInfo.getInstance().getAppBuild()
  config.headers[Headers.VERSION_INFO] = AppInfo.getInstance().getAppVersionInfo()
  config.headers[Headers.OS_TYPE] = AppInfo.getInstance().getOsType()
  config.headers[Headers.OS_VERSION] = AppInfo.getInstance().getOsVersion()
  config.headers[Headers.APP_BUNDLEID] = AppInfo.getInstance().getBundleId()
  config.headers[Headers.CORRELATION_ID] = uuid.v4().toString() // Guid.NewGuid().ToString()
  // config.headers[Headers.language] = "user-language" - TODO
}

async function SessionExpired(): Promise<void> {
  if (await ApiSharedAuthProviderInstance.getInstance().checkSessionExpired()) {
    queryClient.removeQueries()
    await ApiSharedAuthProviderInstance.getInstance().resetSession()
    isSingleAlert = true
    resetAlert()
  }

  // const session = await EncryptedStorage.getItem(EncryptedStorageKey.userSession)
  // if (session !== undefined && session !== null) {
  //   const user = JSON.parse(session)
  //   if (new Date().getTime() > new Date(user.accessTokenExpirationDate).getTime()) {
  //     await EncryptedStorage.removeItem(EncryptedStorageKey.userSession)
  //     queryClient.removeQueries()
  //     // reset('Root')
  //     isSingleAlert = true
  //     resetAlert()
  //   }
  // }
}

function resetAlert() {
  setTimeout(() => {
    isSingleAlert = false
  }, 5000)
}

export async function GET(url: string, config = {}) {
  const result = await httpClient
    .get(url, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function POST(url: string, params: any) {
  const result = await httpClient
    .post(url, params || {})
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function FORM_POST(url: string, params: any) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const result = await httpClient
    .post(url, params || {}, { headers })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function DELETE(url: string, params: any) {
  const result = await httpClient
    .delete(url, { data: params })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function PUT(url: string, params: any) {
  const result = await httpClient
    .put(url, params || {})
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

let aiChatApiClient: AxiosInstance | null = null
export async function AICHAT_POST(url: string, params: any) {
  if (aiChatApiClient === null) {
    aiChatApiClient = axios.create()
    applyLoggingInterceptorsToAxios(aiChatApiClient)
  }

  const result = await aiChatApiClient
    .post(url, params || {}, {
      baseURL: appConfig.properties.aiAssistant.url,
      headers: {
        'x-api-key': appConfig.properties.aiAssistant.assistantApiKey,
        'x-assistant-id': appConfig.properties.aiAssistant.assistantID,
        // 'Host': appConfig.properties.aiAssistant.host,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export default { GET, POST, FORM_POST, DELETE, PUT, AICHAT_POST }
