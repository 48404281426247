import { useQuery } from '@tanstack/react-query'
import { UserJobRoleDto } from '@inphiz-shared/shared-models/src/Dto/user-job-role-dto'
import { QueryKey } from '../../QueryKey'
import APIFunctions from '../../Networking/APIFunctions'
import { APIConst } from '../../Networking/APIServices'

const JobRoleDetailsType = new UserJobRoleDto()
type JobRoleItem = typeof JobRoleDetailsType

export interface JobRoleDetails extends JobRoleItem {
  badge?: {
    text: string
    textColor: {
      colorHex: string
    }
    backgroundColor: {
      colorHex: string
    }
  }
}

export function getJobRoleDetails(handle: string = '@me', jobRole: string) {
  const results = useQuery({
    queryKey: [QueryKey.jobRoleDetails],
    queryFn: () =>
      APIFunctions.GET(
        `${APIConst.employeesPerformance.getJobRoleDetails}?UserHandle=${handle}&JobRoleHandle=${jobRole}`,
      ),
  })
  const response = results.isFetching ? null : results.data
  if (response && response?.isSuccess)
    return { ...results, data: response.item as JobRoleDetails }
  else return { ...results, data: null }
}
