export function EmployeesIcon({ className = '' }) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" className={className}>
      <path
        d="M18.468 13.18L20.009 16.5H22.988C23.1393 16.4941 23.2887 16.535 23.4159 16.6172C23.543 16.6994 23.6417 16.8189 23.6984 16.9593C23.755 17.0997 23.767 17.2542 23.7325 17.4017C23.6981 17.5491 23.619 17.6822 23.506 17.783L20.924 20.345L22.366 23.659C22.429 23.8107 22.4429 23.9785 22.4056 24.1385C22.3684 24.2985 22.282 24.4427 22.1585 24.5511C22.035 24.6594 21.8807 24.7264 21.7172 24.7425C21.5537 24.7586 21.3893 24.7231 21.247 24.6409L17.761 22.68L14.274 24.6409C14.1317 24.7231 13.9673 24.7586 13.8038 24.7425C13.6403 24.7264 13.486 24.6594 13.3625 24.5511C13.239 24.4427 13.1526 24.2985 13.1154 24.1385C13.0781 23.9785 13.092 23.8107 13.155 23.659L14.6 20.3479L12 17.7859C11.8859 17.6862 11.8056 17.5536 11.7699 17.4063C11.7342 17.259 11.745 17.1043 11.8008 16.9634C11.8565 16.8225 11.9546 16.7024 12.0814 16.6194C12.2082 16.5365 12.3575 16.4947 12.509 16.5H15.509L17.053 13.177C17.1209 13.0483 17.2227 12.9405 17.3474 12.8653C17.4721 12.7901 17.6149 12.7505 17.7605 12.7505C17.9061 12.7505 18.0489 12.7901 18.1736 12.8653C18.2983 12.9405 18.4001 13.0483 18.468 13.177V13.18Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.332 13.2349C11.653 12.2028 10.6751 11.4027 9.52905 10.9412C8.38301 10.4798 7.12341 10.379 5.91864 10.6527C4.71387 10.9263 3.62132 11.5613 2.78705 12.4725C1.95278 13.3837 1.41654 14.5279 1.25 15.752"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 9.00195C7.91751 9.00195 8.57005 8.80395 9.12506 8.4331C9.68008 8.06226 10.1127 7.53516 10.3681 6.91846C10.6235 6.30176 10.6904 5.62319 10.5602 4.96851C10.43 4.31382 10.1085 3.71248 9.63647 3.24048C9.16447 2.76848 8.56313 2.44712 7.90845 2.31689C7.25376 2.18667 6.57514 2.25334 5.95844 2.50879C5.34173 2.76423 4.81464 3.19694 4.44379 3.75195C4.07294 4.30697 3.875 4.95944 3.875 5.62695C3.875 6.52206 4.23059 7.38049 4.86353 8.01343C5.49646 8.64636 6.35489 9.00195 7.25 9.00195Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7.50196C17.5192 7.50196 18.0267 7.34801 18.4584 7.05957C18.8901 6.77114 19.2265 6.36125 19.4252 5.8816C19.6239 5.40194 19.6759 4.87395 19.5746 4.36475C19.4733 3.85555 19.2233 3.38787 18.8561 3.02076C18.489 2.65364 18.0213 2.40378 17.5121 2.30249C17.0029 2.20121 16.4751 2.25298 15.9955 2.45166C15.5158 2.65034 15.1058 2.98703 14.8174 3.41871C14.5289 3.85038 14.375 4.35778 14.375 4.87696C14.375 5.57315 14.6516 6.24088 15.1439 6.73316C15.6361 7.22544 16.3038 7.50196 17 7.50196Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9 10.502C20.5419 9.88246 20.0412 9.3573 19.4396 8.97C18.8379 8.58269 18.1526 8.34435 17.4404 8.27493C16.7283 8.20551 16.0098 8.307 15.3447 8.57083C14.6795 8.83465 14.0869 9.25331 13.616 9.79202"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
