import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RightIcon } from '../../../icons'
import type { MultiDropdownParams } from '../../atoms'
import {
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomNumberInput,
  CustomText,
  CustomTextArea,
  MultiSelectDropDown,
} from '../../atoms'
import WeeklyOption from '../../molecules/WeeklyOption'
import CustomDatePicker from '../../atoms/CustomDatePicker'
import { SurveyTable } from '../../molecules'
import type { SchedulesParam, SurveyParam } from '../../../packages/api-web'
import { useJobTitleList } from '../../../packages/api-web'

export interface ItemParam {
  id: number
  title: string
  description: string
  startAt: Date | null
  endAt: Date | null
  jobTitleHandles: string[]
  surveys: any[]
  cron: string
}

interface ScheduleItemProps {
  item: SchedulesParam
  index: number
  handleEdit: (id: number, field: string, value: any) => void
  errorString: string[]
}

function ScheduleItem({ item, index, errorString, handleEdit }: ScheduleItemProps) {
  const { t } = useTranslation('common')
  const [isExpand, setIsExpand] = useState(false)
  const [selectedOption, setSelectedOption] = useState<{ title: string, id: number }>()
  const [isEdited, setIsEdited] = useState(false)
  const [tableList, setTableList] = useState<SurveyParam[]>([])
  const [selectedTime, setSelectedTime] = useState<Date>()
  const [days, setDays] = useState<number>(1)
  const [month, setMonth] = useState<number>(1)
  const [selectedWeek, setSelectedWeek] = useState<string[]>([])
  const { data: jobData } = useJobTitleList()

  useEffect(() => {
    if (item) {
      const [second, minute, hour, dayOfMonth, months, dayOfWeek] = item?.cron?.split(' ')

      const defaultTime = new Date()
      defaultTime.setHours(Number(hour))
      defaultTime.setMinutes(Number(minute))
      defaultTime.setSeconds(0)
      setSelectedTime(defaultTime)

      if (item.surveys?.length > 0) {
        const surveyList = item.surveys.map(i => ({
          ...i,
          selected: i.selected ?? true,
        }))
        setTableList(surveyList)
      }

      if (months.includes('/')) {
        const [, interval] = months.split('/')
        setDays(Number(dayOfMonth))
        setMonth(Number(interval))
        setSelectedOption({ title: t('monthly'), id: 3 })
      }
      else if (dayOfMonth.includes('/')) {
        const [, interval] = dayOfMonth.split('/')
        setDays(Number(interval))
        setSelectedOption({ title: t('daily'), id: 1 })
      }
      else {
        setDays(Number(dayOfMonth))
        setSelectedOption({ title: t('weekly'), id: 2 })
        setSelectedWeek(dayOfWeek.split(','))
      }
    }
  }, [item])

  const jobFilterData = useMemo(() => {
    const result
      = jobData?.items
      && jobData?.items?.length > 0
      && (jobData?.items?.map(item => ({
        label: item?.title,
        value: item?.handle,
      })) as MultiDropdownParams[])
    return result as MultiDropdownParams[]
  }, [jobData])

  const handleTableEdit = (itemIndex: number, field: string, value: any) => {
    const updatedList = tableList.map((item, index) =>
      index === itemIndex ? { ...item, [field]: value } : item,
    )
    setTableList(updatedList)
    handleEdit(index, 'surveys', updatedList)
  }

  const handleDeletePress = () => {
    const updatedList = tableList.filter((_, i) => i !== index)
    setTableList(updatedList)
    setIsEdited(false)
    handleEdit(index, 'surveys', updatedList)
  }

  return (
    <div className="flex flex-1 flex-col px-5 py-3 bg-white rounded-md mb-5">
      <button
        onClick={() => setIsExpand(!isExpand)}
        className="flex flex-1 items-center justify-between"
      >
        <div className="w-1/2 flex">
          <CustomText variant="xlPoppinsSemiBold">{`${t('schedule')} ${index}`}</CustomText>
        </div>
        {!isExpand && (
          <div className="w-1/3 flex">
            <CustomText className="line-clamp-1 px-2" variant="mdPoppinsSemiBold">
              {item.title}
            </CustomText>
          </div>
        )}
        {!isExpand && (
          <div className="w-1/3 flex">
            <CustomText className="line-clamp-1 px-2">{item.description}</CustomText>
          </div>
        )}
        <RightIcon className={`${isExpand ? 'rotate-90' : ''}`} />
      </button>
      {isExpand && (
        <div>
          <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
            <div>
              <CustomInput
                title={t('title')}
                className={`mt-[5px] mb-4 ${!item.title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                value={item.title}
                onChange={(e) => {
                  handleEdit(index, 'title', e.target.value)
                }}
              />
              <CustomTextArea
                rows={5}
                title={t('description')}
                className="mt-[5px]"
                value={item.description}
                onChange={(e) => {
                  handleEdit(index, 'description', e.target.value)
                }}
              />
            </div>
            <div className="col-span-2 flex flex-1 flex-col">
              <div className="grid sm:grid-cols-2 gap-4 mt-4 lg:mt-0 mb-4">
                <CustomDatePicker
                  title={t('startDate')}
                  value={new Date(item.startAt ?? '')}
                  onChange={(value) => {
                    if (value) {
                      handleEdit(index, 'startAt', value)
                    }
                  }}
                />
                <CustomDatePicker
                  title={t('endDate')}
                  value={new Date(item.endAt ?? '')}
                  onChange={(value) => {
                    if (value) {
                      handleEdit(index, 'endAt', value)
                    }
                  }}
                />
              </div>
              <div className="flex flex-col flex-1 justify-between">
                <div className="grid sm:grid-cols-2 sm:gap-4 mb-0 sm:mb-4">
                  <div className="grid grid-cols-2 gap-4">
                    <CustomDatePicker
                      value={selectedTime}
                      title={t('time')}
                      format="HH:mm"
                      onChange={(value) => {
                        if (value) {
                          const time = new Date(value)
                          setSelectedTime(time)
                          handleEdit(
                            index,
                            'cron',
                            `0 ${time.getMinutes()} ${time.getHours()} 1/1 * ? *`,
                          )
                        }
                      }}
                    />
                    <CustomDropDown
                      values={[selectedOption]}
                      title={t('repeat')}
                      options={[
                        {
                          title: t('daily'),
                          id: 1,
                        },
                        {
                          title: t('weekly'),
                          id: 2,
                        },
                        {
                          title: t('monthly'),
                          id: 3,
                        },
                      ]}
                      labelField="title"
                      valueField="id"
                      className="!m-1 !mx-0"
                      onChange={(item) => {
                        setSelectedOption(item[0])
                        setDays(1)
                        setMonth(1)
                        setSelectedWeek([])
                      }}
                    />
                  </div>
                  <div className="my-4 sm:my-0">
                    {selectedOption?.id == 1 ? (
                      <CustomNumberInput
                        title={t('every')}
                        rightTitle={t('days')}
                        className="!w-1/3"
                        value={days}
                        onChange={(value) => {
                          if (value) {
                            setDays(Number(value))
                            handleEdit(
                              index,
                              'cron',
                              `0 ${selectedTime?.getMinutes()} ${selectedTime?.getHours()} 1/${Number(value)} * ? *`,
                            )
                          }
                        }}
                      />
                    ) : selectedOption?.id == 2
                      ? (
                          <WeeklyOption
                            title={t('on')}
                            selectedData={selectedWeek}
                            onChange={(data) => {
                              handleEdit(
                                index,
                                'cron',
                                `0 ${selectedTime?.getMinutes()} ${selectedTime?.getHours()} ? * ${data.toString()} *`,
                              )
                            }}
                          />
                        )
                      : (
                          <div className="grid grid-cols-2 gap-4">
                            <CustomNumberInput
                              title={t('every')}
                              rightTitle={t('month')}
                              value={month}
                              onChange={(value) => {
                                if (value) {
                                  setMonth(Number(value))
                                  handleEdit(
                                    index,
                                    'cron',
                                    `0 ${selectedTime?.getMinutes()} ${selectedTime?.getHours()} ${Number(days)} 1/${value} ? *`,
                                  )
                                }
                              }}
                            />
                            <CustomNumberInput
                              title={t('on')}
                              rightTitle={t('days')}
                              max={31}
                              value={days}
                              onChange={(value) => {
                                if (value) {
                                  setDays(Number(value))
                                  handleEdit(
                                    index,
                                    'cron',
                                    `0 ${selectedTime?.getMinutes()} ${selectedTime?.getHours()} ${Number(value)} 1/${month} ? *`,
                                  )
                                }
                              }}
                            />
                          </div>
                        )}
                  </div>
                </div>
                <MultiSelectDropDown
                  title={t('selectedJobTitle')}
                  value={item.jobTitleHandles}
                  data={jobFilterData}
                  onChange={(value) => {
                    handleEdit(index, 'jobTitleHandles', value)
                  }}
                />
              </div>
            </div>
          </div>
          <CustomButton
            disabled={isEdited}
            children={t('addNewSurvey')}
            className={`mt-5 !bg-green ${isEdited ? 'opacity-40' : ''}`}
            onClick={() => {
              const tableData = [...tableList]
              tableData.unshift({
                title: '',
                content: '',
                inputTypeConfiguration: {
                  inputType: 1,
                },
                comment: {
                  isCommentAllowed: true,
                },
                type: 'scheduled',
                selected: false,
              })
              setTableList(tableData)
              setIsEdited(true)
            }}
          />
          <div>
            <SurveyTable
              tableDataList={tableList}
              isEdited={isEdited}
              setIsEdited={setIsEdited}
              tableEdit={handleTableEdit}
              onDeletePress={handleDeletePress}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduleItem
