import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { JobRequirementItem } from './useJobRequirements'

interface JobRequirementsInfoParams {
  handle?: string
  isJobTitle?: boolean
}

export function usePostJobRequirementInfo({ handle, isJobTitle }: JobRequirementsInfoParams) {
  return useMutation({
    mutationFn: async (data: JobRequirementItem[]) => {
      const params = {
        requirements: data,
      }
      const result = await APIFunctions.POST(
        `${isJobTitle ? APIConst.employeesPerformance.getJobTitles : APIConst.employeesPerformance.getJobRolesLeadership}/${handle}/requirements/info`,
        params,
      )
      return result
    },
  })
}
