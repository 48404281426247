import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { filterItem } from '../../../screen/RoleSpecializationsList'
import type { RequirementData } from './useJobRequirements'

interface JobRequirementsParams {
  filter?: string
  filterData?: filterItem[]
}

export function useJobAllRequirements({ filter = '', filterData = [] }: JobRequirementsParams) {
  return useQuery<RequirementData>({
    queryKey: [QueryKey.roleAllRequirements],
    queryFn: async () => {
      const qb = new QueryBuilder(`${APIConst.employeesPerformance.requirements}`).add(
        'Filter',
        filter,
      )
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('Type', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('Subtype', filterData[1]?.selectedData)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
