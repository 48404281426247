import { useLocation } from 'react-router-dom'
import { ChatList } from '../../component'

function ChatDetails() {
  const { state } = useLocation()
  return (
    <div className="h-full w-full flex flex-col">
      <div className="m-5 rounded-lg relative flex flex-1 flex-col overflow-hidden bg-white border border-stroke">
        <ChatList historyItem={state?.historyItem} />
      </div>
    </div>
  )
}

export default ChatDetails
