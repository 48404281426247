export function ControlCenterIcon({ className = '' }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className={className}>
      <path
        d="M11.002 13.52C11.0022 13.7791 10.8998 14.0278 10.717 14.2115C10.5342 14.3952 10.2861 14.4989 10.027 14.5H2.22694C2.09851 14.4999 1.97141 14.4744 1.85285 14.425C1.73429 14.3757 1.6266 14.3034 1.53602 14.2124C1.44544 14.1213 1.37377 14.0133 1.32502 13.8945C1.27627 13.7757 1.25143 13.6484 1.25196 13.52V2.73C1.2517 2.60156 1.27672 2.47435 1.32563 2.35559C1.37454 2.23683 1.44637 2.12887 1.537 2.03787C1.62763 1.94687 1.73527 1.8746 1.85383 1.82521C1.97239 1.77582 2.09954 1.75026 2.22797 1.75L10.028 1.763C10.287 1.76406 10.5351 1.86785 10.7177 2.05157C10.9003 2.2353 11.0025 2.48396 11.002 2.743V13.52Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7479 7.53801C23.7461 7.79484 23.6424 8.04042 23.4595 8.2208C23.2767 8.40118 23.0298 8.5016 22.7729 8.5H14.973C14.8458 8.50093 14.7196 8.47677 14.6017 8.42891C14.4839 8.38105 14.3766 8.31043 14.2861 8.22109C14.1955 8.13174 14.1235 8.02543 14.074 7.90822C14.0246 7.79101 13.9987 7.66522 13.9979 7.53801V2.72501C13.9986 2.59794 14.0243 2.47223 14.0735 2.35509C14.1228 2.23795 14.1947 2.13169 14.285 2.04235C14.3754 1.953 14.4825 1.88235 14.6002 1.83442C14.7179 1.78649 14.8439 1.76221 14.9709 1.763L22.7709 1.75C23.0279 1.7484 23.2751 1.84878 23.4583 2.0291C23.6414 2.20943 23.7455 2.455 23.7479 2.71201V7.53801Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.002 23.285C11.0012 23.4125 10.9752 23.5386 10.9257 23.6561C10.8762 23.7736 10.804 23.8802 10.7133 23.9698C10.6226 24.0595 10.5151 24.1303 10.397 24.1784C10.2789 24.2265 10.1525 24.2508 10.025 24.25L2.22498 24.237C1.96815 24.2381 1.72139 24.1371 1.53894 23.9564C1.3565 23.7756 1.25328 23.5298 1.25195 23.273V18.464C1.25248 18.3366 1.27813 18.2106 1.32745 18.0932C1.37677 17.9757 1.44879 17.8692 1.53937 17.7796C1.62994 17.6901 1.73729 17.6193 1.85529 17.5713C1.97328 17.5233 2.09956 17.4991 2.22693 17.5H10.027C10.284 17.4989 10.5309 17.5998 10.7137 17.7805C10.8964 17.9612 11.0001 18.207 11.002 18.464V23.285Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.974 24.25C14.8454 24.25 14.7181 24.2246 14.5993 24.1752C14.4806 24.1259 14.3728 24.0535 14.2821 23.9624C14.1914 23.8712 14.1196 23.763 14.0708 23.644C14.0221 23.5251 13.9973 23.3976 13.9979 23.269V12.481C13.9975 12.3526 14.0225 12.2253 14.0712 12.1065C14.12 11.9877 14.1918 11.8797 14.2823 11.7886C14.3728 11.6975 14.4804 11.6251 14.599 11.5756C14.7175 11.5261 14.8445 11.5004 14.973 11.5H22.773C23.0322 11.5013 23.2803 11.6053 23.463 11.7892C23.6458 11.973 23.7482 12.2218 23.7479 12.481V23.256C23.7482 23.515 23.6459 23.7637 23.4634 23.9475C23.2809 24.1313 23.033 24.2354 22.7739 24.237L14.974 24.25Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
