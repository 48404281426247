import { useQuery } from '@tanstack/react-query'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import type { UserCampaignDto } from '@inphiz-shared/shared-models/src/F_Feedback/user-campaign-dto'
import { UserSurveyDto } from '@inphiz-shared/shared-models/src/F_Feedback/user-survey-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const CampaignDetailsDto = new DataItemApiResponse<UserCampaignDto>()
export type CampaignDetailsParam = typeof CampaignDetailsDto

const QuestionFeedbackSurveyDto = new UserSurveyDto()
export type QuestionFeedbackSurveyItem = typeof QuestionFeedbackSurveyDto

export function campaignDetails(handle: string) {
  return useQuery<CampaignDetailsParam>({
    queryKey: [QueryKey.campaignDetails],
    queryFn: async () => APIFunctions.GET(`${APIConst.getCampaignDetails}?Handle=${handle}`),
  })
}
