import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FlatList from 'flatlist-react'
import { BorderButton, CustomText } from '../../atoms'

interface ChatEmptyProps {
  onPressMessage: (item: string) => void
}

export function ChatEmpty({ onPressMessage }: ChatEmptyProps) {
  const { t } = useTranslation('common')
  const [list] = useState<string[]>([
    'Jag ska tanka min hjullastare, finns det regler för hur jag ska hantera drivmedel?',
    'Ska sätta upp en byggnadsställning på ett 6-våningshus, vad ska jag tänka på?',
    'Vilka regler gäller för asbestsanering?',
    'Hur är det med semesterersättning?',
  ])

  const renderPerson = (item: string, idx: string) => {
    return (
      <li key={idx} className="cursor-pointer">
        <BorderButton
          className="sm:h-14 py-3 px-2 lg:px-4 border border-stroke rounded-md w-full text-left"
          children={item}
          textVariant="xsmPoppinsRegular"
          onClick={() => {
            onPressMessage(item)
          }}
        />
      </li>
    )
  }

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col flex-1 justify-center items-center text-center mt-10">
        <CustomText variant="mdPoppinsSemiBold">{t('giveAnswersTime')}</CustomText>
        <CustomText className="pt-2" variant="smPoppinsRegular">
          {t('giveAnswersDes')}
        </CustomText>
      </div>
      <div className="text-center mb-4">
        <CustomText variant="smPoppinsSemiBold">{t('example')}</CustomText>
        <ul className="mx-4 lg:mx-8 mt-3">
          <FlatList
            list={list}
            renderItem={renderPerson}
            displayGrid
            gridGap="15px"
            minColumnWidth="35%"
          />
        </ul>
      </div>
    </div>
  )
}
