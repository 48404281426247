import { useInfiniteQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

export interface Notification {
  readonly createdTime: string
  readonly message: string
  readonly originImage: string
  readonly handle: string
  readonly status: string
  readonly messageFormat: string
  readonly category: string
  readonly userHandle: string
  readonly target: string
  readonly openUrl: string
  readonly parameters: {
    name: string
    value: string
  }[]
  readonly author?: {
    readonly profileImageHandle?: string
    readonly profileImage?: string
    readonly displayName?: string
  }
}

export interface NotificationsList {
  readonly items: ReadonlyArray<Notification>
  readonly nextCursor: number
}

export function useNotificationsQuery() {
  const results = useInfiniteQuery({
    queryKey: [QueryKey.notifications],
    queryFn: async ({ pageParam }) => {
      const results = await APIFunctions.GET(
        `${APIConst.notificationsList}?UseContinuationToken=False&Take=30&IsOrderAscending=False&OnlyCount=False&Cursor=${pageParam}`,
      )
      const nextCursorValue = pageParam + 30
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor
    },
  })
  return results
}
