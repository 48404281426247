import type { InfiniteData } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { News, NewsList } from '../News'
import { queryClient } from '../../../App'
import { ContentType } from '../../../helpers'
import type { Likes } from './useGetLikesByHandle'
import type { CommentsData } from './useGetCommentByHandle'
import type { SocialList } from './useSocialList'

interface DeleteLikeProps {
  readonly ownerHandle: string
  readonly contentType: string
  readonly ownerContentType?: string
  readonly authorHandle: string
  readonly userHandle?: string
  readonly itemHandle?: string
  handle?: string
  readonly parentCommentHandle?: string | undefined
}
interface DeleteLikeSuccessProps {
  readonly selectedNewsGroupHandle: string | undefined
  params: DeleteLikeProps
}

function onDeleteLikeSuccess({ selectedNewsGroupHandle, params }: DeleteLikeSuccessProps) {
  if (params.ownerContentType == ContentType.comment) {
    queryClient.setQueryData(
      [QueryKey.comments, params.itemHandle],
      (requestsHistory: CommentsData) => {
        if (requestsHistory === undefined)
          return

        if (params.parentCommentHandle) {
          return {
            ...requestsHistory,
            items: requestsHistory.items.map((item) => {
              if (item.handle == params.parentCommentHandle) {
                return {
                  ...item,
                  childs: item.childs?.map((comment: any) => {
                    if (comment.handle === params.ownerHandle) {
                      return {
                        ...comment,
                        likeCount: comment.likeCount && comment.likeCount - 1,
                        metadata: { ...comment.metadata, IsLikedByUser: 'False' },
                      }
                    }
                    return comment
                  }),
                }
              }
              return item
            }),
          }
        }
        return {
          ...requestsHistory,
          items: requestsHistory.items.map((comment) => {
            if (comment.handle === params.ownerHandle) {
              return {
                ...comment,
                likeCount: comment.likeCount && comment.likeCount - 1,
                metadata: { ...comment.metadata, IsLikedByUser: 'False' },
              }
            }
            return comment
          }),
        }
      },
    )
  }
  else {
    if (params.ownerContentType == ContentType.groupwallpost) {
      queryClient.setQueryData(
        [QueryKey.socialDetails, params.ownerHandle],
        (socialDetails: any) => {
          if (socialDetails === undefined)
            return

          return {
            ...socialDetails,
            likeCount: socialDetails.likeCount - 1,
            metadata: { ...socialDetails.metadata, IsLikedByUser: 'False' },
          }
        },
      )

      queryClient.setQueryData<InfiniteData<SocialList>>(
        [QueryKey.socialList, selectedNewsGroupHandle],
        (requestsHistory) => {
          if (requestsHistory === undefined)
            return

          return {
            ...requestsHistory,
            pages: requestsHistory.pages.map(page => ({
              ...page,
              items: page.items.map((social) => {
                if (social.handle === params.ownerHandle) {
                  return {
                    ...social,
                    likeCount: social.likeCount && social.likeCount - 1,
                    metadata: { ...social.metadata, IsLikedByUser: 'False' },
                  }
                }
                return social
              }),
            })),
          }
        },
      )
    }
    else {
      queryClient.setQueryData([QueryKey.newsDetails], (newsDetails: News) => {
        if (newsDetails === undefined)
          return

        return {
          ...newsDetails,
          likeCount: newsDetails.likeCount - 1,
          metadata: { ...newsDetails.metadata, IsLikedByUser: 'False' },
        }
      })

      queryClient.setQueryData<InfiniteData<NewsList>>(
        [QueryKey.newsList, selectedNewsGroupHandle],
        (requestsHistory) => {
          if (requestsHistory === undefined)
            return

          return {
            ...requestsHistory,
            pages: requestsHistory.pages.map(page => ({
              ...page,
              items: page?.items?.map((news) => {
                if (news.handle === params.ownerHandle) {
                  return {
                    ...news,
                    likeCount: news.likeCount - 1,
                    metadata: { ...news.metadata, IsLikedByUser: 'False' },
                  }
                }
                return news
              }),
            })),
          }
        },
      )
    }
  }
}

export function useDeleteLikeMutation(selectedNewsGroupHandle: string | undefined) {
  return useMutation({
    mutationKey: [QueryKey.deleteLike],
    mutationFn: async (data: DeleteLikeProps) => {
      const likesResult = await APIFunctions.GET(`${APIConst.getLikes}?ChildOf=${data.ownerHandle}`)
      const ownLike = likesResult?.items.find(
        (like: Likes) => like.author?.handle === data.userHandle,
      )
      data.handle = ownLike.handle
      const params = {
        item: data,
      }
      const results = await APIFunctions.DELETE(APIConst.getLikes, params)
      return { result: results.item, params: data, selectedNewsGroupHandle }
    },
    onMutate: (data) => {
      if (data.ownerContentType == ContentType.groupwallpost) {
        queryClient.cancelQueries({ queryKey: [QueryKey.socialList, selectedNewsGroupHandle] })
        queryClient.cancelQueries({ queryKey: [QueryKey.socialDetails, data.ownerHandle] })
      }
      else {
        if (data.ownerContentType == ContentType.comment)
          queryClient.cancelQueries({ queryKey: [QueryKey.comments, data.itemHandle] })
        else queryClient.cancelQueries({ queryKey: [QueryKey.newsList, selectedNewsGroupHandle] })
      }
      onDeleteLikeSuccess({ params: data, selectedNewsGroupHandle })
    },
    onSettled: (data) => {
      if (data?.params.ownerContentType == ContentType.groupwallpost) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.socialList, selectedNewsGroupHandle] })
        queryClient.invalidateQueries({
          queryKey: [QueryKey.socialDetails, data.params.ownerHandle],
        })
      }
      else {
        if (data?.params.ownerContentType == ContentType.comment)
          queryClient.invalidateQueries({ queryKey: [QueryKey.comments, data?.params.itemHandle] })
        else
          queryClient.invalidateQueries({ queryKey: [QueryKey.newsList, selectedNewsGroupHandle] })
      }
    },
  })
}
