import { useQuery } from '@tanstack/react-query'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { LinkDto } from '@inphiz-shared/shared-models/src/F_Links/link-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const LinkDtoType = new LinkDto()
export type QuickStartItem = typeof LinkDtoType

const MyApplicationType = new DataCollectionApiResponse<LinkDto>()
type QuickStartProps = typeof MyApplicationType

export function useQuickStart() {
  return useQuery<QuickStartProps>({
    queryKey: [QueryKey.quickStart],
    queryFn: () => APIFunctions.GET(APIConst.linksGeneral),
  })
}
