import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { RolePositionsData } from '../RoleManagement'

export function useJobTitleList() {
  return useQuery<RolePositionsData>({
    queryKey: [QueryKey.jobTitles],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getJobTitles)
        .add('cursor', 0)
        .add('take', 2147483647)
        .add('filter', '')
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
