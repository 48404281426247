import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import type { JobTitleItem } from './useRolePositions'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.rolePositions] })
}

export function usePostJobTitle() {
  return useMutation({
    mutationFn: async (data: JobTitleItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.employeesPerformance.getJobTitles, params)
      return result
    },
    onSuccess,
  })
}
