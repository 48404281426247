import type { Stores } from './db'
import { dbName } from './db'

export function getStoreData<T>(storeName: Stores): Promise<T[]> {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName, 1)
    request.onsuccess = (event: any) => {
      const db = event.target.result
      const tx = db.transaction(storeName, 'readonly')
      const store = tx.objectStore(storeName)
      const res = store.getAll()
      res.onsuccess = () => {
        resolve(res.result)
      }
    }
  })
}
