import { useQuery } from '@tanstack/react-query'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import type { FeedbackQuestionItemDto } from '@inphiz-shared/shared-models/src/F_Feedback/feedback-question-item-dto'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const QuestionDetailsType = new DataItemApiResponse<FeedbackQuestionItemDto>()
export type QuestionDetailsParam = typeof QuestionDetailsType

export function questionDetails(handle: string) {
  return useQuery<QuestionDetailsParam>({
    queryKey: [QueryKey.questionDetails, handle],
    queryFn: async () => APIFunctions.GET(`${APIConst.getQuestionDetails}?Handle=${handle}`),
  })
}
