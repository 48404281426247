export function NewsLikeIcon({ className = '' }) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M7.00143 10.9913C6.97733 10.223 7.25894 9.47658 7.78443 8.91577C8.30993 8.35497 9.03639 8.02561 9.80438 8C10.2334 8.00327 10.6556 8.10735 11.037 8.30386C11.4184 8.50037 11.7482 8.78379 12 9.13127C12.2518 8.78379 12.5816 8.50037 12.963 8.30386C13.3444 8.10735 13.7666 8.00327 14.1956 8C14.9636 8.02561 15.6901 8.35497 16.2156 8.91577C16.7411 9.47658 17.0227 10.223 16.9986 10.9913C17.0009 11.3968 16.9232 11.7988 16.7698 12.1742C16.6165 12.5496 16.3906 12.891 16.1051 13.1788L12.9047 16.6052C12.7927 16.7261 12.6576 16.8233 12.5074 16.8911C12.3571 16.9588 12.1948 16.9957 12.0301 16.9997C11.8653 17.0036 11.7015 16.9744 11.5482 16.9138C11.3949 16.8532 11.2554 16.7625 11.1377 16.647L11.0971 16.6058L7.89555 13.1826C7.6094 12.8944 7.38308 12.5524 7.22963 12.1763C7.07617 11.8002 6.99862 11.3975 7.00143 10.9913Z"
        fill="#FF3399"
      />
      <circle cx={12} cy={12} r={11.5} stroke="#FF3399" />
    </svg>
  )
}
