export function RightJumpIcon({ className = '' }) {
  return (
    <svg
      width={10}
      height={12}
      viewBox="0 0 9 10"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M0.939813 9.31836L5.25781 5.00036L0.939813 0.681359" />
      <path d="M8.06152 9.31836L8.06152 0.681359" />
    </svg>
  )
}
