import { useMutation } from '@tanstack/react-query'
import { MinimizedBaseItemDto } from '@inphiz-shared/shared-models/src/Dto/minimized-base-item-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

const itemType = new MinimizedBaseItemDto()
type Item = typeof itemType

const UploadResponseType = new DataCollectionApiResponse<Item>()
export type UploadResponse = typeof UploadResponseType

export function uploadMedia() {
  const { mutate } = useMutation<UploadResponse, Error, FormData, unknown>({
    mutationFn: (param: FormData) => APIFunctions.FORM_POST(APIConst.mediaUpload, param),
  })
  return { mutate }
}
