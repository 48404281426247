import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import type { EquipmentItem } from './useEquipmentList'

interface EquipmentDetailsParams {
  handle: string
}

const EquipmentDetailsDto = new DataItemApiResponse<EquipmentItem>()
export type EquipmentDetailsParam = typeof EquipmentDetailsDto

export function useEquipmentDetails({ handle }: EquipmentDetailsParams) {
  return useQuery<EquipmentDetailsParam>({
    queryKey: [QueryKey.equipmentDetails, handle],
    queryFn: () => APIFunctions.GET(`${APIConst.equipment.detailsEquipment}?handle=${handle}`),
    enabled: !!handle,
  })
}
