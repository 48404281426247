import 'rsuite/dist/rsuite.min.css'
import type { MultiCascaderProps } from 'rsuite'
import { MultiCascader } from 'rsuite'
import type { DataItemValue } from 'rsuite/esm/internals/types'
import { useTranslation } from 'react-i18next'
import { CustomText } from '../CustomText'

export interface MultiDropdownParams {
  label: string
  value: string
}
interface MultiSelectDropDownProps extends MultiCascaderProps<DataItemValue> {
  title?: string
  className?: string
}

export function MultiSelectDropDown({ title, className, ...props }: MultiSelectDropDownProps) {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col cm-multi-select-dropdown">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <MultiCascader
        className={`mt-[5px] ${className}`}
        placeholder={t('select')}
        countable={false}
        menuWidth={240}
        {...props}
      />
    </div>
  )
}
