import { useQuery } from '@tanstack/react-query'
import { UserJobRoleDto } from '@inphiz-shared/shared-models/src/Dto/user-job-role-dto'
import { QueryKey } from '../../QueryKey'
import APIFunctions from '../../Networking/APIFunctions'
import { APIConst } from '../../Networking/APIServices'
import { GetUserRoleModes } from '../../../../InphizComponents/src/helpers/constant'

const UserJobRolesType = new UserJobRoleDto()
type UserJobRoleItem = typeof UserJobRolesType

export interface UserJobRoles extends UserJobRoleItem {
  selected: boolean
  badge?: {
    text: string
    textColor: {
      colorHex: string
    }
    backgroundColor: {
      colorHex: string
    }
  }
}

export function getJobRoles(handle: string = '@me', moda: string = GetUserRoleModes.all) {
  const results = useQuery({
    queryKey: [QueryKey.userJobRoles],
    queryFn: () =>
      APIFunctions.GET(
        `${APIConst.employeesPerformance.getJobRoles}?UserHandle=${handle}&Mode=${moda}`,
      ),
  })
  const response = results.isFetching ? null : results.data

  if (response && response?.isSuccess)
    return { ...results, data: response.item.userJobRoles as UserJobRoles[] }
  else return { ...results, data: null }
}
