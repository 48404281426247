import * as React from 'react'

export function Comment({ className = '' }) {
  return (
    <svg width={20} height={16} viewBox="0 0 20 16" fill="none" className={className}>
      <path
        d="M16.4122 0.804783H3.59122C3.02877 0.799452 2.48721 1.01765 2.08555 1.41143C1.6839 1.8052 1.45503 2.34233 1.44922 2.90478L1.44922 9.89478C1.45503 10.4572 1.6839 10.9944 2.08555 11.3881C2.48721 11.7819 3.02877 12.0001 3.59122 11.9948H12.5562C12.7453 11.9946 12.927 12.0686 13.0622 12.2008L15.9052 14.9918C16.0053 15.0908 16.1323 15.1583 16.2704 15.1857C16.4085 15.2131 16.5517 15.1994 16.682 15.1461C16.8123 15.0928 16.9241 15.0023 17.0035 14.886C17.0828 14.7697 17.1262 14.6326 17.1282 14.4918V12.5528C17.1347 12.4074 17.1863 12.2676 17.2759 12.1529C17.3655 12.0381 17.4887 11.9542 17.6282 11.9128C17.897 11.8149 18.1293 11.6371 18.2939 11.4033C18.4586 11.1694 18.5477 10.8908 18.5492 10.6048V2.90478C18.5434 2.34321 18.3152 1.80684 17.9147 1.4132C17.5141 1.01957 16.9738 0.800783 16.4122 0.804783Z"
        stroke="#333333"
      />
    </svg>
  )
}
