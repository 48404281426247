import { useInfiniteQuery } from '@tanstack/react-query'
import { SearchBucketDto } from '@inphiz-shared/shared-models/src/F_GlobalSearch/search-bucket-dto'
import { QueryBuilder } from '@inphiz/core-utilities'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'

interface FetchNewsProps {
  selectedCategory: string | undefined
  search: string | undefined
}

const SearchResponseProps = new SearchBucketDto()
export type SearchResponsePropsParam = typeof SearchResponseProps

const LIMIT = 10

export function useSearchList({ selectedCategory, search }: FetchNewsProps) {
  const result = useInfiniteQuery({
    queryKey: [QueryKey.searchList],
    queryFn: async ({ pageParam }) => {
      const qb = new QueryBuilder(APIConst.getSearch).add('q', search)

      if (selectedCategory) {
        qb.add('filter', selectedCategory).add('limit', LIMIT).add('cursor', pageParam)
      }
      else {
        qb.add('limit', 100)
      }

      const results = await APIFunctions.GET(qb.toString()) // todo add type

      const nextCursorValue = pageParam + results?.buckets?.[0]?.count
      const nextCursor = selectedCategory
        ? Number.parseInt(results?.buckets?.[0]?.count) < LIMIT
          ? undefined
          : nextCursorValue
        : undefined
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
    enabled: false,
  })
  return result
}
