import type { InfiniteData } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
// import type { TotalNotifications } from './useTotalNotificationsQuery'
import type { NotificationsList } from './useNotificationsQuery'

interface paramsRequest {
  readonly status: string
}

// interface OnSetAllReadSuccesssResult extends TotalNotifications {
//     readonly accessToken: string
// }

function onSetAllReadNotificationsSuccess() {
  // queryClient.setQueryData<TotalNotifications>([QueryKey.totalNotifications], (requestsHistory) => {
  //     if (requestsHistory === undefined)
  //         return

  //     return {
  //         ...requestsHistory,
  //         unreadNotificationsCount: result.unreadNotificationsCount,
  //     }
  // })

  queryClient.setQueryData<InfiniteData<NotificationsList>>(
    [QueryKey.notifications],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      return {
        ...requestsHistory,
        pages: requestsHistory.pages.map(page => ({
          ...page,
          items: page.items.map((notification) => {
            return {
              ...notification,
              status: 'Read',
            }
          }),
        })),
      }
    },
  )
}

export function useAllReadNotificationsMutation() {
  return useMutation({
    mutationKey: [QueryKey.allReadNotifications],
    mutationFn: (data: paramsRequest) => APIFunctions.POST(APIConst.readAllNotifications, data),
    onSuccess: onSetAllReadNotificationsSuccess,
  })
}
