import './CustomText.css'

export type TextVariants =
  | 'xsmPoppinsRegular'
  | 'smPoppinsRegular'
  | 'mdPoppinsRegular'
  | 'lgPoppinsRegular'
  | 'xlPoppinsRegular'
  | 'xxlPoppinsRegular'
  | 'xsmPoppinsMedium'
  | 'smPoppinsMedium'
  | 'mdPoppinsMedium'
  | 'lgPoppinsMedium'
  | 'xlPoppinsMedium'
  | 'xxlPoppinsMedium'
  | 'xsmPoppinsSemiBold'
  | 'smPoppinsSemiBold'
  | 'mdPoppinsSemiBold'
  | 'lgPoppinsSemiBold'
  | 'xlPoppinsSemiBold'
  | 'xxlPoppinsSemiBold'
  | 'xsmPoppinsBold'
  | 'smPoppinsBold'
  | 'mdPoppinsBold'
  | 'lgPoppinsBold'
  | 'xlPoppinsBold'
  | 'xxlPoppinsBold'

export type TextColorVariants =
  | 'text-textPrimary'
  | 'text-textSecondary'
  | 'text-textWhite'
  | 'text-textDanger'

export interface CompTextProps extends React.ComponentPropsWithoutRef<'p'> {
  readonly variant?: TextVariants
  readonly textColor?: TextColorVariants
  readonly children: string
  readonly style?: React.CSSProperties
  wrapText?: boolean
}

export function CustomText({
  children,
  variant = 'mdPoppinsRegular',
  textColor = 'text-textPrimary',
  wrapText = true,
  style,
  ...props
}: CompTextProps) {
  return (
    <p
      {...props}
      style={{ ...style }}
      className={`${variant} ${textColor} m-0 ${wrapText ? 'whitespace-pre-wrap' : 'whitespace-pre'} ${props.className}`}
    >
      {children}
    </p>
  )
}
