import type { ReactNode } from 'react'
import type { TextColorVariants, TextVariants } from '../CustomText'
import { CustomText } from '../CustomText'

export interface BorderButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  readonly children: string
  readonly textColor?: TextColorVariants
  readonly textVariant?: TextVariants
  readonly onClick?: () => void
  readonly rightIcon?: ReactNode
}

export function BorderButton({
  children,
  textColor = 'text-textPrimary',
  textVariant = 'smPoppinsMedium',
  onClick,
  rightIcon,
  ...props
}: BorderButtonProps) {
  return (
    <button
      {...props}
      type="button"
      className={`rounded-lg px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black border justify-center items-center flex ${props.className}`}
      onClick={() => onClick?.()}
    >
      <CustomText variant={textVariant} textColor={textColor}>
        {children}
      </CustomText>
      {rightIcon && rightIcon}
    </button>
  )
}
