import { dbName } from './db'

const version = 1 // also change in db.ts / updateDB.ts
let request: IDBOpenDBRequest
let db: IDBDatabase

export function addDB<T>(storeName: string, data: T): Promise<T | string | null> {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName, version)

    request.onsuccess = () => {
      db = request.result
      const tx = db.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)
      store.add(data)
      resolve(data)
    }

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error)
      }
      else {
        resolve('Unknown error')
      }
    }
  })
}
