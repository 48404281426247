import { Logger } from '@inphiz/logger'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AxiosError } from 'axios'

export function logResponse(response: AxiosResponse): AxiosResponse {
  if (!response)
    return response

  Logger.Log.info(
    {
      status: response.status,
      method: response.config?.method?.toUpperCase(),
      url: response.config?.url,
      baseUrl: response.config?.baseURL,
    },
    'Response',
  )

  Logger.Log.debug(
    {
      status: response.status,
      method: response.config?.method?.toUpperCase(),
      url: response.config?.url,
      body: response.data,
    },
    'Response Details',
  )
  return response
}

export async function logRequest(
  config: AxiosRequestConfig<any>,
): Promise<AxiosRequestConfig<any>> {
  Logger.Log.debug(
    {
      method: config.method?.toUpperCase(),
      url: config.url,
      body: config.data,
      headers: config.headers,
    },
    'Request',
  )
  return config
}

export function logErrorRequest(error: any) {
  logError('Request', error)
}

export function logErrorResponse(error: any) {
  logError('Response', error)
}

function logError(type: string, error: any) {
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError
    Logger.Log.error(`${type} error`, {
      message: axiosError.message,
      name: axiosError.name,
      code: axiosError.code,
      status: axiosError.response?.status,
      method: axiosError.config?.method?.toUpperCase(),
      baseUrl: axiosError.config?.baseURL,
      // body: JSON.stringify(axiosError.response?.data),
      request: JSON.stringify(axiosError.request),
      response: JSON.stringify(axiosError.response),
      raw: JSON.stringify(axiosError),
    })
  }
  else {
    Logger.Log.error(`${type} error`, {
      error,
    })
  }
}
