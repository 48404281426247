import { useQuery } from '@tanstack/react-query'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { QueryKey } from '../QueryKey'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import type { News } from '../News'

const NewsItemDto = new DataItemApiResponse<News>()
export type NewsDetailsParam = typeof NewsItemDto

export function useNewsDetailsQuery(handle: string) {
  return useQuery({
    queryKey: [QueryKey.newsDetails],
    queryFn: async () => {
      const result: NewsDetailsParam = await APIFunctions.GET(
        `${APIConst.newsDetail}?Handle=${handle}`,
      )
      if (result?.isSuccess) {
        return result?.item as News
      }
      else {
        return undefined
      }
    },
  })
}
