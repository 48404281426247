import { useMutation } from '@tanstack/react-query'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { MediaItemDto } from '@inphiz-shared/shared-models/src/Base/media-item-dto'

const itemType = new MediaItemDto()
type Item = typeof itemType

const UploadResponseType = new DataCollectionApiResponse<Item>()
export type UploadResponse = typeof UploadResponseType

export function useUploadMedia() {
  const { mutate, isPending } = useMutation<UploadResponse, Error, FormData, unknown>({
    mutationFn: (param: FormData) => APIFunctions.FORM_POST(APIConst.mediaUpload, param),
  })
  return { mutate, isPending }
}
