import type { IStorage } from './IStorage'

class SharedStorage {
  public static Instance: IStorage
  static setImplementation(impl: IStorage): void {
    this.Instance = impl
  }
}

export { SharedStorage }
