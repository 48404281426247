import type { InfiniteData } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
// import type { TotalNotifications } from './useTotalNotificationsQuery'
import type { NotificationsList } from './useNotificationsQuery'

interface setReadNotificationProps {
  readonly handle: string
  readonly status: string
}

interface OnSetReadSuccesssResult {
  readonly accessToken: string
  readonly handle: string
}

function onSetReadNotificationSuccess(result: OnSetReadSuccesssResult) {
  // queryClient.setQueryData<TotalNotifications>([QueryKey.totalNotifications], (requestsHistory) => {
  //     if (requestsHistory === undefined)
  //         return

  //     return {
  //         ...requestsHistory,
  //         unreadNotificationsCount: result.unreadNotificationsCount,
  //     }
  // })

  queryClient.setQueryData<InfiniteData<NotificationsList>>(
    [QueryKey.notifications],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      return {
        ...requestsHistory,
        pages: requestsHistory.pages.map(page => ({
          ...page,
          items: page.items.map((notification) => {
            if (notification.handle === result.handle) {
              return {
                ...notification,
                status: 'Read',
              }
            }
            return notification
          }),
        })),
      }
    },
  )
}

export function useReadNotificationMutation() {
  return useMutation({
    mutationKey: [QueryKey.readNotification],
    mutationFn: async (data: setReadNotificationProps) => {
      const results = await APIFunctions.POST(APIConst.readNotifications, data)
      return { ...results, handle: data.handle }
    },
    onSuccess: onSetReadNotificationSuccess,
  })
}
